import apiRequest from './api-request';

const resource = 'tenants';

export function getTenants() {
    return apiRequest({
        method: 'GET',
        resource
    });
}

export function getTenant(id: number | string) {
    return apiRequest({
        method: 'GET',
        resource,
        id
    });
}

export function getTenantMeta(id: number | string) {
    return apiRequest({
        method: 'GET',
        resource: `${resource}/${id}/meta`
    });
}

export function createTenant(body: any) {
    return apiRequest({
        method: 'POST',
        resource,
        body
    });
}

export function acceptTenant(id: number | string, isAccepted: boolean) {
    return apiRequest({
        method: 'PUT',
        resource: `${resource}/${id}/accept`,
        body: { isAccepted }
    });
}

export function rejectTenant(id: number | string, isRejected: boolean) {
    return apiRequest({
        method: 'PUT',
        resource: `${resource}/${id}/reject`,
        body: { isRejected }
    });
}

export function updateTenant(id: number | string, body: any) {
    return apiRequest({
        method: 'PUT',
        resource,
        id,
        body
    });
}

export function deleteTenant(id: number | string) {
    return apiRequest({
        method: 'DELETE',
        resource,
        id
    });
}
