import React from 'react';
import { Popup } from '..';
import FormContext from './FormContext';
import Validation from './validations/ValidationContext';

type PropsType = {
    open: boolean,
    title: string,
    okLabel?: string,
    cancelLabel?: string,
    handleOk: () => any,
    handleClose: () => any,
    handleDelete?: () => any | null,
    okDisabled?: boolean
};

const PopupForm: React.FC<PropsType> = (props) => {
    const {
        children,
        handleOk,
        handleDelete,
        okDisabled,
        ...restProps
    } = props;

    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const onSubmitPress = React.useCallback(async () => {
        try {
            setIsSubmitting(true);
            await handleOk();
            setIsSubmitting(false);
        } catch {
            setIsSubmitting(false);
        }
    }, [handleOk]);

    const onDeletePress = React.useCallback(async () => {
        if (handleDelete) {
            try {
                setIsSubmitting(true);
                await handleDelete();
                setIsSubmitting(false);
            } catch {
                setIsSubmitting(false);
            }
        }
    }, [handleDelete]);

    const formContextValue = React.useMemo<{ isSubmitting: boolean }>(() => ({ isSubmitting }), [isSubmitting]);

    return (
        <Validation>
            {(hasError: boolean) => (
                <Popup
                    {...restProps}
                    handleOk={onSubmitPress}
                    handleDelete={handleDelete ? onDeletePress : undefined}
                    okDisabled={okDisabled || hasError || isSubmitting}
                    isSubmitting={isSubmitting}
                >
                    <FormContext.Provider value={formContextValue}>
                        {children}
                    </FormContext.Provider>
                </Popup>
            )}
        </Validation>
    );
};

export default PopupForm;
