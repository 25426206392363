/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeedbackForListType } from '../../types';

type FeedbackStateType = {
    all: FeedbackForListType[],
    fetchingAll: boolean
};

const initialState: FeedbackStateType = {
    all: [],
    fetchingAll: false
};

const slice = createSlice({
    name: 'feedback',
    initialState,
    reducers: {
        fetchAll: (state) => {
            state.fetchingAll = true;
        },
        allFetched: (state, action: PayloadAction<{data: FeedbackStateType['all']}>) => {
            state.all = action.payload.data;
            state.fetchingAll = false;
        }
    }
});

export default slice;
