import React from 'react';
import { Grid } from '@mui/material';
import { createGroupInvitation, deleteGroupInvitation, updateGroupInvitation } from '../../api-admin/group-invitations';
import { GroupInvitationForListType, GroupInvitationType } from '../../types';
import toast from '../../utils/toast';
import { InfoText } from '../CommonComponents';
import {
    AutocompleteField,
    BoolField,
    EmailField,
    PhoneField,
    PopupForm
} from '../CommonComponents/Form';
import { useDispatch, useSelector } from '../../redux/hooks';
import { fetchAllTenants, selectAllTenants } from '../../redux/actions/tenants';

type PropsType = {
    invitations: GroupInvitationForListType[],
    invitation: GroupInvitationType | null,
    setInvitation: (gi: Partial<GroupInvitationType> | null, options?: { reset: boolean }) => void
    refetch: () => void
};

export default function GroupInvitationForm(props: PropsType) {
    const {
        invitations,
        invitation,
        setInvitation,
        refetch
    } = props;

    const dispatch = useDispatch();
    const tenants = useSelector(selectAllTenants());

    React.useEffect(() => {
        if (!tenants.length) {
            dispatch(fetchAllTenants());
        }
    }, [dispatch]); // Only try to fetch once if not already fetched

    const { isValidTenant, isRegisteredTenant, tenantStatusText } = React.useMemo(() => {
        if (invitation?.id) {
            return ({
                isValidTenant: true,
                isRegisteredTenant: null,
                tenantStatusText: ''
            }); // Only if edit
        }
        if (!invitation?.tenantId) {
            return ({
                isValidTenant: false,
                isRegisteredTenant: null,
                tenantStatusText: 'Välj ett företag att bjudan in.'
            });
        }
        const tenantInvitation = invitations.find((i) => i.tenantId === invitation.tenantId);
        if (tenantInvitation) {
            return ({
                isValidTenant: false,
                isRegisteredTenant: null,
                tenantStatusText: 'Det finns redan en gruppinbjudan för företaget, redigera den istället.'
            });
        }
        const tenant = tenants.find((t) => t.id === invitation.tenantId);
        if (tenant?.userCount) {
            return ({
                isValidTenant: true,
                isRegisteredTenant: true,
                // eslint-disable-next-line max-len
                tenantStatusText: 'Företaget är registrerat, välj om du vill skicka notiser till befintliga användare eller extern inbjudan via mail eller sms.'
            });
        }
        return ({
            isValidTenant: true,
            isRegisteredTenant: false,
            // eslint-disable-next-line max-len
            tenantStatusText: 'Företaget är inte registrerat, fyll i kontaktuppgifter om du vill skicka en extern inbjudan.'
        });
    }, [invitation?.id, invitation?.tenantId, invitations, tenants]);

    const submit = React.useCallback(async () => {
        if (invitation) {
            try {
                if (invitation.id) {
                    await updateGroupInvitation(invitation.id, invitation);
                    toast('Gruppinbjudan uppdaterad');
                } else {
                    await createGroupInvitation(invitation);
                    toast('Gruppinbjudan skapad');
                }
                refetch();
                setInvitation(null, { reset: true });
            } catch (e) {
                toast(e, `Kunde inte ${invitation.id ? 'redigera' : 'skapa'} gruppinbjudan, försök igen`);
            }
        }
    }, [invitation, setInvitation, refetch]);

    const remove = React.useCallback(async () => {
        if (invitation?.id) {
            try {
                await deleteGroupInvitation(invitation.id);
                toast('Gruppinbjudan borttagen');
                refetch();
                setInvitation(null, { reset: true });
            } catch (e) {
                toast(e, 'Kunde inte ta bort gruppinbjudan, försök igen');
            }
        }
    }, [invitation?.id, refetch, setInvitation]);

    const cancel = React.useCallback(() => {
        setInvitation(null, { reset: true });
    }, [setInvitation]);

    return (
        <PopupForm
            open={!!invitation}
            title={invitation?.id ? 'Redigera gruppinbjudan' : 'Skapa gruppinbjudan'}
            okLabel={invitation?.id ? 'Spara' : 'Skapa'}
            handleOk={submit}
            handleDelete={invitation?.id ? remove : undefined}
            handleClose={cancel}
            okDisabled={!isValidTenant}
        >
            <Grid container spacing={2} alignContent="space-between">
                <Grid item xs={12}>
                    <AutocompleteField<{
                        id: number | string,
                        name: string,
                        orgNumber?: string
                    }>
                        label="Företag"
                        optionIdKey="id"
                        defaultOption={!invitation?.tenantId ? null : {
                            id: invitation?.tenantId,
                            name: invitation?.tenantName || ''
                        }}
                        fetchOptions={() => { }}
                        options={tenants}
                        minQueryLength={3}
                        getOptionName={(o) => o.name}
                        getOptionDescription={(o) => o.orgNumber || ''}
                        onChange={(tenant) => setInvitation({
                            tenantId: tenant === null ? null : tenant.id,
                            tenantName: tenant === null ? null : tenant.name
                        })}
                        disabled={!!invitation?.id}
                        fetchOnlyOnce
                        required
                    />
                </Grid>

                <Grid item xs={6}>
                    <BoolField
                        name="isAccepted"
                        value={invitation?.isAccepted || false}
                        label="Accepterat"
                        onChange={setInvitation}
                    />
                </Grid>

                <Grid item xs={6}>
                    <BoolField
                        name="canPublish"
                        value={invitation?.canPublish || false}
                        label="Kan publicera"
                        onChange={setInvitation}
                    />
                </Grid>

                {!invitation?.id && (
                    <Grid item xs={12}>
                        <InfoText>
                            {tenantStatusText}
                        </InfoText>
                    </Grid>
                )}

                {!invitation?.id && isValidTenant && (
                    <>
                        {isRegisteredTenant && (
                            <Grid item xs={12}>
                                <BoolField
                                    name="sendNotifications"
                                    value={invitation?.sendNotifications || false}
                                    label="Skicka notiser till befintliga användare"
                                    onChange={setInvitation}
                                />
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <EmailField
                                name="invitedEmail"
                                label="E-postadress för extern inbjudan"
                                value={invitation?.invitedEmail || ''}
                                onChange={setInvitation}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PhoneField
                                name="invitedPhone"
                                label="Mobilnummer för extern inbjudan"
                                value={invitation?.invitedPhone || ''}
                                onChange={setInvitation}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </PopupForm>
    );
}
