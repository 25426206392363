import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Avatar,
    Button,
    Grid,
    Typography
} from '@mui/material';
import {
    Business,
    Edit
} from '@mui/icons-material';

import {
    Block,
    List,
    ToggleCheckbox
} from '../CommonComponents';

import { acceptTenant, rejectTenant } from '../../api-admin/tenants';
import { TenantType } from '../../types';
import toast from '../../utils/toast';
import { formatDateTime } from '../../utils/parsing';
import { FetchPropsType } from '../CommonComponents/Fetch/FetchPropsType';
import { fetchTenantById, selectFetchingTenantById, selectTenantById } from '../../redux/actions/tenants';
import FetchRedux from '../CommonComponents/Fetch/FetchRedux';

function TenantDetails(props: FetchPropsType<TenantType>) {
    const { entity, fetch } = props;
    const navigate = useNavigate();

    const setAccepted = React.useCallback(async (isAccepted) => {
        await acceptTenant(entity.id, isAccepted);
        fetch();
        toast(`Företaget ${isAccepted ? '' : 'ej'} accepterat`);
    }, [entity.id, fetch]);

    const setRejected = React.useCallback(async (isRejected) => {
        await rejectTenant(entity.id, isRejected);
        fetch();
        toast(`Företaget ${isRejected ? '' : 'ej'} avvisat`);
    }, [entity.id, fetch]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
                <Avatar sx={{ width: 'auto', height: '80%', maxHeight: 500 }} variant="rounded">
                    <Business sx={{ fontSize: 120, p: 2 }} />
                </Avatar>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Block
                    title="Uppgifter"
                    variant="outlined"
                    ActionComponent={(
                        <Button
                            variant="outlined"
                            startIcon={<Edit />}
                            onClick={() => navigate(`/tenants/edit/${entity.id}`)}
                            sx={{ ml: 1, mb: 1 }}
                        >
                            Redigera
                        </Button>
                    )}
                >
                    <List
                        data={[{
                            name: 'Namn',
                            value: entity.name
                        }, {
                            name: 'Org. nummer',
                            value: entity.orgNumber
                        }, {
                            name: 'Accepterat',
                            type: 'raw',
                            valuePadding: 1,
                            value: <ToggleCheckbox
                                value={entity.isAccepted}
                                onToggle={setAccepted}
                                confirm
                                confirmText={`Vill du verkligen ${entity.isAccepted ? 'av-' : ''}acceptera ${entity.name || 'företaget'}?`}
                            />
                        }, {
                            name: 'Avvisat',
                            type: 'raw',
                            valuePadding: 1,
                            value: <ToggleCheckbox
                                value={entity.isRejected}
                                onToggle={setRejected}
                                checkedColor="error"
                                confirm
                                confirmText={`Vill du verkligen ${entity.isRejected ? 'av-' : ''}avvisa ${entity.name || 'företaget'}?`}
                            />
                        }, {
                            name: 'Omsättning',
                            value: entity.revenue * 1000,
                            type: 'currency'
                        }, {
                            name: 'Antal anställda',
                            value: entity.employees,
                            type: 'number'
                        }, {
                            name: 'E-post',
                            value: entity.email
                        }, {
                            name: 'Telefon',
                            value: entity.phone
                        }, {
                            name: 'Webbsida',
                            value: entity.website
                        }, {
                            name: 'Adress',
                            value: entity.street
                        }, {
                            name: 'Postnummer',
                            value: entity.zip
                        }, {
                            name: 'Ort',
                            value: entity.city
                        }, {
                            name: 'Faktura, e-post',
                            value: entity.billingEmail
                        }, {
                            name: 'Faktura, märkning',
                            value: entity.billingMark
                        }, {
                            name: 'Faktura, address',
                            value: entity.billingStreet
                        }, {
                            name: 'Faktura, postnummer',
                            value: entity.billingZip
                        }, {
                            name: 'Faktura, ort',
                            value: entity.billingCity
                        }, {
                            name: 'Skapat',
                            value: !entity.createdAt
                                ? '' : `${formatDateTime(entity.createdAt)} ${entity.isExternallyCreated ? 'EXTERNT' : 'INTERNT'}`
                        }, {
                            name: 'Uppdaterat',
                            value: !entity.updatedAt
                                ? '' : `${formatDateTime(entity.updatedAt)} av ${entity.updatedByName || '-'}`,
                            url: entity.updatedBy ? `/users/${entity.updatedBy}` : undefined
                        }]}
                    />
                </Block>
            </Grid>
        </Grid>
    );
}

export default function TenantDetailsFetched({ hasRendered }: { hasRendered?: boolean }) {
    const { id } = useParams() as unknown as { id: number };

    return (
        <FetchRedux<TenantType>
            fetchEntity={fetchTenantById(id)}
            selectEntity={selectTenantById(id)}
            selectIsFetching={selectFetchingTenantById(id)}
            Component={TenantDetails}
            name="företaget"
            initialFetch={!hasRendered}
        />
    );
}
