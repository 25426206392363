import React from 'react';
import { RootStateType, ThunkDispatchType } from '../../../redux';
import { useDispatch, useSelector } from '../../../redux/hooks';
import { FetchPropsType } from './FetchPropsType';
import Loading from './Loading';
import NotFound from './NotFound';

type PropsType<EntityType> = {
    fetchEntity: (dispatch: ThunkDispatchType) => Promise<void>,
    selectEntity: (s: RootStateType) => EntityType | undefined,
    selectIsFetching: (s: RootStateType) => boolean,
    Component: React.FC<FetchPropsType<EntityType>>,
    name?: string,
    notFoundDescription?: string,
    initialFetch?: boolean
};

export default function FetchRedux<EntityType>(props: PropsType<EntityType>): JSX.Element {
    const {
        fetchEntity,
        selectEntity,
        selectIsFetching,
        Component,
        name,
        notFoundDescription,
        initialFetch = true,
        ...restProps
    } = props;

    const dispatch = useDispatch();
    const entity = useSelector(selectEntity);
    const isFetching = useSelector(selectIsFetching);

    const fetch = React.useCallback(async () => {
        dispatch(fetchEntity);
    }, [dispatch, fetchEntity]);

    React.useEffect(() => {
        if (initialFetch) {
            fetch();
        }
    }, [initialFetch, fetch]);

    if (!entity) {
        if (isFetching) {
            return <Loading name={name} />;
        }
        return <NotFound name={name} description={notFoundDescription} />;
    }

    return (
        <Component
            entity={entity}
            isFetching={isFetching}
            fetch={fetch}
            {...restProps}
        />
    );
}
