type CallbackType = (a: any) => any;

function Debounce(func: CallbackType, timeoutTime = 1000) {
    let timeoutId: number | undefined;

    return function debouncedFn(params: unknown) {
        if (timeoutId) {
            window.clearTimeout(timeoutId);
        }

        timeoutId = window.setTimeout(() => func(params), timeoutTime);

        return () => window.clearTimeout(timeoutId);
    };
}

const newDebounce = (func: CallbackType, timeoutTime: number) => new (Debounce as any)(func, timeoutTime);

export default newDebounce;
