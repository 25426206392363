import { createContext } from 'react';

export type FormContextValueType = {
    isSubmitting: boolean,
    submit?: null | (() => void)
};

const FormContext = createContext<FormContextValueType>({
    isSubmitting: false,
    submit: null
});

export default FormContext;
