import React from 'react';
import {
    InputLabel,
    Slider,
    styled,
    Typography
} from '@mui/material';

// import { validateNumberRange } from '../validations/validations';

type PropsType = {
    name: string,
    label: string,
    value: number,
    minValue: number,
    maxValue: number,
    step?: number,
    required?: boolean,
    // helperText?: string,
    onChange: (val: number) => void
};

const StyledSlider = styled(Slider)({
    '& .MuiSlider-valueLabel': {
        // padding: 5,
        top: 55,
        '&:before': { top: -7 },
        // backgroundColor: '#FFDDD2',
        // color: '#555'
    },
});

export default function SliderField(props: PropsType) {
    const {
        name,
        label,
        value,
        minValue,
        maxValue,
        step,
        onChange,
        required
        // ...baseProps
    } = props;

    const [val, setValue] = React.useState<number>(Math.max(Math.min(value, maxValue), minValue));

    React.useEffect(() => {
        setValue(Math.max(Math.min(value, maxValue), minValue));
    }, [value, minValue, maxValue]);

    const handleChange = React.useCallback((event, v) => {
        setValue(v);
    }, []);

    const commitChange = React.useCallback((event, v) => {
        onChange(v);
    }, []);

    const stepValue = React.useMemo(() => (
        step || Math.round((maxValue - minValue) / Math.min(100, maxValue - minValue))
    ), [minValue, maxValue, step]);

    const validations = [];
    // if (typeof minValue === 'number' || typeof maxValue === 'number') {
    //     validations.push(validateNumberRange({ val: baseProps.val, minValue, maxValue }));
    // }

    return (
        <>
            <InputLabel>
                {`${label}${required ? ' *' : ''}`}
            </InputLabel>
            <div style={{
                paddingBottom: '20px',
                paddingLeft: '30px',
                paddingRight: '30px'
            }}
            >
                <StyledSlider
                    name={name}
                    // getAriaLabel={getLabel}
                    value={val}
                    min={minValue}
                    max={maxValue}
                    step={stepValue}
                    onChange={handleChange}
                    onChangeCommitted={commitChange}
                    // valueLabelDisplay="on"
                    // valueLabelFormat={getLabel}
                    valueLabelDisplay="on"
                // getAriaValueText={getLabel}
                />
            </div>
        </>
    );
}
