import React from 'react';

import InputField from './InputField';
import { validateNumberRange } from '../validations/validations';

type PropsType = {
    minValue?: number,
    maxValue?: number,
    decimals?: number,
    name: string,
    label: string,
    value: string,
    required?: boolean,
    helperText?: string,
    onChange: (a: any) => void
};

export default function NumberField(props: PropsType) {
    const {
        minValue,
        maxValue,
        decimals = 0,
        ...baseProps
    } = props;

    const step = decimals === 0 ? '0' : (10 ** (-1 * decimals)).toString();
    const validations = [];

    if (typeof minValue === 'number' || typeof maxValue === 'number') {
        validations.push(validateNumberRange({ value: baseProps.value, minValue, maxValue }));
    }

    return (
        <InputField
            {...baseProps}
            type="number"
            inputProps={{ step }}
            validations={validations}
            emptyAsNull
        />
    );
}
