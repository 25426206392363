import React from 'react';

import AuthRouter from './AuthRouter';
import { useSelector, useDispatch } from '../../redux/hooks';

import {
    setIsLoading,
    setIsLoggedIn,
    selectIsLoading,
    selectIsLoggedIn
} from '../../redux/actions/auth';

import {
    fetchAdminProfile,
    selectAdminProfile
} from '../../redux/actions/admins';

import Loading from './Loading';

import { getCurrentUser } from '../../utils/auth';
import AuthContext, { AuthContextValueType, defaultAuthValues } from './AuthContext';
import { useChangeable } from '../CommonComponents/Form';

const Auth: React.FC = ({ children }) => {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsLoading());
    const isLoggedIn = useSelector(selectIsLoggedIn());
    const adminProfile = useSelector(selectAdminProfile());

    const [values, onChange] = useChangeable(defaultAuthValues);

    const authContextValue = React.useMemo<AuthContextValueType>(() => ({
        values, onChange
    }), [onChange, values]);

    React.useEffect(() => {
        (async () => {
            try {
                await getCurrentUser();
                dispatch(fetchAdminProfile());
                dispatch(setIsLoggedIn(true));
                dispatch(setIsLoading(false));
            } catch (error) {
                dispatch(setIsLoggedIn(false));
                dispatch(setIsLoading(false));
            }
        })();
    }, [dispatch]);

    if (isLoading) {
        return <Loading />;
    }

    if (!isLoggedIn) {
        return (
            <AuthContext.Provider value={authContextValue}>
                <AuthRouter />
            </AuthContext.Provider>
        );
    }

    if (!adminProfile) {
        return <Loading />;
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
};

export default Auth;
