import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';
import toast from '../../utils/toast';
import Popup from './Popup';

type PropsType = {
    value: boolean,
    onToggle: (newValue: boolean) => Promise<void>,
    checkedColor?: 'primary' | 'action' | 'disabled' | 'inherit' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
    confirm?: boolean,
    confirmText?: string,
    disabled?: boolean
}

export default function ToggleCheckbox({
    value,
    onToggle,
    checkedColor = 'primary',
    confirm = false,
    confirmText = 'Vill du verkligen ändra värdet?',
    disabled = false
}: PropsType) {
    const [showConfirmPopup, setShowConfirmPopup] = React.useState(false);
    const [isPending, setIsPending] = React.useState(false);

    const commit = React.useCallback(async () => {
        setIsPending(true);
        try {
            await onToggle(!value);
        } catch (e) {
            toast(e, 'Kunde inte ändra värdet, försök igen');
        }
        setIsPending(false);
        setShowConfirmPopup(false);
    }, [onToggle, value]);

    const onClick = React.useCallback(async () => {
        if (confirm) {
            setShowConfirmPopup(true);
        } else {
            commit();
        }
    }, [commit, confirm]);

    return (
        <>
            <Popup
                open={showConfirmPopup}
                title="Bekräfta ändring"
                body={confirmText}
                okLabel="Ja"
                cancelLabel="Nej"
                handleOk={commit}
                handleClose={() => setShowConfirmPopup(false)}
            />
            <IconButton aria-label="Toggle" onClick={onClick} disabled={disabled || isPending}>
                {
                    value
                        ? <CheckBox color={checkedColor} />
                        : <CheckBoxOutlineBlank />
                }
            </IconButton>
        </>
    );
}
