import apiRequest from './api-request';

const resource = 'admins';

export function getAdminProfile() {
    return apiRequest({
        method: 'GET',
        resource: 'admin-profile'
    });
}

export function getAdmins() {
    return apiRequest({
        method: 'GET',
        resource
    });
}
