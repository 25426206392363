import React, { useEffect } from 'react';
import { IconButton, Snackbar } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useSelector } from 'react-redux';

import { selectMessage } from '../../redux/actions/toast';

const Toast: React.FC = () => {
    const [open, setOpen] = React.useState(false);

    const message = useSelector(selectMessage());

    useEffect(() => {
        if (message) {
            setOpen(true);
        }
    }, [message]);

    const handleClose = (
        event?: Event | React.SyntheticEvent<any, Event> | React.MouseEvent<HTMLElement>,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message={message}
            action={(
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleClose}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
            )}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        />
    );
};

export default Toast;
