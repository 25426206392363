import requests from '../slices/requests';
import type { RootStateType, ThunkDispatchType } from '../index';

import {
    getRequests,
    getRequestsByOrderId,
    getRequestsByTenantId,
    getRequestsByUserId
} from '../../api-admin/requests';

export function fetchAllRequests() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(requests.actions.fetchAll());
            const data = await getRequests();
            dispatch(requests.actions.allFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching requests, set redux error state and handle in errorBoundary.', e);
            dispatch(requests.actions.allFetched({ data: [] }));
        }
    };
}

export function fetchRequestsByTenantId(id: number | string) {
    if (!id) {
        throw Error('No id supplied for tenant');
    }
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(requests.actions.fetchByTenantId({ id }));
            const data = await getRequestsByTenantId(id);
            dispatch(requests.actions.byTenantIdFetched({ id, data }));
        } catch (e) {
            console.log('ERROR fetching requests for tenant, set redux error state and handle in errorBoundary.', e);
            dispatch(requests.actions.byTenantIdFetched({ id, data: [] }));
        }
    };
}

export function fetchRequestsByUserId(id: number | string) {
    if (!id) {
        throw Error('No id supplied for user');
    }
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(requests.actions.fetchByUserId({ id }));
            const data = await getRequestsByUserId(id);
            dispatch(requests.actions.byUserIdFetched({ id, data }));
        } catch (e) {
            console.log('ERROR fetching requests for user, set redux error state and handle in errorBoundary.', e);
            dispatch(requests.actions.byUserIdFetched({ id, data: [] }));
        }
    };
}

export function fetchRequestsByOrderId(id: number | string) {
    if (!id) {
        throw Error('No id supplied for order');
    }
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(requests.actions.fetchByOrderId({ id }));
            const data = await getRequestsByOrderId(id);
            dispatch(requests.actions.byOrderIdFetched({ id, data }));
        } catch (e) {
            console.log('ERROR fetching requests for order, set redux error state and handle in errorBoundary.', e);
            dispatch(requests.actions.byOrderIdFetched({ id, data: [] }));
        }
    };
}

export const selectAllRequests = () => (state: RootStateType) => state.requests.all;
export const selectFetchingAllRequests = () => (state: RootStateType) => state.requests.fetchingAll;

export const selectRequestsByTenantId = (id: number | string) => (state: RootStateType) => (
    state.requests.byTenantId[id]
);
export const selectFetchingRequestsByTenantId = (id: number | string) => (state: RootStateType) => (
    state.requests.fetchingByTenantId[id]
);

export const selectRequestsByUserId = (id: number | string) => (state: RootStateType) => (
    state.requests.byUserId[id]
);
export const selectFetchingRequestsByUserId = (id: number | string) => (state: RootStateType) => (
    state.requests.fetchingByUserId[id]
);

export const selectRequestsByOrderId = (id: number | string) => (state: RootStateType) => (
    state.requests.byOrderId[id]
);
export const selectFetchingRequestsByOrderId = (id: number | string) => (state: RootStateType) => (
    state.requests.fetchingByOrderId[id]
);
