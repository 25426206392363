import React from 'react';

import { FetchPropsType } from '../CommonComponents/Fetch/FetchPropsType';
import { GroupStatsType } from '../../types';
import {
    fetchGroupStats,
    selectFetchingGroupStats,
    selectGroupStats
} from '../../redux/actions/stats';
import FetchRedux from '../CommonComponents/Fetch/FetchRedux';
import { BarChart } from '../CommonComponents/Charts';

function GroupStats(props: FetchPropsType<GroupStatsType>) {
    const { entity } = props;

    return (
        <BarChart
            title="Gruppstatistik"
            sets={[{
                label: 'Skapade grupper',
                data: entity.groupsCreatedAt
            }, {
                label: 'Skapade inbjudningar',
                data: entity.groupInvitationsCreatedAt
            }]}
        />
    );
}

export default function GroupStatsFetched({ hasRendered }: { hasRendered?: boolean }) {
    return (
        <FetchRedux<GroupStatsType>
            fetchEntity={fetchGroupStats()}
            selectEntity={selectGroupStats()}
            selectIsFetching={selectFetchingGroupStats()}
            Component={GroupStats}
            name="statistiken"
            initialFetch={!hasRendered}
        />
    );
}
