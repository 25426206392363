// @ts-nocheck
import React from 'react';
import { Provider } from 'react-redux';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import type { } from '@mui/lab/themeAugmentation';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import svLocale from 'date-fns/locale/sv';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppRouter from './AppRouter';
import Auth from '../Auth/Auth';
import Toast from '../Toast/Toast';

import store from '../../redux';

const theme = createTheme({
    palette: {
        primary: {
            main: '#004177',
            // main: '#006D77'
        },
        secondary: {
            main: '#FFDDD2',
        }
    }
});

const App: React.FC = () => {
    return (
        <React.StrictMode>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={svLocale}>
                        <CssBaseline />
                        <Toast />
                        <Auth>
                            <AppRouter />
                        </Auth>
                    </LocalizationProvider>
                </ThemeProvider>
            </Provider>
        </React.StrictMode>
    );
};

export default App;
