import React from 'react';
import {
    Container as MuiContainer
} from '@mui/material';

const Container: React.FC = ({ children }) => {
    return (
        <MuiContainer maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            {children}
        </MuiContainer>
    );
};

export default Container;
