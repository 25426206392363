import apiRequest from './api-request';

const resource = 'invites';

// eslint-disable-next-line import/prefer-default-export
export function getInvites() {
    return apiRequest({
        method: 'GET',
        resource
    });
}

export function setInviteIsHandled(ids: number[], isHandled: boolean) {
    return apiRequest({
        method: 'PUT',
        resource: `${resource}/set-is-handled`,
        body: { ids, isHandled }
    });
}
