import { Chip, Grid } from '@mui/material';
import React from 'react';
import {
    DateField,
    DateTimeField,
    NumberField,
    TextField,
    useChangeable
} from '../Form';
import SelectField from '../Form/fields/SelectField';
import Popup from '../Popup';
import SectionDivider from '../SectionDivider';

import type { ColumnType, FilterValuesType } from './Table';
import { FiltersType } from './tableFilters';

type PropsType = {
    filterColumns: ColumnType[],
    filters: FiltersType,
    filterValues: FilterValuesType,
    editFilterKey: string | null,
    setEditFilterKey: (k: string | null) => void,
    setFilterValues: (v: { [key: string]: string }) => void
}

type FilterFieldType = {
    type?: string,
    options?: {
        label: string,
        value: string
    }[]
    name: string,
    label: string,
    value: string,
    onChange: (v: any) => void
}

function FilterField(props: FilterFieldType) {
    const { type, options, ...fieldProps } = props;

    if (options) {
        return (
            <SelectField
                {...fieldProps}
                options={options}
            />
        );
    }
    if (type === 'bool') {
        return (
            <SelectField
                {...fieldProps}
                options={[
                    { label: '', value: '' },
                    { label: 'Ja', value: true },
                    { label: 'Nej', value: false },
                ]}
            />
        );
    }
    if (type === 'number') {
        return (
            <NumberField {...fieldProps} />
        );
    }
    if (type === 'date') {
        return (
            <DateField {...fieldProps} />
        );
    }
    if (type === 'datetime') {
        return (
            <DateTimeField {...fieldProps} />
        );
    }

    return (
        <TextField {...fieldProps} />
    );
}

export default function TableFilters({
    filterColumns,
    filters,
    filterValues,
    editFilterKey,
    setEditFilterKey,
    setFilterValues
}: PropsType) {
    const [entity, onChange] = useChangeable(filterValues);

    React.useEffect(() => {
        onChange(filterValues, { reset: true });
    }, [filterValues, onChange]);

    const removeFilter = React.useCallback((key: string) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [key]: omit, ...newFilterValues } = filterValues;
        setFilterValues(newFilterValues);
    }, [filterValues, setFilterValues]);

    const saveFilters = React.useCallback(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        setFilterValues(Object.fromEntries<any>(Object.entries(entity).filter(([_, v]) => (
            v !== '' && v !== null && typeof v !== 'undefined'
        ))));
        setEditFilterKey(null);
    }, [entity, setEditFilterKey, setFilterValues]);

    return (
        <>
            <Popup
                open={!!editFilterKey}
                title="Filter"
                okLabel="Spara"
                deleteLabel="Rensa"
                cancelLabel="Avbryt"
                handleOk={saveFilters}
                handleDelete={() => onChange({}, { reset: true })}
                handleClose={() => setEditFilterKey(null)}
            >
                {filterColumns.map((fc, fci) => (
                    <React.Fragment key={fc.key}>
                        <SectionDivider label={fc.name} marginTop={fci ? 8 : 0} marginBottom={1} />
                        <Grid container spacing={2} sx={{ minWidth: '500px' }}>
                            {filters.filter((f) => f.columnKey === fc.key).map((f) => (
                                <Grid item xs={6} key={f.key}>
                                    <FilterField
                                        type={fc.type}
                                        name={f.key}
                                        label={f.label}
                                        value={typeof entity[f.key] === 'undefined' ? '' : entity[f.key]}
                                        onChange={onChange}
                                        options={f.options}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </React.Fragment>
                ))}
            </Popup>

            <Grid
                container
                spacing={1}
                justifyContent="flex-start"
                alignItems="flex-start"
                alignContent="flex-end"
                sx={{ pl: 2, pr: 2 }}
            >

                {(
                    Object.entries<string>(filterValues).map(([key, value]) => (
                        <Grid item key={key}>
                            <Chip
                                label={filters.find((f) => f.key === key)?.getDescription(value) ?? '-'}
                                color="secondary"
                                onClick={() => setEditFilterKey(key)}
                                onDelete={() => removeFilter(key)}
                            />
                        </Grid>
                    ))
                )}
            </Grid>
        </>
    );
}
