// @ts-nocheck
import React from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { styled, createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Avatar, ThemeProvider, Collapse } from '@mui/material';

import {
    Menu as MenuIcon,
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';

import { selectAdminProfile } from '../../redux/actions/admins';
import { useSelector } from '../../redux/hooks';

import getMenuLinks from './menuLinks';
import HomeMenuItem from './HomeMenuItem';

const APP_BAR_MD_HEIGHT = 64;
const APP_BAR_SM_HEIGHT = 56;
const MENU_DRAWER_WIDTH = 250;

const openedMixin = (theme: any) => ({
    width: MENU_DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: any) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(9)} + 1px)`,
    // width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.down('md')]: {
        width: 0,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: MENU_DRAWER_WIDTH,
        width: `calc(100% - ${MENU_DRAWER_WIDTH}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: MENU_DRAWER_WIDTH,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const theme = createTheme({
    palette: {
        background: {
            // paper: '#5f6d45',
            paper: '#004177', // Blue
            active: '#005187' // Skin
        },
        text: {
            primary: '#FFFFFF', // White
            active: '#FFDDD2' // Skin
        },
        primary: {
            // main: '#5f6d45',
            main: '#004177' // Blue
        },
        secondary: {
            main: '#FFDDD2', // Skin
        }
    }
});

export default function Layout() {
    const [open, setOpen] = React.useState(true);
    const [openSubmenus, setOpenSubmenus] = React.useState<Record<number, boolean>>({});
    const navigate = useNavigate();

    const adminProfile = useSelector(selectAdminProfile());

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const { pathname } = useLocation();

    const links = React.useMemo(
        () => getMenuLinks({ canReadStats: adminProfile?.canReadStats }),
        [adminProfile?.canReadStats]
    );

    const isActiveLink = React.useCallback((linkPath: string) => {
        // const currentLink = React.useMemo(
        //     () => links.flat().find((link) => link.path === pathname),
        //     [pathname]
        // );
        return (
            linkPath === '/'
                ? pathname === linkPath
                : pathname.startsWith(linkPath)
        );
    }, [pathname]);

    const getTextColor = React.useCallback((linkPath: string) => {
        return isActiveLink(linkPath) ? theme.palette.text.active : theme.palette.text.primary;
    }, [isActiveLink]);

    const getBackgroundColor = React.useCallback((linkPath: string) => {
        return isActiveLink(linkPath) ? theme.palette.background.active : theme.palette.background.paper;
    }, [isActiveLink]);

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar
                position="fixed"
                open={open}
                sx={{
                    display: 'none',
                    [theme.breakpoints.down('md')]: {
                        display: 'block',
                    }
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                        onClick={toggleDrawer}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <ThemeProvider theme={theme}>
                <Drawer variant="permanent" open={open}>
                    <DrawerHeader
                        onClick={toggleDrawer}
                        sx={{
                            justifyContent: 'space-between',
                            ':hover': { cursor: 'pointer' }
                        }}
                    >
                        <Avatar
                            variant="square"
                            sx={{
                                m: 1,
                                width: 40,
                                height: 40
                            }}
                            src="/logiclink_logo_white.png"
                            onClick={toggleDrawer}
                        />
                        <IconButton sx={{ color: theme.palette.text.primary }}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </DrawerHeader>

                    <Divider />

                    <List disablePadding>
                        <HomeMenuItem
                            backgroundColor={getBackgroundColor('/')}
                            textColor={getTextColor('/')}
                            onClick={() => navigate('/')}
                        />
                        {links.map((link) => (
                            link.links
                                ? (
                                    <React.Fragment key={link.name}>
                                        <ListItemButton
                                            onClick={() => setOpenSubmenus({
                                                ...openSubmenus,
                                                [link.key]: !openSubmenus[link.key]
                                            })}
                                        >
                                            <ListItemIcon sx={{ color: getTextColor(link.path) }}>
                                                {link.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={link.name} sx={{ color: getTextColor(link.path) }} />
                                            {openSubmenus[link.key] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </ListItemButton>
                                        <Collapse in={openSubmenus[link.key]} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                {link.links.map((sublink) => (
                                                    <ListItemButton
                                                        key={sublink.name}
                                                        onClick={() => (sublink.action ? sublink.action() : navigate(sublink.path))}
                                                        sx={{ pl: 4, background: getBackgroundColor(sublink.path) }}
                                                    >
                                                        <ListItemIcon sx={{ color: getTextColor(sublink.path) }}>
                                                            {sublink.icon}
                                                        </ListItemIcon>
                                                        <ListItemText primary={sublink.name} sx={{ color: getTextColor(sublink.path) }} />
                                                    </ListItemButton>
                                                ))}
                                            </List>
                                        </Collapse>
                                    </React.Fragment>
                                ) : (
                                    <ListItemButton
                                        key={link.name}
                                        onClick={() => (link.action ? link.action() : navigate(link.path))}
                                        sx={{ background: getBackgroundColor(link.path) }}
                                    >
                                        <ListItemIcon sx={{ color: getTextColor(link.path) }}>
                                            {link.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={link.name} sx={{ color: getTextColor(link.path) }} />
                                    </ListItemButton>
                                )
                        ))}
                    </List>
                </Drawer>
            </ThemeProvider>

            <Box sx={{ flexGrow: 1, background: '#F0F0F0' }}>
                <DrawerHeader
                    sx={{
                        display: 'none',
                        [theme.breakpoints.down('md')]: {
                            display: 'block',
                        }
                    }}
                />
                <Box
                    sx={{
                        height: '100vh',
                        overflow: 'auto',
                        [theme.breakpoints.down('md')]: {
                            height: `calc(100vh - ${APP_BAR_MD_HEIGHT}px)`
                        },
                        [theme.breakpoints.down('sm')]: {
                            height: `calc(100vh - ${APP_BAR_SM_HEIGHT}px)`
                        }
                    }}
                >
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
}
