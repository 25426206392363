import React, { useContext } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import FormContext from '../FormContext';
import useValidation from '../hooks/useValidation';
import { validateDate, validateDateRange, validateRequired } from '../validations/validations';
import type { ValidationType } from '../validations/ValidationContext';
import { Input } from '../../../StyledComponents';
import { formatDate } from '../../../../utils/parsing';

type PropsType = {
    name: string,
    label: string,
    value: string | Date,
    onChange: (a: any) => void,
    required?: boolean,
    minDate?: string,
    maxDate?: string,
    helperText?: string
};

const DateField: React.FC<PropsType> = (props) => {
    const {
        name,
        label,
        value,
        onChange,
        required,
        minDate,
        maxDate,
        helperText
    } = props;

    const formContext = useContext(FormContext);
    const isSubmitting = formContext?.isSubmitting;

    const validations = [
        ...(typeof required !== 'undefined'
            ? [validateRequired({ value: !required || value })]
            : []
        ),
        validateDate({ value }),
        validateDateRange({ value, minDate, maxDate })
    ];

    const errors = validations
        // useValidation registers the validation and returns isValid for filter
        // eslint-disable-next-line react-hooks/rules-of-hooks
        .filter((v: ValidationType) => useValidation({ name, type: v.type, isValid: v.isValid }));

    const errorMessage = errors.map((v: ValidationType) => v.errorText).join(' ');

    return (
        <DatePicker
            label={`${label}${required ? ' *' : ''}`}
            value={value || null}
            onChange={(newValue: string | Date | null) => {
                onChange({ [name]: newValue ? formatDate(newValue) : null });
            }}
            mask="____-__-__"
            renderInput={(params: any) => (
                <Input
                    {...params}
                    inputProps={{ ...params.inputProps, placeholder: 'åååå-mm-dd' }}
                    autoComplete="off"
                    error={!!errors.length}
                    helperText={errorMessage || helperText}
                />
            )}
            disabled={isSubmitting}
            minDate={minDate ? new Date(minDate) : undefined}
            maxDate={maxDate ? new Date(maxDate) : undefined}
        />
    );
};

export default DateField;
