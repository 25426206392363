import {
    Avatar,
    Container,
    Grid,
    Link,
    Typography
} from '@mui/material';
import React from 'react';

const AuthContainer: React.FC = ({ children }) => {
    const year = React.useMemo(() => new Date().getFullYear(), []);

    return (
        <Container component="main" maxWidth="xs" sx={{ p: 4 }}>
            <Avatar
                sx={{
                    mt: 2,
                    mb: 6,
                    ml: 'auto',
                    mr: 'auto',
                    width: 200,
                    height: 200,
                    bgcolor: 'white'
                }}
                src="/logiclink_logo_new.jpg"
            />

            {children}

            <Grid container justifyContent="space-between">
                <Grid item xs>
                    <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 4 }}>
                        Copyright ©
                        <Link color="inherit" href="https://logiclink.se/" target="_blank">
                            Logic Link AB
                        </Link>
                        {` ${year}.`}
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
};

export default AuthContainer;
