/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GroupForListType, GroupMetaType, GroupType } from '../../types';
import { GroupInvitationForListType } from '../../types/GroupInvitationForListType';

type GroupsStateType = {
    all: GroupForListType[],
    byTenantId: {[key: number | string]: GroupForListType[] },
    fetchingByTenantId: {[key: number | string]: boolean}
    fetchingAll: boolean,
    byId: {[key: number | string]: GroupType | undefined },
    fetchingById: {[key: number | string]: boolean},
    metaById: {[key: number | string]: GroupMetaType | undefined },
    fetchingMetaById: {[key: number | string]: boolean}
    groupInvitationsByGroupId: {[key: number | string]: GroupInvitationForListType[] | undefined },
    fetchingGroupInvitationsByGroupId: {[key: number | string]: boolean}
};

const initialState: GroupsStateType = {
    all: [],
    fetchingAll: false,
    byTenantId: {},
    fetchingByTenantId: {},
    byId: {},
    fetchingById: {},
    metaById: {},
    fetchingMetaById: {},
    groupInvitationsByGroupId: {},
    fetchingGroupInvitationsByGroupId: {}
};

const slice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        fetchAll: (state) => {
            state.fetchingAll = true;
        },
        allFetched: (state, action: PayloadAction<{data: GroupsStateType['all']}>) => {
            state.all = action.payload.data;
            state.fetchingAll = false;
        },
        fetchByTenantId: (state, action) => {
            state.fetchingByTenantId[action.payload.id] = true;
        },
        byTenantIdFetched: (state, action: PayloadAction<{ id: number | string, data: GroupForListType[] }>) => {
            state.byTenantId[action.payload.id] = action.payload.data;
            state.fetchingByTenantId[action.payload.id] = false;
        },
        fetchById: (state, action) => {
            state.fetchingById[action.payload.id] = true;
        },
        byIdFetched: (state, action: PayloadAction<{ id: number | string, data?: GroupType }>) => {
            state.byId[action.payload.id] = action.payload.data;
            state.fetchingById[action.payload.id] = false;
        },
        fetchMetaById: (state, action) => {
            state.fetchingMetaById[action.payload.id] = true;
        },
        metaByIdFetched: (state, action: PayloadAction<{ id: number | string, data?: GroupMetaType }>) => {
            state.metaById[action.payload.id] = action.payload.data;
            state.fetchingMetaById[action.payload.id] = false;
        },
        fetchGroupInvitationsByGroupId: (state, action) => {
            state.fetchingGroupInvitationsByGroupId[action.payload.id] = true;
        },
        groupInvitationsByGroupIdFetched: (state, action: PayloadAction<{
            id: number | string,
            data: GroupInvitationForListType[]
        }>) => {
            state.groupInvitationsByGroupId[action.payload.id] = action.payload.data;
            state.fetchingGroupInvitationsByGroupId[action.payload.id] = false;
        }
    }
});

export default slice;
