/* eslint-disable no-param-reassign */
// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TabActionType = {
    tabsKey: string,
    activeTabKey: string
};

type TabStateType = {
    activeTab: {[k: string]: string}
};

const initialState: TabStateType = {
    activeTab: {}
};

const slice = createSlice({
    name: 'tabs',
    initialState,
    reducers: {
        setActiveTab: (state, action: PayloadAction<TabActionType>) => {
            const { tabsKey, activeTabKey } = action.payload;
            state.activeTab[tabsKey] = activeTabKey;
        }
    }
});

export default slice;
