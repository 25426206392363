import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Avatar,
    Button,
    Grid,
    Typography
} from '@mui/material';
import {
    Edit,
    Password,
    Person
} from '@mui/icons-material';

import {
    Block,
    List,
    ToggleCheckbox
} from '../CommonComponents';

import { FetchPropsType } from '../CommonComponents/Fetch/FetchPropsType';
import {
    setUserAdmin,
    setUserEmailVerified,
    setUserPassword,
    setUserPhoneVerified,
    updateUser
} from '../../api-admin/users';
import { UserType } from '../../types';
import toast from '../../utils/toast';
import { fetchUserById, selectFetchingUserById, selectUserById } from '../../redux/actions/users';
import FetchRedux from '../CommonComponents/Fetch/FetchRedux';
import { PasswordField, PopupForm } from '../CommonComponents/Form';
import { validatePhone } from '../CommonComponents/Form/validations/validations';
import { formatDateTime } from '../../utils/parsing';

function UserDetails(props: FetchPropsType<UserType>) {
    const { entity, fetch } = props;
    const navigate = useNavigate();

    const [showChangePasswordPopup, setShowChangePasswordPopup] = React.useState(false);
    const [newPassword, setNewPassword] = React.useState('');

    const setIsAdmin = React.useCallback(async (isAdmin) => {
        await setUserAdmin(entity.id, isAdmin);
        fetch();
        toast(`Användaren är ${isAdmin ? '' : 'ej'} admin`);
    }, [entity.id, fetch]);

    const setEmailVerified = React.useCallback(async (emailVerified) => {
        await setUserEmailVerified(entity.id, emailVerified);
        fetch();
        toast(`E-postadressen är markerad som ${emailVerified ? '' : 'ej'} bekräftad`);
    }, [entity.id, fetch]);

    const setPhoneVerified = React.useCallback(async (phoneVerified) => {
        await setUserPhoneVerified(entity.id, phoneVerified);
        fetch();
        toast(`Mobilnumret är markerat som ${phoneVerified ? '' : 'ej'} bekräftat`);
    }, [entity.id, fetch]);

    const setPushNotifications = React.useCallback(async (pushNotifications) => {
        await updateUser(entity.id, { pushNotifications });
        fetch();
        toast(`Pushnotiser är ${pushNotifications ? 'aktiverade' : 'avstängda'}`);
    }, [entity.id, fetch]);

    const setEmailNotifications = React.useCallback(async (emailNotifications) => {
        await updateUser(entity.id, { emailNotifications });
        fetch();
        toast(`E-postnotiser är ${emailNotifications ? 'aktiverade' : 'avstängda'}`);
    }, [entity.id, fetch]);

    const setSmsNotifications = React.useCallback(async (smsNotifications) => {
        await updateUser(entity.id, { smsNotifications });
        fetch();
        toast(`SMS-notiser är ${smsNotifications ? 'aktiverade' : 'avstängda'}`);
    }, [entity.id, fetch]);

    const changePassword = React.useCallback(async () => {
        try {
            await setUserPassword(entity.id, newPassword);
            toast('Lösenordet ändrat');
            setShowChangePasswordPopup(false);
            setNewPassword('');
        } catch (e) {
            toast(e, 'Kunde inte ändra lösenord, försök igen');
        }
    }, [entity.id, newPassword]);

    return (
        <>
            <PopupForm
                open={showChangePasswordPopup}
                title="Ändra lösenord"
                okLabel="Ändra"
                cancelLabel="Avbryt"
                handleOk={changePassword}
                handleClose={() => {
                    setShowChangePasswordPopup(false);
                    setNewPassword('');
                }}
            >
                <Grid container spacing={2} sx={{ mt: 0, minWidth: 350 }}>
                    <Grid item xs={12}>
                        <PasswordField
                            name="newPassword"
                            label="Nytt lösenord"
                            autoComplete="off"
                            value={newPassword}
                            onChange={({ target: { value } }) => {
                                setNewPassword(value);
                            }}
                            variant="outlined"
                            submitOnEnter
                        />
                    </Grid>
                </Grid>
            </PopupForm>

            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <Avatar sx={{ width: 'auto', height: '80%', maxHeight: 500 }} variant="rounded">
                        <Person sx={{ fontSize: 120, p: 2 }} />
                    </Avatar>
                </Grid>

                <Grid item xs={12} lg={6}>
                    <Block
                        variant="outlined"
                        title="Grunduppgifter"
                        ActionComponent={(
                            <>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<Password />}
                                    onClick={() => setShowChangePasswordPopup(true)}
                                    sx={{ ml: 1, mb: 1 }}
                                >
                                    Byt lösenord
                                </Button>
                                <Button
                                    variant="outlined"
                                    startIcon={<Edit />}
                                    onClick={() => navigate(`/users/edit/${entity.id}`)}
                                    sx={{ ml: 1, mb: 1 }}
                                >
                                    Redigera
                                </Button>
                            </>
                        )}
                    >
                        <List
                            data={[{
                                name: 'Företag',
                                value: entity.tenantName,
                                url: `/tenants/${entity.tenantId}`
                            }, {
                                name: 'Förnamn',
                                value: entity.firstName
                            }, {
                                name: 'Efternamn',
                                value: entity.lastName
                            }, {
                                name: 'Administratör',
                                type: 'raw',
                                valuePadding: 1,
                                value: <ToggleCheckbox
                                    value={entity.isAdmin}
                                    onToggle={setIsAdmin}
                                    confirm
                                    confirmText={
                                        entity.isAdmin
                                            ? 'Vill du verkligen ta bort admin-behörigheten för användaren?'
                                            : 'Vill du verkligen ge användaren admin-behörigheter?'
                                    }
                                />
                            }, {
                                name: 'E-post',
                                type: 'raw',
                                valuePadding: 1,
                                value: (
                                    <>
                                        <ToggleCheckbox
                                            value={entity.emailVerified}
                                            onToggle={setEmailVerified}
                                            confirm
                                            confirmText={
                                                `Vill du verkligen markera e-postadressen som ${entity.emailVerified ? 'ej ' : ''} bekräftad?`
                                            }
                                        />
                                        <Typography display="inline">{entity.email}</Typography>
                                    </>
                                )
                            }, {
                                name: 'Mobil',
                                type: 'raw',
                                valuePadding: 1,
                                value: (
                                    <>
                                        <ToggleCheckbox
                                            value={entity.phoneVerified}
                                            onToggle={setPhoneVerified}
                                            disabled={!entity.phone || !validatePhone({ value: entity.phone }).isValid}
                                            confirm
                                            confirmText={
                                                `Vill du verkligen markera mobilnumret som ${entity.phoneVerified ? 'ej ' : ''} bekräftat?`
                                            }
                                        />
                                        <Typography display="inline">{entity.phone}</Typography>
                                    </>
                                )
                            }, {
                                name: 'Pushnotiser',
                                type: 'raw',
                                valuePadding: 1,
                                value: <ToggleCheckbox
                                    value={entity.pushNotifications}
                                    onToggle={setPushNotifications}
                                    confirm
                                    confirmText={
                                        `Vill du verkligen ${entity.pushNotifications ? 'stänga av' : 'aktivera'} pushnotiser?`
                                    }
                                />
                            }, {
                                name: 'E-postnotiser',
                                type: 'raw',
                                valuePadding: 1,
                                value: <ToggleCheckbox
                                    value={entity.emailNotifications}
                                    onToggle={setEmailNotifications}
                                    confirm
                                    confirmText={
                                        `Vill du verkligen ${entity.emailNotifications ? 'stänga av' : 'aktivera'} e-postnotiser?`
                                    }
                                />
                            }, {
                                name: 'SMS-notiser',
                                type: 'raw',
                                valuePadding: 1,
                                value: <ToggleCheckbox
                                    value={entity.smsNotifications}
                                    onToggle={setSmsNotifications}
                                    confirm
                                    confirmText={
                                        `Vill du verkligen ${entity.smsNotifications ? 'stänga av' : 'aktivera'} SMS-notiser?`
                                    }
                                />
                            }, {
                                name: 'Skapad',
                                value: !entity.createdAt
                                    ? '' : `${formatDateTime(entity.createdAt)} av ${entity.createdByName || '-'}`,
                                url: entity.createdBy && entity.createdBy !== entity.id ? `/users/${entity.createdBy}` : undefined
                            }, {
                                name: 'Uppdaterad',
                                value: !entity.updatedAt
                                    ? '' : `${formatDateTime(entity.updatedAt)} av ${entity.updatedByName || '-'}`,
                                url: entity.updatedBy && entity.updatedBy !== entity.id ? `/users/${entity.updatedBy}` : undefined
                            }]}
                        />
                    </Block>
                </Grid>
            </Grid>
        </>
    );
}

export default function UserDetailsFetched({ hasRendered }: { hasRendered?: boolean }) {
    const { id } = useParams() as unknown as { id: number };

    return (
        <FetchRedux<UserType>
            fetchEntity={fetchUserById(id)}
            selectEntity={selectUserById(id)}
            selectIsFetching={selectFetchingUserById(id)}
            Component={UserDetails}
            name="användaren"
            initialFetch={!hasRendered}
        />
    );
}
