import {
    ChevronLeft,
    ChevronRight,
    FirstPage,
    LastPage
} from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import React from 'react';

type PropsType = {
    page: number,
    firstPage: number,
    lastPage: number,
    rowsPerPage: number,
    rowCount: number,
    setPage: (n: number) => void
}

export default function TablePagination({
    page,
    firstPage,
    lastPage,
    rowsPerPage,
    rowCount,
    setPage
}: PropsType) {
    const cappedPage = React.useMemo(() => (
        Math.max(
            Math.min(
                page,
                lastPage
            ),
            firstPage
        )
    ), [firstPage, lastPage, page]);

    const firstNumber = React.useMemo(() => (
        rowCount === 0
            ? 0
            : rowsPerPage * (cappedPage - 1) + 1
    ), [cappedPage, rowCount, rowsPerPage]);

    const lastNumber = React.useMemo(() => (
        Math.min(rowsPerPage * cappedPage, rowCount)
    ), [cappedPage, rowCount, rowsPerPage]);

    const setPrevPage = React.useCallback(() => {
        if (cappedPage > firstPage) {
            setPage(cappedPage - 1);
        }
    }, [cappedPage, firstPage, setPage]);

    const setNextPage = React.useCallback(() => {
        if (cappedPage < lastPage) {
            setPage(cappedPage + 1);
        }
    }, [cappedPage, lastPage, setPage]);

    const setFirstPage = React.useCallback(() => {
        if (cappedPage !== firstPage) {
            setPage(1);
        }
    }, [cappedPage, firstPage, setPage]);

    const setLastPage = React.useCallback(() => {
        if (cappedPage !== lastPage) {
            setPage(lastPage);
        }
    }, [cappedPage, lastPage, setPage]);

    return (
        <>
            <Typography variant="body2" component="span" sx={{ pt: 2 }}>
                {`${firstNumber} - ${lastNumber} av ${rowCount}`}
            </Typography>
            <IconButton disabled={cappedPage <= 1} onClick={setFirstPage}>
                <FirstPage />
            </IconButton>
            <IconButton disabled={cappedPage <= 1} onClick={setPrevPage}>
                <ChevronLeft />
            </IconButton>
            <IconButton disabled={cappedPage >= lastPage} onClick={setNextPage}>
                <ChevronRight />
            </IconButton>
            <IconButton disabled={cappedPage >= lastPage} onClick={setLastPage}>
                <LastPage />
            </IconButton>
        </>
    );
}
