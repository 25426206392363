import React from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';

import { Paper } from '../StyledComponents';
import { useDispatch, useSelector } from '../../redux/hooks';
import { selectActiveTab, setActiveTab } from '../../redux/actions/tabs';

type TabType = {
    key: string,
    label: string,
    Component: React.FC<any>,
    padding?: number
};

type PropsType = {
    tabsKey: string,
    tabs: TabType[],
    getTitle: (t: TabType) => string
};

export default function Tabs(props: PropsType) {
    const { tabsKey, tabs, getTitle } = props;

    const dispatch = useDispatch();
    const activeTab = useSelector(selectActiveTab(tabsKey)) || tabs[0].key;

    const changeTab = React.useCallback(async (event, activeTabKey) => {
        dispatch(setActiveTab({ tabsKey, activeTabKey }));
    }, [dispatch, tabsKey]);

    const hasRendered = React.useRef<{[k: string]: boolean}>({});

    React.useEffect(() => {
        hasRendered.current[activeTab] = true;
    }, [activeTab]);

    return (
        <TabContext value={activeTab}>
            <TabList
                value={activeTab}
                onChange={changeTab}
                variant="fullWidth"
            >
                {tabs.map((tab) => (
                    <Tab
                        key={tab.key}
                        value={tab.key}
                        label={getTitle(tab)}
                    />
                ))}
            </TabList>
            <Paper padding={0}>
                {tabs.map((tab) => (
                    <TabPanel sx={{ p: tab.padding ?? 0 }} value={tab.key} key={tab.key}>
                        <tab.Component hasRendered={hasRendered.current[tab.key]} />
                    </TabPanel>
                ))}
            </Paper>
        </TabContext>
    );
}
