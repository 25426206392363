import apiRequest from './api-request';

const resource = 'dashboard';

// eslint-disable-next-line import/prefer-default-export
export function getData() {
    return apiRequest({
        method: 'GET',
        resource
    });
}
