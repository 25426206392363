import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    Divider,
    Grid,
    Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
    ArrowBack as ArrowBackIcon,
    Delete as DeleteIcon,
    Save as SaveIcon
} from '@mui/icons-material';

import { Container, Paper } from '../../StyledComponents';
import FormContext from './FormContext';
import Validation from './validations/ValidationContext';
import Popup from '../Popup';

type EntityType = {
    [key: string]: any
};

type PropsType = {
    title: string,
    entity: EntityType,
    onSubmit: (entity: EntityType) => void,
    submitText?: string,
    submitStartIcon?: JSX.Element,
    submitEndIcon?: JSX.Element,
    submitDisabled?: boolean
    onAltSubmit?: (entity: EntityType) => void,
    altSubmitText?: string,
    altSubmitIcon?: JSX.Element,
    onDelete?: () => void,
    showBottomSave?: boolean
};

const FullForm: React.FC<PropsType> = (props) => {
    const {
        title,
        children,
        entity,
        onDelete,
        onSubmit,
        submitText = 'Spara',
        submitStartIcon,
        submitEndIcon,
        submitDisabled,
        onAltSubmit,
        altSubmitText = 'Spara',
        altSubmitIcon,
        showBottomSave = false
    } = props;

    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [showDeletePopup, setShowDeletePopup] = React.useState(false);

    // Errors are handled in onSubmit
    const onSubmitPress = React.useCallback(async () => {
        setIsSubmitting(true);
        await onSubmit(entity);
        setIsSubmitting(false);
    }, [entity, onSubmit]);

    // Errors are handled in onAltSubmit
    const onAltSubmitPress = React.useCallback(async () => {
        if (onAltSubmit) {
            setIsSubmitting(true);
            await onAltSubmit(entity);
            setIsSubmitting(false);
        }
    }, [entity, onAltSubmit]);

    const confirmDelete = React.useCallback(async () => {
        if (onDelete) {
            setIsSubmitting(true);
            await onDelete();
            setIsSubmitting(false);
            setShowDeletePopup(false);
        }
    }, [onDelete]);

    const formContextValue = React.useMemo<{ isSubmitting: boolean }>(() => ({ isSubmitting }), [isSubmitting]);

    return (
        <Container>
            <Popup
                open={showDeletePopup}
                title="Ta bort?"
                body="Vill du verkligen ta bort?"
                okLabel="Ja"
                cancelLabel="Nej"
                handleOk={confirmDelete}
                handleClose={() => setShowDeletePopup(false)}
                isSubmitting={isSubmitting}
            />
            <Validation>
                {(hasError: boolean) => (
                    <div>
                        <Grid container spacing={1} sx={{ pb: 2 }} alignItems="flex-end">
                            <Grid item xs>
                                <Typography variant="h4" noWrap gutterBottom>
                                    {title}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button variant="text" startIcon={<ArrowBackIcon />} onClick={() => navigate(-1)}>
                                    Tillbaka
                                </Button>
                            </Grid>
                            {!!onDelete && (
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        startIcon={<DeleteIcon />}
                                        color="error"
                                        onClick={() => setShowDeletePopup(true)}
                                        disabled={isSubmitting}
                                    >
                                        Ta bort
                                    </Button>
                                </Grid>
                            )}
                            {!!onAltSubmit && (
                                <Grid item>
                                    <LoadingButton
                                        variant="outlined"
                                        onClick={onAltSubmitPress}
                                        loading={isSubmitting}
                                        startIcon={(altSubmitIcon || <SaveIcon />)}
                                        disabled={submitDisabled || isSubmitting || hasError}
                                    >
                                        {altSubmitText}
                                    </LoadingButton>
                                </Grid>
                            )}
                            <Grid item>
                                <LoadingButton
                                    variant="contained"
                                    onClick={onSubmitPress}
                                    loading={isSubmitting}
                                    loadingPosition={submitEndIcon ? 'end' : 'start'}
                                    startIcon={submitEndIcon ? undefined : (submitStartIcon || <SaveIcon />)}
                                    endIcon={submitEndIcon}
                                    disabled={submitDisabled || isSubmitting || hasError}
                                >
                                    {submitText}
                                </LoadingButton>
                            </Grid>
                        </Grid>

                        <Paper>
                            <FormContext.Provider value={formContextValue}>
                                {children}
                            </FormContext.Provider>

                            {showBottomSave && (
                                <>
                                    <Divider sx={{ marginTop: 4, marginBottom: 2 }} />
                                    <Grid container spacing={1} alignItems="flex-end" justifyContent="flex-end">
                                        {!!onAltSubmit && (
                                            <Grid item>
                                                <LoadingButton
                                                    variant="outlined"
                                                    onClick={onAltSubmitPress}
                                                    loading={isSubmitting}
                                                    startIcon={(altSubmitIcon || <SaveIcon />)}
                                                    disabled={submitDisabled || isSubmitting || hasError}
                                                >
                                                    {altSubmitText}
                                                </LoadingButton>
                                            </Grid>
                                        )}
                                        <Grid item>
                                            <LoadingButton
                                                variant="contained"
                                                onClick={onSubmitPress}
                                                loading={isSubmitting}
                                                loadingPosition={submitEndIcon ? 'end' : 'start'}
                                                startIcon={submitEndIcon ? undefined : (submitStartIcon || <SaveIcon />)}
                                                endIcon={submitEndIcon}
                                                disabled={submitDisabled || isSubmitting || hasError}
                                            >
                                                {submitText}
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Paper>
                    </div>
                )}
            </Validation>
        </Container>

    );
};

export default FullForm;
