import tenants from '../slices/tenants';
import type { RootStateType, ThunkDispatchType } from '../index';

import {
    getTenant,
    getTenantMeta,
    getTenants
} from '../../api-admin/tenants';

export function fetchAllTenants() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(tenants.actions.fetchAll());
            const data = await getTenants();
            dispatch(tenants.actions.allFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching tenants, set redux error state and handle in errorBoundary.', e);
            dispatch(tenants.actions.allFetched({ data: [] }));
        }
    };
}

export function fetchTenantById(id: number | string) {
    if (!id) {
        throw Error('No id supplied for tenant');
    }
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(tenants.actions.fetchById({ id }));
            const data = await getTenant(id);
            dispatch(tenants.actions.byIdFetched({ id, data }));
        } catch (e) {
            console.log('ERROR fetching tenant, set redux error state and handle in errorBoundary.', e);
            dispatch(tenants.actions.byIdFetched({ id }));
        }
    };
}

export function fetchTenantMetaById(id: number | string) {
    if (!id) {
        throw Error('No id supplied for tenant');
    }
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(tenants.actions.fetchMetaById({ id }));
            const data = await getTenantMeta(id);
            dispatch(tenants.actions.metaByIdFetched({ id, data }));
        } catch (e) {
            console.log('ERROR fetching tenant meta, set redux error state and handle in errorBoundary.', e);
            dispatch(tenants.actions.metaByIdFetched({ id }));
        }
    };
}

export const selectAllTenants = () => (state: RootStateType) => state.tenants.all;
export const selectFetchingAllTenants = () => (state: RootStateType) => state.tenants.fetchingAll;

export const selectTenantById = (id: number | string) => (state: RootStateType) => (
    state.tenants.byId[id]
);
export const selectFetchingTenantById = (id: number | string) => (state: RootStateType) => (
    state.tenants.fetchingById[id]
);

export const selectTenantMetaById = (id: number | string) => (state: RootStateType) => (
    state.tenants.metaById[id]
);
export const selectFetchingTenantMetaById = (id: number | string) => (state: RootStateType) => (
    state.tenants.fetchingMetaById[id]
);

