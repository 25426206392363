import React from 'react';
import { Grid } from '@mui/material';

import toast from '../../utils/toast';
import { SimpleForm, PopupForm, TextField } from '../CommonComponents/Form';
import AuthContext from './AuthContext';
import AuthContainer from './AuthContainer';

import { signIn, confirmSignIn } from '../../utils/auth';
import login from '../../utils/login';

export default function SignIn() {
    const {
        values: {
            email,
            password,
            code
        },
        onChange
    } = React.useContext(AuthContext);

    const [user, setUser] = React.useState(null);

    const tryLogin = React.useCallback(async () => {
        if (!email) {
            toast('Kontrollera din e-postadress');
            return;
        }
        if (!password) {
            toast('Kontrollera ditt lösenord');
            return;
        }

        try {
            const data = await signIn({ email, password });
            setUser(data);
        } catch (error) {
            toast('Fel inloggningsuppgifter, försök igen');
        }
    }, [email, password]);

    const confirmLogin = React.useCallback(async () => {
        if (!code) {
            toast('Saknar MFA-kod');
            return;
        }

        try {
            await confirmSignIn({ user, code });
            onChange({ code: '', password: '' });
            login();
        } catch (error) {
            toast('Fel MFA-kod, försök igen');
        }
    }, [code, onChange, user]);

    return (
        <AuthContainer>
            <SimpleForm
                submitText="Logga in"
                onSubmit={tryLogin}
            >
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12}>
                        <TextField
                            name="email"
                            label="E-post"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={onChange}
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            name="password"
                            label="Lösenord"
                            autoComplete="current-password"
                            value={password}
                            onChange={onChange}
                            type="password"
                            variant="outlined"
                            submitOnEnter
                        />
                    </Grid>
                </Grid>
            </SimpleForm>
            <PopupForm
                open={!!user}
                title="Multifaktorautentisering"
                handleClose={() => {
                    setUser(null);
                    onChange({ code: '' });
                }}
                handleOk={confirmLogin}
            >
                <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={12}>
                        <TextField
                            name="code"
                            label="MFA-kod"
                            autoComplete="code"
                            value={code}
                            onChange={onChange}
                            variant="outlined"
                            submitOnEnter
                        />
                    </Grid>
                </Grid>
            </PopupForm>
        </AuthContainer>
    );
}
