import apiRequest from './api-request';

const resource = 'users';

export function getUsers() {
    return apiRequest({
        method: 'GET',
        resource
    });
}

export function getUsersByTenantId(id: number | string) {
    return apiRequest({
        method: 'GET',
        resource: `tenants/${id}/${resource}`
    });
}

export function getUser(id: number | string) {
    return apiRequest({
        method: 'GET',
        resource,
        id
    });
}

export function getUserMeta(id: number | string) {
    return apiRequest({
        method: 'GET',
        resource: `${resource}/${id}/meta`
    });
}

export function createUser(body: any) {
    return apiRequest({
        method: 'POST',
        resource,
        body
    });
}

export function setUserPassword(id: number | string, newPassword: string) {
    return apiRequest({
        method: 'PUT',
        resource: `${resource}/${id}/password`,
        body: { newPassword }
    });
}

export function setUserAdmin(id: number | string, isAdmin: boolean) {
    return apiRequest({
        method: 'PUT',
        resource: `${resource}/${id}/admin`,
        body: { isAdmin }
    });
}

export function setUserEmailVerified(id: number | string, emailVerified: boolean) {
    return apiRequest({
        method: 'PUT',
        resource: `${resource}/${id}/email-verified`,
        body: { emailVerified }
    });
}

export function setUserPhoneVerified(id: number | string, phoneVerified: boolean) {
    return apiRequest({
        method: 'PUT',
        resource: `${resource}/${id}/phone-verified`,
        body: { phoneVerified }
    });
}

export function updateUser(id: number | string, body: any) {
    return apiRequest({
        method: 'PUT',
        resource,
        id,
        body
    });
}

export function deleteUser(id: number | string) {
    return apiRequest({
        method: 'DELETE',
        resource,
        id
    });
}
