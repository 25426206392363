import React from 'react';
import { Paper, Typography } from '@mui/material';
import { formatNumber } from '../../utils/parsing';

type PropsType = {
    title: string,
    value: number | null,
    onClick?: () => void,
    small?: boolean,
    elevation?: number
};

export default function StatBlock(props: PropsType) {
    const {
        title,
        value,
        onClick,
        small,
        elevation
    } = props;

    return (
        <Paper
            sx={{
                p: small ? 1 : 3,
                ':hover': { cursor: onClick ? 'pointer' : 'inherit' }
            }}
            elevation={elevation}
            onClick={onClick}
        >
            <Typography variant={small ? 'h4' : 'h3'} color="primary" align="center">
                {formatNumber(value)}
            </Typography>
            <Typography variant={small ? 'body1' : 'h6'} align="center">
                {title}
            </Typography>
        </Paper>
    );
}
