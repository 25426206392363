import React from 'react';

import { Header, Tabs } from '../CommonComponents';
import { Container } from '../StyledComponents';
import DhlStats from './DhlStats';
import GroupStats from './GroupStats';
import OrderStats from './OrderStats';
import TenantStats from './TenantStats';
import UserStats from './UserStats';

const tabs = [{
    key: 'tenants',
    label: 'Företag',
    Component: TenantStats,
    padding: 2
}, {
    key: 'users',
    label: 'Användare',
    Component: UserStats,
    padding: 2
}, {
    key: 'groups',
    label: 'Grupper',
    Component: GroupStats,
    padding: 2
}, {
    key: 'orders',
    label: 'Uppdrag',
    Component: OrderStats,
    padding: 2
}, {
    key: 'dhl',
    label: 'DHL-Uppdrag',
    Component: DhlStats,
    padding: 2
}];

export default function StatsTabs() {
    const getTitle = React.useCallback((tab) => tab.label, []);

    return (
        <Container>
            <Header
                title="Statistik"
            />
            <Tabs
                tabsKey="stats"
                tabs={tabs}
                getTitle={getTitle}
            />
        </Container>
    );
}
