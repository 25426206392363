import apiRequest from './api-request';

const resource = 'offers';

export function getOffers() {
    return apiRequest({
        method: 'GET',
        resource
    });
}

export function getOffersByTenantId(id: number | string) {
    return apiRequest({
        method: 'GET',
        resource: `tenants/${id}/${resource}`
    });
}

export function getOffersByOrderId(id: number | string) {
    return apiRequest({
        method: 'GET',
        resource: `orders/${id}/${resource}`
    });
}

export function getOffer(id: number | string) {
    return apiRequest({
        method: 'GET',
        resource,
        id
    });
}

export function createOffer(body: any) {
    return apiRequest({
        method: 'POST',
        resource,
        body
    });
}

export function updateOffer(id: number | string, body: any) {
    return apiRequest({
        method: 'PUT',
        resource,
        id,
        body
    });
}

export function deleteOffer(id: number | string) {
    return apiRequest({
        method: 'DELETE',
        resource,
        id
    });
}
