import React from 'react';
// import { useNavigate } from 'react-router-dom';

import { Header, Table } from '../CommonComponents';
import { Container, Paper } from '../StyledComponents';

import { useDispatch, useSelector } from '../../redux/hooks';
import {
    fetchAllInvites,
    selectAllInvites,
    selectFetchingAllInvites
} from '../../redux/actions/invites';
import toast from '../../utils/toast';
import { InviteForListType } from '../../types';
import { setInviteIsHandled } from '../../api-admin/invites';

const columns = [{
    name: 'Företag',
    key: 'tenantName',
    canSearch: true
}, {
    name: 'Användare',
    key: 'createdByName',
    canSearch: true
}, {
    name: 'Inbjuden e-post',
    key: 'email',
    canSearch: true
}, {
    name: 'Hanterat',
    key: 'isHandled',
    type: 'bool' as 'bool',
    canFilter: true
}, {
    name: 'Skapades',
    key: 'createdAt',
    type: 'datetime' as 'datetime',
    canFilter: true
}];

export default function Invites() {
    // const navigate = useNavigate();
    const dispatch = useDispatch();
    const invites = useSelector(selectAllInvites());
    const isFetching = useSelector(selectFetchingAllInvites());

    React.useEffect(() => {
        dispatch(fetchAllInvites());
    }, [dispatch]);

    const toggleIsHandled = React.useCallback(async (selectedInvite) => {
        const ids = selectedInvite.map((f: InviteForListType) => f.id);
        const isHandled = selectedInvite.some((f: InviteForListType) => !f.isHandled);
        try {
            await setInviteIsHandled(ids, isHandled);
            dispatch(fetchAllInvites());
        } catch (e) {
            toast(e, 'Kunde inte ändra hanterat, försök igen');
        }
    }, [dispatch]);

    // const onRowClick = React.useCallback((invite: { id: number }) => {
    //     navigate(`/invites/${invite.id}`);
    // }, [navigate]);

    return (
        <Container>
            <Header title="Inbjudningar" />

            <Paper padding={0}>
                <Table
                    name="invites"
                    columns={columns}
                    data={invites}
                    // onRowClick={onRowClick}
                    emptyText={isFetching ? 'Hämtar inbjudningar...' : 'Det finns inga inbjudningar.'}
                    showPagination
                    defaultOrderBy="createdAt"
                    defaultOrderDirection="asc"
                    batchActions={[{
                        name: 'Ändra hanterat',
                        action: toggleIsHandled
                    }]}
                />
            </Paper>
        </Container>
    );
}
