import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';

import { useDispatch, useSelector } from '../../redux/hooks';

import {
    Header,
    StatBlock
} from '../CommonComponents';
import {
    Container
} from '../StyledComponents';

import { selectAdminProfile } from '../../redux/actions/admins';
import { setTableOptions } from '../../redux/actions/tables';
import { fetchDashboardData, selectDashboardData } from '../../redux/actions/dashboard';

const Dashboard: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const adminProfile = useSelector(selectAdminProfile());
    const data = useSelector(selectDashboardData());

    React.useEffect(() => {
        dispatch(fetchDashboardData());
    }, [dispatch]);

    const onClickPendingRegistrations = React.useCallback(() => {
        dispatch(setTableOptions({
            name: 'tenants',
            query: '',
            page: 1,
            orderBy: 'regDate',
            orderDirection: 'asc',
            filterValues: {
                'isAccepted-equals': false,
                'isRejected-equals': false,
                'userCount-greaterThan': 0
            }
        }));
        navigate('/tenants');
    }, [dispatch, navigate]);

    const onClickActiveUsersToday = React.useCallback(() => {
        dispatch(setTableOptions({
            name: 'requests',
            query: '',
            page: 1,
            orderBy: 'createdAt',
            orderDirection: 'asc',
            filterValues: {
                'createdAt-greaterThan': (new Date()).toISOString().slice(0, 10)
            }
        }));
        navigate('/requests');
    }, [dispatch, navigate]);

    const onClickAcceptedTenants = React.useCallback(() => {
        dispatch(setTableOptions({
            name: 'tenants',
            query: '',
            page: 1,
            orderBy: 'regDate',
            orderDirection: 'asc',
            filterValues: {
                'isAccepted-equals': true,
                'isRejected-equals': false
            }
        }));
        navigate('/tenants');
    }, [dispatch, navigate]);

    const onClickAcceptedUsers = React.useCallback(() => {
        dispatch(setTableOptions({
            name: 'users',
            query: '',
            page: 1,
            orderBy: 'createdAt',
            orderDirection: 'asc',
            filterValues: {
                'tenantIsAccepted-equals': true
            }
        }));
        navigate('/users');
    }, [dispatch, navigate]);

    const onClickActiveOrders = React.useCallback(() => {
        dispatch(setTableOptions({
            name: 'orders',
            query: '',
            page: 1,
            orderBy: 'createdAt',
            orderDirection: 'asc',
            filterValues: {
                'status-includes': 'Publicerad'
            }
        }));
        navigate('/orders');
    }, [dispatch, navigate]);

    const onClickActiveOffers = React.useCallback(() => {
        dispatch(setTableOptions({
            name: 'offers',
            query: '',
            page: 1,
            orderBy: 'createdAt',
            orderDirection: 'asc',
            filterValues: {
                // 'status-includes': 'Ohanterat'
            }
        }));
        navigate('/offers');
    }, [dispatch, navigate]);

    const onClickGroups = React.useCallback(() => {
        dispatch(setTableOptions({
            name: 'groups',
            query: '',
            page: 1,
            orderBy: 'createdAt',
            orderDirection: 'asc',
            filterValues: {}
        }));
        navigate('/groups');
    }, [dispatch, navigate]);

    const onClickWatchlists = React.useCallback(() => {
        dispatch(setTableOptions({
            name: 'watchlists',
            query: '',
            page: 1,
            orderBy: 'createdAt',
            orderDirection: 'asc',
            filterValues: {
                'instantNotifications-equals': true,
                'activeEmailNotifications-equals': true
            }
        }));
        navigate('/watchlists');
    }, [dispatch, navigate]);

    const onClickUnhandledInvites = React.useCallback(() => {
        dispatch(setTableOptions({
            name: 'invites',
            query: '',
            page: 1,
            orderBy: 'createdAt',
            orderDirection: 'asc',
            filterValues: {
                'isHandled-equals': false
            }
        }));
        navigate('/invites');
    }, [dispatch, navigate]);

    const onClickUnhandledFeedback = React.useCallback(() => {
        dispatch(setTableOptions({
            name: 'feedback',
            query: '',
            page: 1,
            orderBy: 'createdAt',
            orderDirection: 'asc',
            filterValues: {
                'isHandled-equals': false
            }
        }));
        navigate('/feedback');
    }, [dispatch, navigate]);

    return (
        <Container>
            <Header
                title={`Hej ${adminProfile?.firstName || ''}!`}
                hideBackButton
            />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <StatBlock
                        title="Väntande registreringar"
                        value={data.pendingRegistrations}
                        onClick={onClickPendingRegistrations}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <StatBlock
                        title="Aktiva användare idag"
                        value={data.activeUsersToday}
                        onClick={onClickActiveUsersToday}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <StatBlock
                        title="Accepterade företag"
                        value={data.acceptedTenants}
                        onClick={onClickAcceptedTenants}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <StatBlock
                        title="Aktiva användare"
                        value={data.acceptedUsers}
                        onClick={onClickAcceptedUsers}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <StatBlock
                        title="Aktiva uppdrag"
                        value={data.activeOrders}
                        onClick={onClickActiveOrders}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <StatBlock
                        title="Aktiva anbud"
                        value={data.activeOffers}
                        onClick={onClickActiveOffers}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <StatBlock
                        title="Grupper"
                        value={data.groups}
                        onClick={onClickGroups}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <StatBlock
                        title="Bevakningar med e-postnotiser"
                        value={data.watchlistsWithNotifications}
                        onClick={onClickWatchlists}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <StatBlock
                        title="Ohanterade inbjudningar"
                        value={data.unhandledInvites}
                        onClick={onClickUnhandledInvites}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <StatBlock
                        title="Ohanterad feedback"
                        value={data.unhandledFeedback}
                        onClick={onClickUnhandledFeedback}
                    />
                </Grid>
            </Grid>
        </Container>
    );
};

export default Dashboard;
