import React, { useContext } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import FormContext from '../FormContext';
import useValidation from '../hooks/useValidation';
import { validateDate, validateDateRange, validateRequired } from '../validations/validations';
import type { ValidationType } from '../validations/ValidationContext';
import { Input } from '../../../StyledComponents';

type PropsType = {
    name: string,
    label: string,
    value: string | Date,
    onChange: (a: any) => void,
    required?: boolean,
    minDateTime?: string | Date,
    maxDateTime?: string | Date,
    helperText?: string
};

const DateTimeField: React.FC<PropsType> = (props) => {
    const {
        name,
        label,
        value,
        onChange,
        required,
        minDateTime,
        maxDateTime,
        helperText
    } = props;

    const formContext = useContext(FormContext);
    const isSubmitting = formContext?.isSubmitting;

    const validations = [
        ...(typeof required !== 'undefined'
            ? [validateRequired({ value: !required || value })]
            : []
        ),
        ...(minDateTime || maxDateTime
            ? [validateDateRange({ value, minDate: minDateTime, maxDate: maxDateTime })]
            : []
        ),
        validateDate({ value })
    ];

    const errors = validations
        // useValidation registers the validation and returns isValid for filter
        // eslint-disable-next-line react-hooks/rules-of-hooks
        .filter((v: ValidationType) => useValidation({ name, type: v.type, isValid: v.isValid }));

    const errorMessage = errors.map((v: ValidationType) => v.errorText).join(' ');

    return (
        <DateTimePicker
            label={`${label}${required ? ' *' : ''}`}
            value={value || null}
            onChange={(newValue) => {
                onChange({ [name]: newValue });
            }}
            mask="____-__-__ __:__"
            renderInput={(params: any) => (
                <Input
                    {...params}
                    inputProps={{ ...params.inputProps, placeholder: 'åååå-mm-dd tt:mm' }}
                    autoComplete="off"
                    error={!!errors.length}
                    helperText={errorMessage || helperText}
                />
            )}
            disabled={isSubmitting}
            minDateTime={minDateTime}
            maxDateTime={maxDateTime}
        />
    );
};

export default DateTimeField;
