/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminProfileType, AdminForListType } from '../../types';

type AdminsStateType = {
    profile: AdminProfileType | null,
    all: AdminForListType[],
    fetchingProfile: boolean,
    fetchingAll: boolean
};

const initialState: AdminsStateType = {
    profile: null,
    all: [],
    fetchingProfile: false,
    fetchingAll: false
};

const slice = createSlice({
    name: 'admins',
    initialState,
    reducers: {
        fetchProfile: (state) => {
            state.fetchingProfile = true;
        },
        profileFetched: (state, action: PayloadAction<{data: AdminsStateType['profile']}>) => {
            state.profile = action.payload.data;
            state.fetchingProfile = false;
        },
        fetchAll: (state) => {
            state.fetchingAll = true;
        },
        allFetched: (state, action: PayloadAction<{data: AdminsStateType['all']}>) => {
            state.all = action.payload.data;
            state.fetchingAll = false;
        }
    }
});

export default slice;
