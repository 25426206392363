import apiRequest from './api-request';

const resource = 'group-invitations';

export function getGroupInvitationsByGroupId(groupId: number | string) {
    return apiRequest({
        method: 'GET',
        resource: `groups/${groupId}/${resource}`
    });
}

export function createGroupInvitation(body: any) {
    return apiRequest({
        method: 'POST',
        resource,
        body
    });
}

export function updateGroupInvitation(id: number | string, body: any) {
    return apiRequest({
        method: 'PUT',
        resource,
        id,
        body
    });
}

export function deleteGroupInvitation(id: number | string) {
    return apiRequest({
        method: 'DELETE',
        resource,
        id
    });
}
