import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Header, Table } from '../CommonComponents';
import { Container, Paper } from '../StyledComponents';

import { useDispatch, useSelector } from '../../redux/hooks';
import { fetchAllTenants, selectAllTenants, selectFetchingAllTenants } from '../../redux/actions/tenants';

const columns = [{
    name: 'Namn',
    key: 'name',
    type: 'string' as 'string',
    canSearch: true
}, {
    name: 'Org. nummer',
    key: 'orgNumber',
    canSearch: true
}, {
    name: 'Accepterad',
    key: 'isAccepted',
    type: 'bool' as 'bool',
    canFilter: true
}, {
    name: 'Avvisad',
    key: 'isRejected',
    type: 'bool' as 'bool',
    canFilter: true
}, {
    name: 'Antal användare',
    key: 'userCount',
    type: 'number' as 'number',
    canFilter: true
}, {
    name: 'Active at',
    key: 'activeAt',
    type: 'datetime' as 'datetime',
    canFilter: true
}, {
    name: 'Reg. datum',
    key: 'regDate',
    type: 'datetime' as 'datetime',
    canFilter: true
}, {
    name: 'Senast aktiv',
    key: 'activeAt',
    type: 'datetime' as 'datetime',
    canFilter: true
}];

export default function Tenants() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const tenants = useSelector(selectAllTenants());
    const isFetching = useSelector(selectFetchingAllTenants());

    React.useEffect(() => {
        dispatch(fetchAllTenants());
    }, [dispatch]);

    const onRowClick = React.useCallback((tenant: { id: number }) => {
        navigate(`/tenants/${tenant.id}`);
    }, [navigate]);

    return (
        <Container>
            <Header
                title="Företag"
                addUrl="/tenants/create"
            />

            <Paper padding={0}>
                <Table
                    name="tenants"
                    columns={columns}
                    data={tenants}
                    onRowClick={onRowClick}
                    emptyText={isFetching ? 'Hämtar företag...' : 'Det finns inga företag.'}
                    showPagination
                    defaultOrderBy="regDate"
                    defaultOrderDirection="asc"
                    // batchActions={[{
                    //     name: 'Aktivera',
                    //     // icon?: JSX.Element | undefined;
                    //     action: async (t) => {
                    //         console.log(t);
                    //     }
                    //     // color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | undefined;
                    //     // variant?: "text" | ... 2 more ... | undefined;
                    //     // getDisabled?: ((a: any[]) => boolean) | undefined;
                    // }]}
                />
            </Paper>
        </Container>
    );
}
