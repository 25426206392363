import React from 'react';

import InputField from './InputField';
import { validateMinLength, validateMaxLength } from '../validations/validations';

type PropsType = {
    minLength?: number,
    maxLength?: number,
    name: string,
    label: string,
    value: string,
    required?: boolean,
    helperText?: string,
    sx?: {[key: string]: string | number },
    autoComplete?: string,
    autoFocus?: boolean,
    variant?: 'filled' | 'outlined' | 'standard',
    type?: string,
    multiline?: boolean,
    minRows?: number,
    submitOnEnter?: boolean,
    disabled?: boolean,
    onChange: (a: any) => void
};

export default function TextField(props: PropsType) {
    const {
        minLength,
        maxLength,
        ...baseProps
    } = props;

    const validations = [];

    if (minLength) {
        validations.push(validateMinLength({ value: baseProps.value, minLength }));
    }

    if (maxLength) {
        validations.push(validateMaxLength({ value: baseProps.value, maxLength }));
    }

    return (
        <InputField
            {...baseProps}
            validations={validations}
        />
    );
}
