import React from 'react';
import { useParams } from 'react-router-dom';

import { Header, Tabs } from '../CommonComponents';
import { Container } from '../StyledComponents';
import GroupDetails from './GroupDetails';
import GroupInvitations from './GroupInvitations';
import GroupOrders from './GroupOrders';
import FetchRedux from '../CommonComponents/Fetch/FetchRedux';
import { FetchPropsType } from '../CommonComponents/Fetch/FetchPropsType';
import {
    fetchGroupMetaById,
    selectFetchingGroupMetaById,
    selectGroupMetaById
} from '../../redux/actions/groups';

const tabs = [{
    key: 'group',
    label: 'Uppgifter',
    Component: GroupDetails,
    padding: 2
}, {
    key: 'invitations',
    label: 'Inbjudningar',
    Component: GroupInvitations
}, {
    key: 'orders',
    label: 'Uppdrag',
    Component: GroupOrders
}];

type GroupMetaType = {
    [key: string]: number | string
};

function GroupTabs(props: FetchPropsType<any>) {
    const { entity, isFetching } = props;

    const getTitle = React.useCallback((tab) => {
        if (!entity) {
            return tab.label;
        }
        const count = entity[`${tab.key}Count` as string];
        return typeof count === 'number'
            ? `${tab.label} (${count})`
            : tab.label;
    }, [entity]);

    return (
        <Container>
            <Header
                title={entity.name as string ?? 'Grupp'}
                subtitle={entity.ownerTenantName}
            />
            <Tabs
                tabsKey="groups"
                tabs={tabs}
                getTitle={getTitle}
            />
        </Container>
    );
}

export default function GroupTabsFetched() {
    const { id } = useParams() as unknown as { id: number };

    return (
        <FetchRedux<GroupMetaType>
            fetchEntity={fetchGroupMetaById(id)}
            selectEntity={selectGroupMetaById(id)}
            selectIsFetching={selectFetchingGroupMetaById(id)}
            Component={GroupTabs}
            name="gruppen"
        />
    );
}
