import React from 'react';
import { Grid, Typography } from '@mui/material';

import { Spinner } from '../../StyledComponents';

export default function Loading({ name }: { name?: string }): JSX.Element {
    return (
        <Grid
            container
            sx={{ height: '100%' }}
            justifyContent="space-around"
            alignItems="center"
        >
            <Grid item sx={{ m: 1 }}>
                <div style={{ textAlign: 'center', marginBottom: 10 }}>
                    <Spinner />
                </div>
                <Typography gutterBottom variant="h5" sx={{ textAlign: 'center' }}>
                    {name ? `Hämtar ${name}` : 'Hämtar'}
                </Typography>
            </Grid>
        </Grid>
    );
}
