import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    fetchAllWatchlists,
    selectFetchingAllWatchlists,
    selectAllWatchlists
} from '../../redux/actions/watchlists';
import { useDispatch, useSelector } from '../../redux/hooks';
import { Header, Table } from '../CommonComponents';
import { Container, Paper } from '../StyledComponents';

const columns = [{
    name: 'Företag',
    key: 'tenantName',
    canSearch: true
}, {
    name: 'Användare',
    key: 'userName',
    canSearch: true
}, {
    name: 'Bevakning',
    key: 'name',
    canSearch: true
}, {
    name: 'Direktnotiser',
    key: 'instantNotifications',
    type: 'bool' as 'bool',
    canFilter: true
}, {
    name: 'Dagliga notiser',
    key: 'dailyNotifications',
    type: 'bool' as 'bool',
    canFilter: true
}, {
    name: 'Aktiva e-postnotiser',
    key: 'activeEmailNotifications',
    type: 'bool' as 'bool',
    canFilter: true
}, {
    name: 'Skapades',
    key: 'createdAt',
    type: 'datetime' as 'datetime',
    canFilter: true
}];

export default function Watchlists() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const watchlists = useSelector(selectAllWatchlists());
    const isFetching = useSelector(selectFetchingAllWatchlists());

    React.useEffect(() => {
        dispatch(fetchAllWatchlists());
    }, [dispatch]);

    const onRowClick = React.useCallback((watchlist: { id: number }) => {
        navigate(`/watchlists/${watchlist.id}`);
    }, [navigate]);

    return (
        <Container>
            <Header title="Bevakningar" />

            <Paper padding={0}>
                <Table
                    name="watchlists"
                    columns={columns}
                    data={watchlists}
                    onRowClick={onRowClick}
                    emptyText={isFetching ? 'Hämtar bevakningar...' : 'Det finns inga bevakningar.'}
                    showPagination
                    defaultOrderBy="createdAt"
                    defaultOrderDirection="asc"
                />
            </Paper>
        </Container>
    );
}
