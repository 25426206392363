import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import {
    ChevronLeft as ChevronLeftIcon,
    Info as InfoIcon,
    Refresh as RefreshIcon
} from '@mui/icons-material';

import { useNavigate } from 'react-router-dom';

export default function NotFound({ name, description }: { name?: string, description?: string }): JSX.Element {
    const navigate = useNavigate();

    return (
        <Grid
            container
            sx={{ height: '100%' }}
            justifyContent="space-around"
            alignItems="center"
        >
            <Grid item sx={{ m: 1 }}>
                <div style={{ textAlign: 'center', marginBottom: 10 }}>
                    <InfoIcon color="action" sx={{ fontSize: 40 }} />
                </div>

                <Typography gutterBottom variant="h5" sx={{ textAlign: 'center' }}>
                    {name ? `Kunde inte hitta ${name}` : 'Sidan kunde inte hittas'}
                </Typography>
                {description && (
                    <Typography gutterBottom variant="body1" sx={{ textAlign: 'center' }}>
                        {description}
                    </Typography>
                )}
                <div style={{ textAlign: 'center', marginTop: 20 }}>
                    <Button
                        variant="outlined"
                        startIcon={<ChevronLeftIcon />}
                        onClick={() => navigate(-1)}
                        sx={{ m: 1 }}
                    >
                        Gå tillbaka
                    </Button>
                    <Button
                        variant="outlined"
                        startIcon={<RefreshIcon />}
                        onClick={() => navigate(0)}
                        sx={{ m: 1 }}
                    >
                        Försök igen
                    </Button>
                </div>
            </Grid>
        </Grid>
    );
}
