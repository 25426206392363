/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderForListType, OrderMetaType, OrderType } from '../../types';

type OrdersStateType = {
    all: OrderForListType[],
    fetchingAll: boolean,
    byTenantId: {[key: number | string]: OrderForListType[] },
    fetchingByTenantId: {[key: number | string]: boolean},
    byGroupId: {[key: number | string]: OrderForListType[] },
    fetchingByGroupId: {[key: number | string]: boolean},
    byId: {[key: number | string]: OrderType | undefined },
    fetchingById: {[key: number | string]: boolean},
    metaById: {[key: number | string]: OrderMetaType | undefined },
    fetchingMetaById: {[key: number | string]: boolean}
};

const initialState: OrdersStateType = {
    all: [],
    fetchingAll: false,
    byTenantId: {},
    fetchingByTenantId: {},
    byGroupId: {},
    fetchingByGroupId: {},
    byId: {},
    fetchingById: {},
    metaById: {},
    fetchingMetaById: {}
};

const slice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        fetchAll: (state) => {
            state.fetchingAll = true;
        },
        allFetched: (state, action: PayloadAction<{data: OrdersStateType['all']}>) => {
            state.all = action.payload.data;
            state.fetchingAll = false;
        },
        fetchByTenantId: (state, action) => {
            state.fetchingByTenantId[action.payload.id] = true;
        },
        byTenantIdFetched: (state, action: PayloadAction<{ id: number | string, data: OrderForListType[] }>) => {
            state.byTenantId[action.payload.id] = action.payload.data;
            state.fetchingByTenantId[action.payload.id] = false;
        },
        fetchByGroupId: (state, action) => {
            state.fetchingByGroupId[action.payload.id] = true;
        },
        byGroupIdFetched: (state, action: PayloadAction<{ id: number | string, data: OrderForListType[] }>) => {
            state.byGroupId[action.payload.id] = action.payload.data;
            state.fetchingByGroupId[action.payload.id] = false;
        },
        fetchById: (state, action) => {
            state.fetchingById[action.payload.id] = true;
        },
        byIdFetched: (state, action: PayloadAction<{ id: number | string, data?: OrderType }>) => {
            state.byId[action.payload.id] = action.payload.data;
            state.fetchingById[action.payload.id] = false;
        },
        fetchMetaById: (state, action) => {
            state.fetchingMetaById[action.payload.id] = true;
        },
        metaByIdFetched: (state, action: PayloadAction<{ id: number | string, data?: OrderMetaType }>) => {
            state.metaById[action.payload.id] = action.payload.data;
            state.fetchingMetaById[action.payload.id] = false;
        }
    }
});

export default slice;
