import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchUsersByTenantId, selectFetchingUsersByTenantId, selectUsersByTenantId } from '../../redux/actions/users';
import { UserForListType } from '../../types';
import { Table } from '../CommonComponents';
import { FetchPropsType } from '../CommonComponents/Fetch/FetchPropsType';
import FetchRedux from '../CommonComponents/Fetch/FetchRedux';

const columns = [{
    name: 'Namn',
    key: 'name',
    canSearch: true
}, {
    name: 'E-post',
    key: 'email',
    canSearh: true
}, {
    name: 'Mobil',
    key: 'phone',
    canSearch: true
}, {
    name: 'E-post ver.',
    key: 'emailVerified',
    type: 'bool' as 'bool',
    canFilter: true
}, {
    name: 'Mobil ver.',
    key: 'phoneVerified',
    type: 'bool' as 'bool',
    canFilter: true
}, {
    name: 'Admin',
    key: 'isAdmin',
    type: 'bool' as 'bool',
    canFilter: true
}, {
    name: 'Skapades',
    key: 'createdAt',
    type: 'datetime' as 'datetime',
    canFilter: true
}];

function TenantUsers(props: FetchPropsType<UserForListType[]>) {
    const { entity, isFetching } = props;
    const { id } = useParams() as unknown as { id: number };
    const navigate = useNavigate();

    const onAddClick = React.useCallback(() => {
        navigate(`/tenants/${id}/users/create`);
    }, [id, navigate]);

    const onRowClick = React.useCallback((user: { id: number }) => {
        navigate(`/users/${user.id}`);
    }, [navigate]);

    return (
        <Table
            name="tenantUsers"
            columns={columns}
            data={entity}
            onRowClick={onRowClick}
            emptyText={isFetching ? 'Hämtar användare...' : 'Det finns inga användare.'}
            showPagination
            defaultOrderBy="createdAt"
            defaultOrderDirection="asc"
            onAddClick={onAddClick}
        // batchActions={[{
        //     name: 'Aktivera',
        //     // icon?: JSX.Element | undefined;
        //     action: async (t) => {
        //         console.log(t);
        //     }
        //     // color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | undefined;
        //     // variant?: "text" | ... 2 more ... | undefined;
        //     // getDisabled?: ((a: any[]) => boolean) | undefined;
        // }]}
        />
    );
}

export default function TenantDetailsFetched({ hasRendered }: { hasRendered?: boolean }) {
    const { id } = useParams() as unknown as { id: number };

    return (
        <FetchRedux<UserForListType[]>
            fetchEntity={fetchUsersByTenantId(id)}
            selectEntity={selectUsersByTenantId(id)}
            selectIsFetching={selectFetchingUsersByTenantId(id)}
            Component={TenantUsers}
            name="användarna"
            initialFetch={!hasRendered}
        />
    );
}
