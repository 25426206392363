import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

import {
    useChangeable,
    FullForm,
    TextField,
    AutocompleteField,
    BoolField
} from '../CommonComponents/Form';
import { SectionDivider } from '../CommonComponents';

import {
    createGroup,
    deleteGroup,
    getGroup,
    updateGroup
} from '../../api-admin/groups';

import toast from '../../utils/toast';
import { fetchAllTenants, selectAllTenants } from '../../redux/actions/tenants';
import { useDispatch, useSelector } from '../../redux/hooks';

const defaultGroup = {
    ownerTenantId: null,
    name: '',
    description: '',
    isPublic: false,
    canPublishDefault: false
};

export default function GroupForm() {
    const { id, tenantId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const tenants = useSelector(selectAllTenants());

    const [entity, onChange] = useChangeable(defaultGroup);

    React.useEffect(() => {
        (async () => {
            if (id) {
                const g = await getGroup(id);
                onChange(g);
            } else if (tenantId) {
                onChange({
                    ownerTenantId: tenantId,
                    ownerTenantName: tenants.find((t) => (t.id === Number(tenantId)))?.name || tenantId
                });
            }
        })();
    }, [id, onChange, tenantId, tenants]);

    const onSubmit = React.useCallback(async () => {
        try {
            if (id) {
                await updateGroup(id, entity);
                toast('Gruppen uppdaterad');
                navigate(-1);
            } else {
                const createdGroup = await createGroup(entity);
                toast('Gruppen skapad');
                navigate(`/groups/${createdGroup.id}`, { replace: true });
            }
        } catch (e: any) {
            toast(e, `Kunde inte ${id ? 'uppdatera' : 'skapa'} gruppen, försök igen`);
        }
    }, [id, entity, navigate]);

    const onDelete = React.useCallback(async () => {
        try {
            if (id) {
                await deleteGroup(id);
                toast('Gruppen borttagen');
                navigate(-2);
            }
        } catch (e: any) {
            toast(e, 'Kunde inte ta bort gruppen, försök igen');
        }
    }, [id, navigate]);

    return (
        <FullForm
            title={`${id ? 'Redigera' : 'Skapa'} grupp`}
            entity={entity}
            onSubmit={onSubmit}
            onDelete={id ? onDelete : undefined}
        >
            <Grid container spacing={2}>
                <Grid item md={12} lg={6}>
                    <SectionDivider label="Kontaktuppgifter" marginTop={1} />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AutocompleteField<{
                                id: number | string,
                                name: string,
                                orgNumber?: string
                            }>
                                label="Företag"
                                optionIdKey="id"
                                defaultOption={!entity.ownerTenantId ? null : {
                                    id: entity.ownerTenantId,
                                    name: entity.ownerTenantName || ''
                                }}
                                fetchOptions={() => { dispatch(fetchAllTenants()); }}
                                options={tenants}
                                minQueryLength={3}
                                getOptionName={(o) => o.name}
                                getOptionDescription={(o) => o.orgNumber || ''}
                                onChange={(tenant) => onChange({
                                    ownerTenantId: tenant === null ? null : tenant.id,
                                    ownerTenantName: tenant === null ? null : tenant.name,
                                    ownerTenantOrgNumber: tenant === null ? null : tenant.orgNumber,
                                })}
                                disabled={!!id || !!tenantId}
                                fetchOnlyOnce
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                name="name"
                                label="Namn"
                                value={entity.name}
                                onChange={onChange}
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                name="description"
                                label="Beskrivning"
                                value={entity.description}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <BoolField
                                name="isPublic"
                                label="Publik"
                                value={entity.isPublic}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <BoolField
                                name="canPublishDefault"
                                label="Nya medlemmar kan publicera"
                                value={entity.canPublishDefault}
                                onChange={onChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </FullForm>
    );
}
