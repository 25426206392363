import stats from '../slices/stats';
import type { RootStateType, ThunkDispatchType } from '../index';

import {
    getDhlStats,
    getGroupStats,
    getOrderStats,
    getTenantStats,
    getUserStats
} from '../../api-admin/stats';

export function fetchTenantStats() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(stats.actions.fetchTenantStats());
            const data = await getTenantStats();
            dispatch(stats.actions.tenantStatsFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching tenant stats, set redux error state and handle in errorBoundary.', e);
        }
    };
}

export function fetchUserStats() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(stats.actions.fetchUserStats());
            const data = await getUserStats();
            dispatch(stats.actions.userStatsFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching user stats, set redux error state and handle in errorBoundary.', e);
        }
    };
}

export function fetchGroupStats() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(stats.actions.fetchGroupStats());
            const data = await getGroupStats();
            dispatch(stats.actions.groupStatsFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching group stats, set redux error state and handle in errorBoundary.', e);
        }
    };
}

export function fetchOrderStats() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(stats.actions.fetchOrderStats());
            const data = await getOrderStats();
            dispatch(stats.actions.orderStatsFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching order stats, set redux error state and handle in errorBoundary.', e);
        }
    };
}

export function fetchDhlStats() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(stats.actions.fetchDhlStats());
            const data = await getDhlStats();
            dispatch(stats.actions.dhlStatsFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching dhl stats, set redux error state and handle in errorBoundary.', e);
        }
    };
}

export const selectTenantStats = () => (state: RootStateType) => state.stats.tenantStats;
export const selectFetchingTenantStats = () => (state: RootStateType) => state.stats.fetchingTenantStats;

export const selectUserStats = () => (state: RootStateType) => state.stats.userStats;
export const selectFetchingUserStats = () => (state: RootStateType) => state.stats.fetchingUserStats;

export const selectGroupStats = () => (state: RootStateType) => state.stats.groupStats;
export const selectFetchingGroupStats = () => (state: RootStateType) => state.stats.fetchingGroupStats;

export const selectOrderStats = () => (state: RootStateType) => state.stats.orderStats;
export const selectFetchingOrderStats = () => (state: RootStateType) => state.stats.fetchingOrderStats;

export const selectDhlStats = () => (state: RootStateType) => state.stats.dhlStats;
export const selectFetchingDhlStats = () => (state: RootStateType) => state.stats.fetchingDhlStats;
