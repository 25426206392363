import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend
} from 'chart.js';

import {
    Doughnut,
    getElementAtEvent
} from 'react-chartjs-2';

import { colors } from './index';

ChartJS.register(ArcElement, Tooltip, Legend);

type DataType = {
    label: string,
    value: number,
    onClick?: () => void
}[];

const options = {
    plugins: {
        legend: {
            position: 'bottom' as 'bottom'
        }
    },
    // onHover: (event: any, chartElement: any) => {
    //     // eslint-disable-next-line no-param-reassign
    //     event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    // }
};

export default function DoughnutChart({ data }: { data: DataType }) {
    const chartRef = React.useRef();

    const formattedData = React.useMemo(() => ({
        labels: data.map((d) => d.label),
        datasets: [{
            data: data.map((d) => d.value),
            backgroundColor: colors,
            hoverOffset: 4
        }]
    }), [data]);

    const onClick = React.useCallback((event) => {
        if (chartRef.current) {
            const element = (getElementAtEvent(chartRef.current, event))[0];
            if (element?.index !== undefined) {
                const oc = data[element.index]?.onClick;
                if (typeof oc === 'function') {
                    oc();
                }
            }
        }
    }, [data]);

    return (
        <Doughnut
            ref={chartRef}
            data={formattedData}
            options={options}
            onClick={onClick}
        />
    );
}
