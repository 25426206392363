import { Home } from '@mui/icons-material';
import {
    ListItemButton,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import React from 'react';

type PropsType = {
    backgroundColor: string,
    textColor: string,
    onClick: () => void
};

const HomeMenuItem: React.FC<PropsType> = (props) => {
    const {
        backgroundColor,
        textColor,
        onClick
    } = props;

    return (
        <ListItemButton
            key="notifications"
            onClick={onClick}
            sx={{ background: backgroundColor }}
        >
            <ListItemIcon sx={{ color: textColor }}>
                <Home />
            </ListItemIcon>
            <ListItemText primary="Hem" sx={{ color: textColor }} />
        </ListItemButton>
    );
};

export default HomeMenuItem;
