import { isValid as isValidDate, parseISO as parseISODate } from 'date-fns';

import { splitString } from '../../../../utils';
import { formatDateTime } from '../../../../utils/parsing';

// eslint-disable-next-line max-len
const emailRegex = /^\s*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;

function castDateTime(dt?: string | Date) {
    if (typeof dt === 'string' || typeof dt === 'undefined') {
        return dt;
    }
    return formatDateTime(dt);
}

export function validateRequired({ value }: { value: boolean | string | number | Date }) {
    return ({
        type: 'isRequired',
        errorText: '', // Using * instead to indicate required
        isValid: Array.isArray(value) ? value.length > 0 : !!value
    });
}

type NumberRangePropsType = { value?: string, minValue?: number, maxValue?: number };
export function validateNumberRange({ value, minValue, maxValue }: NumberRangePropsType) {
    if (typeof minValue !== 'number' && typeof maxValue !== 'undefined') {
        return ({
            type: 'numberRange',
            errorText: `Måste vara mindre eller lika med ${maxValue}.`,
            isValid: typeof value === 'undefined' || value === '' || Number(value) <= maxValue
        });
    }

    if (typeof maxValue !== 'number' && typeof minValue !== 'undefined') {
        return ({
            type: 'numberRange',
            errorText: `Måste vara större eller lika med ${minValue}.`,
            isValid: typeof value === 'undefined' || value === '' || Number(value) >= minValue
        });
    }

    if (typeof minValue !== 'undefined' && typeof maxValue !== 'undefined') {
        return ({
            type: 'numberRange',
            errorText: `Måste vara mellan ${minValue} och ${maxValue}.`,
            isValid: typeof value === 'undefined' || value === '' || (Number(value) >= minValue && Number(value) <= maxValue)
        });
    }
    return ({
        type: 'numberRange',
        errorText: '',
        isValid: true
    });
}

type DateRangePropsType = { value?: string | Date, minDate?: string | Date, maxDate?: string | Date };
export function validateDateRange({ value, minDate, maxDate }: DateRangePropsType) {
    if (!value) {
        return ({
            type: 'dateRange',
            errorText: '',
            isValid: true
        });
    }
    if (!minDate && maxDate) {
        return ({
            type: 'dateRange',
            errorText: `Måste vara senast ${castDateTime(maxDate)}.`,
            isValid: new Date(value) <= new Date(maxDate)
        });
    }

    if (minDate && !maxDate) {
        return ({
            type: 'dateRange',
            errorText: `Måste vara tidigast ${castDateTime(minDate)}.`,
            isValid: new Date(value) >= new Date(minDate)
        });
    }

    if (maxDate && minDate) {
        return ({
            type: 'dateRange',
            errorText: `Måste vara mellan ${castDateTime(minDate)} och ${castDateTime(maxDate)}.`,
            isValid: (new Date(value) >= new Date(minDate)) && (new Date(value) <= new Date(maxDate))
        });
    }
    return ({
        type: 'dateRange',
        errorText: '',
        isValid: true
    });
}

export function validateDate({ value }: { value?: string | Date }) {
    return ({
        type: 'isDate',
        errorText: 'Ogiltigt datum.',
        isValid: !value || isValidDate(typeof value === 'string' ? parseISODate(value) : value)
    });
}

export function validateTime({ value }: { value?: string | Date }) {
    return ({
        type: 'isDate',
        errorText: 'Ogiltig tid.',
        isValid: !value || isValidDate(typeof value === 'string' ? parseISODate(value) : value)
    });
}

export function validateMinLength({ value, minLength }: { value?: string, minLength: number }) {
    return ({
        type: 'minLength',
        errorText: `Måste vara längre än ${minLength} tecken.`,
        isValid: !value || value?.length >= minLength
    });
}

export function validateMaxLength({ value, maxLength }: { value?: string, maxLength: number }) {
    return ({
        type: 'maxLength',
        errorText: `Måste vara mindre än ${maxLength + 1} tecken.`,
        isValid: !value || value?.length <= maxLength
    });
}

export function validateEmail({ value }: { value: string }) {
    return ({
        type: 'isEmail',
        errorText: 'Fel format.',
        isValid: !value || emailRegex.test(value)
    });
}

export function validateEmails({ value }: { value: string }) {
    const maxNumber = 20;
    const emails = splitString(value);
    const tooMany = emails.length > maxNumber;

    return ({
        type: 'isEmails',
        errorText: tooMany ? 'Max 20 adresser.' : 'Fel format.',
        isValid: !value || (!tooMany && !emails.some((v) => !emailRegex.test(v)))
    });
}

export function validatePassword({ value }: { value: string }) {
    const minLength = 8;
    const numbers = '0123456789';
    const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowerCase = 'abcdefghijklmnopqrstuvwxyz';
    const special = '!"#%&/()=?*@${}[]/*-_,.;:<>';

    const passwordArray = typeof value === 'string' ? value.split('') : [];

    const validLenght = passwordArray.length >= minLength;
    const hasNumber = passwordArray.some((c) => numbers.includes(c));
    const hasUpperCase = passwordArray.some((c) => upperCase.includes(c));
    const hasLowerCase = passwordArray.some((c) => lowerCase.includes(c));
    const hasSpecial = passwordArray.some((c) => special.includes(c));

    const isValid = !value
        || (validLenght && hasNumber && hasUpperCase && hasLowerCase && hasSpecial);
    const errorText = [
        validLenght ? null : '8 tecken',
        hasNumber ? null : '1 siffra',
        hasUpperCase ? null : '1 versal',
        hasLowerCase ? null : '1 gemen',
        hasSpecial ? null : '1 specialtecken'
    ].filter((t) => !!t).join(', ');

    return ({ type: 'isPassword', errorText: isValid ? '' : `Minst ${errorText}.`, isValid });
}

export function validatePhone({ value }: { value: string }) {
    const prefixedPhoneRegex = /(^\s*[+]\s*4\s*6\s*7\s*\d)(\s*\d\s*){7}$/;
    const nonPrefixedPhoneRegex = /^\s*(0\s*7\s*\d)(\s*\d){7}\s*$/;

    return ({
        type: 'isPhone',
        errorText: 'Format (+46|0) 7X XX XX XXX.',
        isValid: !value
            || prefixedPhoneRegex.test(value)
            || nonPrefixedPhoneRegex.test(value)
    });
}

export function validateOrgNumber({ value }: { value: string }) {
    const orgNumberRegex = /^(\s*\d\s*){6}-(\s*\d\s*){4}$/g;

    return ({
        type: 'isOrgNumber',
        errorText: 'Format XXXXXX-XXXX.',
        isValid: !value || orgNumberRegex.test(value)
    });
}

export function validateWebsite({ value }: { value: string }) {
    const websiteRegex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

    return ({
        type: 'isWebsite',
        errorText: 'Fel format',
        isValid: !value || websiteRegex.test(value)
    });
}
