import React from 'react';

import InputField from './InputField';
import { validateWebsite } from '../validations/validations';

type PropsType = {
    name: string,
    label: string,
    value: string,
    required?: boolean,
    helperText?: string,
    sx?: {[key: string]: string | number },
    onChange: (a: any) => void
};

export default function TextField(props: PropsType) {
    const { value } = props;

    const validations = [validateWebsite({ value })];

    return (
        <InputField
            {...props}
            validations={validations}
        />
    );
}
