import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Avatar, Button, Grid } from '@mui/material';
import { Edit, Groups } from '@mui/icons-material';

import { Block, List } from '../CommonComponents';

import { FetchPropsType } from '../CommonComponents/Fetch/FetchPropsType';
import { GroupType } from '../../types';
import { fetchGroupById, selectFetchingGroupById, selectGroupById } from '../../redux/actions/groups';
import FetchRedux from '../CommonComponents/Fetch/FetchRedux';
import { formatDateTime } from '../../utils/parsing';

function GroupDetails(props: FetchPropsType<GroupType>) {
    const { entity } = props;
    const navigate = useNavigate();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
                <Avatar sx={{ width: 'auto', height: '80%', maxHeight: 500 }} variant="rounded">
                    <Groups sx={{ fontSize: 120, p: 2 }} />
                </Avatar>
            </Grid>

            <Grid item xs={12} lg={6}>
                <Block
                    variant="outlined"
                    title="Grunduppgifter"
                    ActionComponent={(
                        <Button
                            variant="outlined"
                            startIcon={<Edit />}
                            onClick={() => navigate(`/groups/edit/${entity.id}`)}
                            sx={{ ml: 1, mb: 1 }}
                        >
                            Redigera
                        </Button>
                    )}
                >
                    <List
                        data={[{
                            name: 'Namn',
                            value: entity.name
                        }, {
                            name: 'Administratör',
                            value: entity.ownerTenantName,
                            url: `/tenants/${entity.ownerTenantId}`
                        }, {
                            name: 'Beskrivning',
                            value: entity.description
                        }, {
                            name: 'Publik',
                            value: entity.isPublic,
                            type: 'bool'
                        }, {
                            name: 'Nya medlemmar kan publicera',
                            value: entity.canPublishDefault,
                            type: 'bool'
                        }, {
                            name: 'Skapad',
                            value: !entity.createdAt
                                ? '' : `${formatDateTime(entity.createdAt)} av ${entity.createdByName || '-'}`,
                            url: entity.createdBy ? `/users/${entity.createdBy}` : undefined
                        }, {
                            name: 'Uppdaterad',
                            value: !entity.updatedAt
                                ? '' : `${formatDateTime(entity.updatedAt)} av ${entity.updatedByName || '-'}`,
                            url: entity.updatedBy ? `/users/${entity.updatedBy}` : undefined
                        }]}
                    />
                </Block>
            </Grid>
        </Grid>
    );
}

export default function GroupDetailsFetched({ hasRendered }: { hasRendered?: boolean }) {
    const { id } = useParams() as unknown as { id: number };

    return (
        <FetchRedux<GroupType>
            fetchEntity={fetchGroupById(id)}
            selectEntity={selectGroupById(id)}
            selectIsFetching={selectFetchingGroupById(id)}
            Component={GroupDetails}
            name="gruppen"
            initialFetch={!hasRendered}
        />
    );
}
