import React from 'react';
// import { useNavigate } from 'react-router-dom';

import { Header, Table } from '../CommonComponents';
import { Container, Paper } from '../StyledComponents';

import { useDispatch, useSelector } from '../../redux/hooks';
import {
    fetchAllFeedback,
    selectAllFeedback,
    selectFetchingAllFeedback
} from '../../redux/actions/feedback';
import { FeedbackForListType } from '../../types';
import { setFeedbackIsHandled } from '../../api-admin/feedback';
import toast from '../../utils/toast';

const columns = [{
    name: 'Företag',
    key: 'tenantName',
    canSearch: true
}, {
    name: 'Användare',
    key: 'createdByName',
    canSearch: true
}, {
    name: 'Meddelande',
    key: 'message',
    canSearch: true
}, {
    name: 'Hanterat',
    key: 'isHandled',
    type: 'bool' as 'bool',
    canFilter: true
}, {
    name: 'Skapades',
    key: 'createdAt',
    type: 'datetime' as 'datetime',
    canFilter: true
}];

export default function Feedback() {
    // const navigate = useNavigate();
    const dispatch = useDispatch();
    const feedback = useSelector(selectAllFeedback());
    const isFetching = useSelector(selectFetchingAllFeedback());

    React.useEffect(() => {
        dispatch(fetchAllFeedback());
    }, [dispatch]);

    const toggleIsHandled = React.useCallback(async (selectedFeedback) => {
        const ids = selectedFeedback.map((f: FeedbackForListType) => f.id);
        const isHandled = selectedFeedback.some((f: FeedbackForListType) => !f.isHandled);
        try {
            await setFeedbackIsHandled(ids, isHandled);
            dispatch(fetchAllFeedback());
        } catch (e) {
            toast(e, 'Kunde inte ändra hanterat, försök igen');
        }
    }, [dispatch]);

    // const onRowClick = React.useCallback((invite: { id: number }) => {
    //     navigate(`/feedback/${invite.id}`);
    // }, [navigate]);

    return (
        <Container>
            <Header title="Feedback" />

            <Paper padding={0}>
                <Table
                    name="feedback"
                    columns={columns}
                    data={feedback}
                    // onRowClick={onRowClick}
                    emptyText={isFetching ? 'Hämtar feedback...' : 'Det finns inga feedback.'}
                    showPagination
                    defaultOrderBy="createdAt"
                    defaultOrderDirection="asc"
                    batchActions={[{
                        name: 'Ändra hanterat',
                        action: toggleIsHandled
                    }]}
                />
            </Paper>
        </Container>
    );
}
