/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestForListType } from '../../types';

type RequestsStateType = {
    all: RequestForListType[],
    fetchingAll: boolean,
    byTenantId: {[key: number | string]: RequestForListType[] },
    fetchingByTenantId: {[key: number | string]: boolean},
    byUserId: {[key: number | string]: RequestForListType[] },
    fetchingByUserId: {[key: number | string]: boolean},
    byOrderId: {[key: number | string]: RequestForListType[] },
    fetchingByOrderId: {[key: number | string]: boolean}
};

const initialState: RequestsStateType = {
    all: [],
    fetchingAll: false,
    byTenantId: {},
    fetchingByTenantId: {},
    byUserId: {},
    fetchingByUserId: {},
    byOrderId: {},
    fetchingByOrderId: {}
};

const slice = createSlice({
    name: 'requests',
    initialState,
    reducers: {
        fetchAll: (state) => {
            state.fetchingAll = true;
        },
        allFetched: (state, action: PayloadAction<{data: RequestsStateType['all']}>) => {
            state.all = action.payload.data;
            state.fetchingAll = false;
        },
        fetchByTenantId: (state, action) => {
            state.fetchingByTenantId[action.payload.id] = true;
        },
        byTenantIdFetched: (state, action: PayloadAction<{ id: number | string, data: RequestForListType[] }>) => {
            state.byTenantId[action.payload.id] = action.payload.data;
            state.fetchingByTenantId[action.payload.id] = false;
        },
        fetchByUserId: (state, action) => {
            state.fetchingByUserId[action.payload.id] = true;
        },
        byUserIdFetched: (state, action: PayloadAction<{ id: number | string, data: RequestForListType[] }>) => {
            state.byUserId[action.payload.id] = action.payload.data;
            state.fetchingByUserId[action.payload.id] = false;
        },
        fetchByOrderId: (state, action) => {
            state.fetchingByOrderId[action.payload.id] = true;
        },
        byOrderIdFetched: (state, action: PayloadAction<{ id: number | string, data: RequestForListType[] }>) => {
            state.byOrderId[action.payload.id] = action.payload.data;
            state.fetchingByOrderId[action.payload.id] = false;
        }
    }
});

export default slice;
