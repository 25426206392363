/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InviteForListType } from '../../types';

type OffersStateType = {
    all: InviteForListType[],
    fetchingAll: boolean
};

const initialState: OffersStateType = {
    all: [],
    fetchingAll: false
};

const slice = createSlice({
    name: 'invites',
    initialState,
    reducers: {
        fetchAll: (state) => {
            state.fetchingAll = true;
        },
        allFetched: (state, action: PayloadAction<{data: OffersStateType['all']}>) => {
            state.all = action.payload.data;
            state.fetchingAll = false;
        }
    }
});

export default slice;
