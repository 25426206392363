import offers from '../slices/offers';
import type { RootStateType, ThunkDispatchType } from '../index';

import { getOffers, getOffersByOrderId, getOffersByTenantId } from '../../api-admin/offers';

export function fetchAllOffers() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(offers.actions.fetchAll());
            const data = await getOffers();
            dispatch(offers.actions.allFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching offers, set redux error state and handle in errorBoundary.', e);
            dispatch(offers.actions.allFetched({ data: [] }));
        }
    };
}

export function fetchOffersByTenantId(id: number | string) {
    if (!id) {
        throw Error('No id supplied for tenant');
    }
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(offers.actions.fetchByTenantId({ id }));
            const data = await getOffersByTenantId(id);
            dispatch(offers.actions.byTenantIdFetched({ id, data }));
        } catch (e) {
            console.log('ERROR fetching offers for tenant, set redux error state and handle in errorBoundary.', e);
            dispatch(offers.actions.byTenantIdFetched({ id, data: [] }));
        }
    };
}

export function fetchOffersByOrderId(id: number | string) {
    if (!id) {
        throw Error('No id supplied for tenant');
    }
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(offers.actions.fetchByOrderId({ id }));
            const data = await getOffersByOrderId(id);
            dispatch(offers.actions.byOrderIdFetched({ id, data }));
        } catch (e) {
            console.log('ERROR fetching offers for tenant, set redux error state and handle in errorBoundary.', e);
            dispatch(offers.actions.byOrderIdFetched({ id, data: [] }));
        }
    };
}

export const selectAllOffers = () => (state: RootStateType) => state.offers.all;
export const selectFetchingAllOffers = () => (state: RootStateType) => state.offers.fetchingAll;

export const selectOffersByTenantId = (id: number | string) => (state: RootStateType) => (
    state.offers.byTenantId[id] ?? []
);
export const selectFetchingOffersByTenantId = (id: number | string) => (state: RootStateType) => (
    state.offers.fetchingByTenantId[id] ?? false
);

export const selectOffersByOrderId = (id: number | string) => (state: RootStateType) => (
    state.offers.byOrderId[id] ?? []
);
export const selectFetchingOffersByOrderId = (id: number | string) => (state: RootStateType) => (
    state.offers.fetchingByOrderId[id] ?? false
);
