import React from 'react';
// import { useNavigate } from 'react-router-dom';

import { Header, Table } from '../CommonComponents';
import { Container, Paper } from '../StyledComponents';

import { useDispatch, useSelector } from '../../redux/hooks';
import {
    fetchAllAdmins,
    selectAllAdmins,
    selectFetchingAllAdmins
} from '../../redux/actions/admins';

const columns = [{
    name: 'Namn',
    key: 'name',
    canSearch: true
}, {
    name: 'E-post',
    key: 'email',
    canSearch: true
}, {
    name: 'Mobil',
    key: 'phone',
    canSearch: true
}, {
    name: 'Kan redigera',
    key: 'carWrite',
    type: 'bool' as 'bool',
    canFilter: true
}, {
    name: 'Kan se statistik',
    key: 'carReadStats',
    type: 'bool' as 'bool',
    canFilter: true
}];

export default function Admins() {
    // const navigate = useNavigate();
    const dispatch = useDispatch();
    const admins = useSelector(selectAllAdmins());
    const isFetching = useSelector(selectFetchingAllAdmins());

    React.useEffect(() => {
        dispatch(fetchAllAdmins());
    }, [dispatch]);

    // const onRowClick = React.useCallback((admin: { id: number }) => {
    //     navigate(`/admins/${admin.id}`);
    // }, [navigate]);

    return (
        <Container>
            <Header title="Administratörer" />

            <Paper padding={0}>
                <Table
                    name="admins"
                    columns={columns}
                    data={admins}
                    // onRowClick={onRowClick}
                    emptyText={isFetching ? 'Hämtar administratörer...' : 'Det finns inga administratörer.'}
                    showPagination
                    defaultOrderBy="createdAt"
                    defaultOrderDirection="asc"
                />
            </Paper>
        </Container>
    );
}
