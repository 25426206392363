import admins from '../slices/admins';
import type { RootStateType, ThunkDispatchType } from '../index';

import {
    getAdminProfile,
    getAdmins
} from '../../api-admin/admins';

export function fetchAdminProfile() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(admins.actions.fetchProfile());
            const data = await getAdminProfile();
            dispatch(admins.actions.profileFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching admin profile, set redux error state and handle in errorBoundary.', e);
            dispatch(admins.actions.profileFetched({ data: null }));
        }
    };
}

export function clearAdminProfile() {
    return async (dispatch: ThunkDispatchType) => {
        dispatch(admins.actions.profileFetched({ data: null }));
    };
}

export function fetchAllAdmins() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(admins.actions.fetchAll());
            const data = await getAdmins();
            dispatch(admins.actions.allFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching admins, set redux error state and handle in errorBoundary.', e);
            dispatch(admins.actions.allFetched({ data: [] }));
        }
    };
}

export const selectAdminProfile = () => (state: RootStateType) => state.admins.profile;
export const selectFetchingAdminProfile = () => (state: RootStateType) => state.admins.fetchingProfile;

export const selectAllAdmins = () => (state: RootStateType) => state.admins.all;
export const selectFetchingAllAdmins = () => (state: RootStateType) => state.admins.fetchingAll;
