import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    fetchGroupsByTenantId,
    selectFetchingGroupsByTenantId,
    selectGroupsByTenantId
} from '../../redux/actions/groups';
import { GroupForListType } from '../../types';
import { Table } from '../CommonComponents';
import { FetchPropsType } from '../CommonComponents/Fetch/FetchPropsType';
import FetchRedux from '../CommonComponents/Fetch/FetchRedux';

function formatGroupRole({ isOwner, isAccepted, isInvited }: { isOwner: boolean, isAccepted: boolean, isInvited: boolean }) {
    if (isOwner) {
        return 'Administratör';
    }
    if (isAccepted) {
        return 'Medlem';
    }
    if (isInvited) {
        return 'Inbjuden';
    }
    return 'Ej medlem';
}

const columns = [{
    name: 'Namn',
    key: 'name',
    canSearch: true
}, {
    name: 'Publik',
    key: 'isPublic',
    type: 'bool' as 'bool',
    canFilter: true
}, {
    name: 'Roll',
    key: 'customRole',
    canFilter: true,
    getValue: formatGroupRole
}, {
    name: 'Kan publicera',
    key: 'customCanPublish',
    type: 'bool' as 'bool',
    getValue: (g: { isOwner: boolean, canPublish: boolean }) => g.isOwner || g.canPublish,
    canFilter: true
}, {
    name: 'Antal inbjudningar',
    key: 'invitationCount',
    type: 'number' as 'number',
    canFilter: true
}, {
    name: 'Antal medlemmar',
    key: 'memberCount',
    type: 'number' as 'number',
    canFilter: true
}, {
    name: 'Skapades',
    key: 'createdAt',
    type: 'datetime' as 'datetime',
    canFilter: true
}];

function TenantGroups(props: FetchPropsType<GroupForListType[]>) {
    const { entity, isFetching } = props;
    const navigate = useNavigate();

    const onRowClick = React.useCallback((group: { id: number }) => {
        navigate(`/groups/${group.id}`);
    }, [navigate]);

    return (
        <Table
            name="tenantGroups"
            columns={columns}
            data={entity}
            onRowClick={onRowClick}
            emptyText={isFetching ? 'Hämtar grupper...' : 'Det finns inga grupper.'}
            showPagination
            defaultOrderBy="createdAt"
            defaultOrderDirection="asc"
        />
    );
}

export default function TenantDetailsFetched({ hasRendered }: { hasRendered?: boolean }) {
    const { id } = useParams() as unknown as { id: number };

    return (
        <FetchRedux<GroupForListType[]>
            fetchEntity={fetchGroupsByTenantId(id)}
            selectEntity={selectGroupsByTenantId(id)}
            selectIsFetching={selectFetchingGroupsByTenantId(id)}
            Component={TenantGroups}
            name="grupper"
            initialFetch={!hasRendered}
        />
    );
}
