import React from 'react';

import { FetchPropsType } from '../CommonComponents/Fetch/FetchPropsType';
import { OrderStatsType } from '../../types';
import {
    fetchOrderStats,
    selectFetchingOrderStats,
    selectOrderStats
} from '../../redux/actions/stats';
import FetchRedux from '../CommonComponents/Fetch/FetchRedux';
import { BarChart } from '../CommonComponents/Charts';

function OrderStats(props: FetchPropsType<OrderStatsType[]>) {
    const { entity } = props;

    return (
        <BarChart
            title="Uppdragsstatistik"
            sets={[{
                label: 'Draftade',
                // @ts-ignore
                data: entity.filter((o) => o.draftedAt).map((o) => o.draftedAt)
            }, {
                label: 'Publicerade',
                // @ts-ignore
                data: entity.filter((o) => o.publishedAt).map((o) => o.publishedAt)
            }, {
                label: 'Tilldelade',
                // @ts-ignore
                data: entity.filter((o) => o.acceptedAt).map((o) => o.acceptedAt)
            }, {
                label: 'Startade',
                // @ts-ignore
                data: entity.filter((o) => o.startedAt).map((o) => o.startedAt)
            }, {
                label: 'Utförda',
                // @ts-ignore
                data: entity.filter((o) => o.completedAt).map((o) => o.completedAt)
            }, {
                label: 'Visningar',
                // @ts-ignore
                data: entity.filter((o) => o.publishedAt).map((o) => [o.publishedAt, o.viewCount])
            }]}
        />
    );
}

export default function OrderStatsFetched({ hasRendered }: { hasRendered?: boolean }) {
    return (
        <FetchRedux<OrderStatsType[]>
            fetchEntity={fetchOrderStats()}
            selectEntity={selectOrderStats()}
            selectIsFetching={selectFetchingOrderStats()}
            Component={OrderStats}
            name="statistiken"
            initialFetch={!hasRendered}
        />
    );
}
