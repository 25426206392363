import React from 'react';
import { Spinner } from '../StyledComponents';

const Loading: React.FC = () => {
    return (
        <div
            style={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <Spinner />
        </div>
    );
};

export default Loading;
