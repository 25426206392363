import React from 'react';
import { useParams } from 'react-router-dom';

import { Header, Tabs } from '../CommonComponents';
import { Container } from '../StyledComponents';
import OrderDetails from './OrderDetails';
import OrderOffers from './OrderOffers';
import OrderRequests from './OrderRequests';
import FetchRedux from '../CommonComponents/Fetch/FetchRedux';
import { FetchPropsType } from '../CommonComponents/Fetch/FetchPropsType';
import {
    fetchOrderMetaById,
    selectFetchingOrderMetaById,
    selectOrderMetaById
} from '../../redux/actions/orders';
import { formatDate } from '../../utils/parsing';
import { OrderMetaType } from '../../types';

const tabs = [{
    key: 'order',
    label: 'Uppgifter',
    Component: OrderDetails,
    padding: 2
}, {
    key: 'offers',
    label: 'Anbud',
    Component: OrderOffers
}, {
    key: 'requests',
    label: 'Aktiviteter',
    Component: OrderRequests
}];

function OrderTabs(props: FetchPropsType<OrderMetaType>) {
    const { entity, isFetching } = props;

    const getTitle = React.useCallback((tab) => {
        if (!entity) {
            return tab.label;
        }
        // @ts-ignore
        const count = entity[`${tab.key}Count` as string];
        return typeof count === 'number'
            ? `${tab.label} (${count})`
            : tab.label;
    }, [entity]);

    return (
        <Container>
            <Header
                title={`${formatDate(entity.firstPickupDate)} ${entity.pickupCity} - ${entity.deliveryCity}`}
                subtitle={entity.tenantName}
            />
            <Tabs
                tabsKey="orders"
                tabs={tabs}
                getTitle={getTitle}
            />
        </Container>
    );
}

export default function OrderTabsFetched() {
    const { id } = useParams() as unknown as { id: number };

    return (
        <FetchRedux<OrderMetaType>
            fetchEntity={fetchOrderMetaById(id)}
            selectEntity={selectOrderMetaById(id)}
            selectIsFetching={selectFetchingOrderMetaById(id)}
            Component={OrderTabs}
            name="uppdraget"
        />
    );
}
