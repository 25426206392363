import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    fetchRequestsByTenantId,
    selectFetchingRequestsByTenantId,
    selectRequestsByTenantId
} from '../../redux/actions/requests';
import { RequestForListType } from '../../types';
import { Table } from '../CommonComponents';
import { FetchPropsType } from '../CommonComponents/Fetch/FetchPropsType';
import FetchRedux from '../CommonComponents/Fetch/FetchRedux';

const columns = [{
    name: 'Användare',
    key: 'userName',
    type: 'string' as 'string',
    canSearch: true
}, {
    name: 'Metod',
    key: 'method',
    type: 'string' as 'string',
    canSearch: true
}, {
    name: 'Resurs',
    key: 'url',
    type: 'string' as 'string',
    canSearch: true
}, {
    name: 'Datum',
    key: 'createdAt',
    type: 'datetime' as 'datetime',
    canFilter: true
}];

function TenantRequests(props: FetchPropsType<RequestForListType[]>) {
    const { entity, isFetching } = props;
    const navigate = useNavigate();

    const onRowClick = React.useCallback((request: { id: number }) => {
        navigate(`/requests/${request.id}`);
    }, [navigate]);

    return (
        <Table
            name="tenantRequests"
            columns={columns}
            data={entity}
            onRowClick={onRowClick}
            emptyText={isFetching ? 'Hämtar aktiviteter...' : 'Det finns inga aktiviteter.'}
            showPagination
            defaultOrderBy="createdAt"
            defaultOrderDirection="asc"
        />
    );
}

export default function TenantRequestsFetched({ hasRendered }: { hasRendered?: boolean }) {
    const { id } = useParams() as unknown as { id: number };

    return (
        <FetchRedux<RequestForListType[]>
            fetchEntity={fetchRequestsByTenantId(id)}
            selectEntity={selectRequestsByTenantId(id)}
            selectIsFetching={selectFetchingRequestsByTenantId(id)}
            Component={TenantRequests}
            name="aktiviteter"
            initialFetch={!hasRendered}
        />
    );
}
