import React from 'react';
import {
    Paper as MuiPaper
} from '@mui/material';

type PropsType = {
    padding?: number,
    variant?: 'elevation' | 'outlined'
    sx?: any
};

const Paper: React.FC<PropsType> = ({
    children,
    padding = 2,
    variant = 'elevation',
    sx = {}
}) => {
    return (
        <MuiPaper elevation={variant === 'elevation' ? 3 : 0} sx={{ ...sx, padding }} variant={variant}>
            {children}
        </MuiPaper>
    );
};

export default Paper;
