import React from 'react';
import { useParams } from 'react-router-dom';

import { Header, Tabs } from '../CommonComponents';
import { Container } from '../StyledComponents';
import UserDetails from './UserDetails';
import UserWatchlists from './UserWatchlists';
import FetchRedux from '../CommonComponents/Fetch/FetchRedux';
import { FetchPropsType } from '../CommonComponents/Fetch/FetchPropsType';
import {
    fetchUserMetaById,
    selectFetchingUserMetaById,
    selectUserMetaById
} from '../../redux/actions/users';
import UserRequests from './UserRequests';

const tabs = [{
    key: 'user',
    label: 'Uppgifter',
    Component: UserDetails,
    padding: 2
}, {
    key: 'watchlists',
    label: 'Bevakningar',
    Component: UserWatchlists
}, {
    key: 'requests',
    label: 'Aktiviteter',
    Component: UserRequests
}];

type UserMetaType = {
    [key: string]: number | string
};

function UserTabs(props: FetchPropsType<any>) {
    const { entity, isFetching } = props;

    const getTitle = React.useCallback((tab) => {
        if (!entity) {
            return tab.label;
        }
        const count = entity[`${tab.key}Count` as string];
        return typeof count === 'number'
            ? `${tab.label} (${count})`
            : tab.label;
    }, [entity]);

    return (
        <Container>
            <Header
                title={entity.name as string ?? 'Användare'}
                subtitle={entity.tenantName}
            />
            <Tabs
                tabsKey="users"
                tabs={tabs}
                getTitle={getTitle}
            />
        </Container>
    );
}

export default function UserTabsFetched() {
    const { id } = useParams() as unknown as { id: number };

    return (
        <FetchRedux<UserMetaType>
            fetchEntity={fetchUserMetaById(id)}
            selectEntity={selectUserMetaById(id)}
            selectIsFetching={selectFetchingUserMetaById(id)}
            Component={UserTabs}
            name="användaren"
        />
    );
}
