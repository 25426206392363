import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';

import FormContext, { FormContextValueType } from './FormContext';
import Validation from './validations/ValidationContext';

type PropsType = {
    onSubmit: () => void,
    submitText?: string,
    submitDisabled?: boolean
};

const SimpleForm: React.FC<PropsType> = (props) => {
    const {
        children,
        onSubmit,
        submitText = 'Skicka',
        submitDisabled = false
    } = props;

    const [isSubmitting, setIsSubmitting] = React.useState(false);

    // Errors are handled in onSubmit
    const submit = React.useCallback(async () => {
        setIsSubmitting(true);
        await onSubmit();
        setIsSubmitting(false);
    }, [onSubmit]);

    const formContextValue = React.useMemo<FormContextValueType>(() => ({
        isSubmitting,
        submit
    }), [isSubmitting, submit]);

    return (
        <Validation>
            {(hasError: boolean) => (
                <>
                    <FormContext.Provider value={formContextValue}>
                        {children}
                    </FormContext.Provider>

                    <Box sx={{ mt: 4, mb: 2 }}>
                        <LoadingButton
                            variant="contained"
                            size="large"
                            loadingPosition="center"
                            loading={isSubmitting}
                            disabled={submitDisabled || isSubmitting || hasError}
                            fullWidth
                            onClick={submit}
                        >
                            {submitText}
                        </LoadingButton>
                    </Box>
                </>
            )}
        </Validation>
    );
};

export default SimpleForm;
