/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserForListType, UserMetaType, UserType } from '../../types';

type UsersStateType = {
    all: UserForListType[],
    byTenantId: {[key: number | string]: UserForListType[] },
    fetchingByTenantId: {[key: number | string]: boolean}
    fetchingAll: boolean,
    byId: {[key: number | string]: UserType | undefined },
    fetchingById: {[key: number | string]: boolean},
    metaById: {[key: number | string]: UserMetaType | undefined },
    fetchingMetaById: {[key: number | string]: boolean}
};

const initialState: UsersStateType = {
    all: [],
    fetchingAll: false,
    byTenantId: {},
    fetchingByTenantId: {},
    byId: {},
    fetchingById: {},
    metaById: {},
    fetchingMetaById: {}
};

const slice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        fetchAll: (state) => {
            state.fetchingAll = true;
        },
        allFetched: (state, action: PayloadAction<{data: UsersStateType['all']}>) => {
            state.all = action.payload.data;
            state.fetchingAll = false;
        },
        fetchByTenantId: (state, action) => {
            state.fetchingByTenantId[action.payload.id] = true;
        },
        byTenantIdFetched: (state, action: PayloadAction<{ id: number | string, data: UserForListType[] }>) => {
            state.byTenantId[action.payload.id] = action.payload.data;
            state.fetchingByTenantId[action.payload.id] = false;
        },
        fetchById: (state, action) => {
            state.fetchingById[action.payload.id] = true;
        },
        byIdFetched: (state, action: PayloadAction<{ id: number | string, data?: UserType }>) => {
            state.byId[action.payload.id] = action.payload.data;
            state.fetchingById[action.payload.id] = false;
        },
        fetchMetaById: (state, action) => {
            state.fetchingMetaById[action.payload.id] = true;
        },
        metaByIdFetched: (state, action: PayloadAction<{ id: number | string, data?: UserMetaType }>) => {
            state.metaById[action.payload.id] = action.payload.data;
            state.fetchingMetaById[action.payload.id] = false;
        }
    }
});

export default slice;
