import { useContext, useEffect, } from 'react';

import { ValidationContext, ValidationContextType } from '../validations/ValidationContext';

type ValidationPropsType = {
    name: string,
    type: string,
    isValid: boolean
};

export default function useValidation({ name, type, isValid }: ValidationPropsType) {
    const validation = useContext<ValidationContextType>(ValidationContext);

    useEffect(() => {
        if (validation) {
            validation(name, type).validate(isValid);
        }

        return () => {
            if (validation) {
                validation(name, type).clear();
            }
        };
    }, [name, type, isValid, validation]);

    return !isValid;
}
