/* eslint-disable no-param-reassign */
// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ToastStateType = {
    message: string | null
};

const initialState: ToastStateType = {
    message: ''
};

const slice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        setMessage: (state, action: PayloadAction<string | null>) => {
            state.message = action.payload;
        }
    }
});

export default slice;
