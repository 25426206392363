import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    fetchRequestsByOrderId,
    selectFetchingRequestsByOrderId,
    selectRequestsByOrderId
} from '../../redux/actions/requests';
import { RequestForListType } from '../../types';
import { Table } from '../CommonComponents';
import { FetchPropsType } from '../CommonComponents/Fetch/FetchPropsType';
import FetchRedux from '../CommonComponents/Fetch/FetchRedux';

const columns = [{
    name: 'Företag',
    key: 'tenantName',
    type: 'string' as 'string',
    canSearch: true
}, {
    name: 'Användare',
    key: 'userName',
    type: 'string' as 'string',
    canSearch: true
}, {
    name: 'Metod',
    key: 'method',
    type: 'string' as 'string',
    canSearch: true
}, {
    name: 'Resurs',
    key: 'url',
    type: 'string' as 'string',
    canSearch: true
}, {
    name: 'Datum',
    key: 'createdAt',
    type: 'datetime' as 'datetime',
    canFilter: true
}];

function OrderRequests(props: FetchPropsType<RequestForListType[]>) {
    const { entity, isFetching } = props;

    const navigate = useNavigate();

    const onRowClick = React.useCallback((request: { userId: number }) => {
        navigate(`/users/${request.userId}`);
    }, [navigate]);

    return (
        <Table
            name="requests"
            columns={columns}
            data={entity}
            onRowClick={onRowClick}
            emptyText={isFetching ? 'Hämtar aktiviteter...' : 'Det finns inga aktiviteter.'}
            showPagination
            defaultOrderBy="createdAt"
            defaultOrderDirection="asc"
        />
    );
}

export default function OrderRequestsFetched({ hasRendered }: { hasRendered?: boolean }) {
    const { id } = useParams() as unknown as { id: number };

    return (
        <FetchRedux<RequestForListType[]>
            fetchEntity={fetchRequestsByOrderId(id)}
            selectEntity={selectRequestsByOrderId(id)}
            selectIsFetching={selectFetchingRequestsByOrderId(id)}
            Component={OrderRequests}
            name="aktiviteter"
            initialFetch={!hasRendered}
        />
    );
}
