import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

import {
    useChangeable,
    FullForm,
    TextField,
    EmailField,
    PhoneField,
    AutocompleteField,
    BoolField
} from '../CommonComponents/Form';
import { SectionDivider } from '../CommonComponents';

import {
    createUser,
    deleteUser,
    getUser,
    updateUser
} from '../../api-admin/users';

import toast from '../../utils/toast';
import { UserType } from '../../types';
import { fetchAllTenants, selectAllTenants } from '../../redux/actions/tenants';
import { useDispatch, useSelector } from '../../redux/hooks';

const defaultUser = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    website: '',
    billingEmail: '',
    billingMark: '',
    billingStreet: '',
    billingZip: '',
    billingCity: ''
};

const UserForm: React.FC = () => {
    const { id, tenantId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const tenants = useSelector(selectAllTenants());

    const [entity, onChange] = useChangeable(defaultUser);
    const [user, setUser] = React.useState<UserType | null>(null);

    React.useEffect(() => {
        (async () => {
            if (id) {
                const u = await getUser(id);
                onChange(u);
                setUser(u);
            } else if (tenantId) {
                onChange({
                    tenantId,
                    tenantName: tenants.find((t) => (t.id === Number(tenantId)))?.name || tenantId
                });
            }
        })();
    }, [id, onChange, tenantId, tenants]);

    const onSubmit = React.useCallback(async () => {
        try {
            if (id) {
                await updateUser(id, entity);
                toast('Användaren uppdaterad');
                navigate(-1);
            } else {
                const createdUser = await createUser(entity);
                toast('Användaren skapad');
                navigate(`/users/${createdUser.id}`, { replace: true });
            }
        } catch (e: any) {
            toast(e, `Kunde inte ${id ? 'uppdatera' : 'skapa'} användaren, försök igen`);
        }
    }, [id, entity, navigate]);

    const onDelete = React.useCallback(async () => {
        try {
            if (id) {
                await deleteUser(id);
                toast('Användaren borttagen');
                navigate(-2);
            }
        } catch (e: any) {
            toast(e, 'Kunde inte ta bort användaren, försök igen');
        }
    }, [id, navigate]);

    return (
        <FullForm
            title={`${id ? 'Redigera' : 'Skapa'} användare`}
            entity={entity}
            onSubmit={onSubmit}
            onDelete={id ? onDelete : undefined}
        >
            <Grid container spacing={2}>
                <Grid item md={12} lg={6}>
                    <SectionDivider label="Kontaktuppgifter" marginTop={1} />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AutocompleteField<{
                                id: number | string,
                                name: string,
                                orgNumber?: string
                            }>
                                label="Företag"
                                optionIdKey="id"
                                defaultOption={!entity.tenantId ? null : {
                                    id: entity.tenantId,
                                    name: entity.tenantName || ''
                                }}
                                fetchOptions={() => { dispatch(fetchAllTenants()); }}
                                options={tenants}
                                minQueryLength={3}
                                getOptionName={(o) => o.name}
                                getOptionDescription={(o) => o.orgNumber || ''}
                                onChange={(tenant) => onChange({
                                    tenantId: tenant === null ? null : tenant.id,
                                    tenantName: tenant === null ? null : tenant.name,
                                    tenantOrgNumber: tenant === null ? null : tenant.orgNumber,
                                })}
                                disabled={!!id || !!tenantId}
                                fetchOnlyOnce
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                name="firstName"
                                label="Förnamn"
                                value={entity.firstName}
                                onChange={onChange}
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                name="lastName"
                                label="Efternamn"
                                value={entity.lastName}
                                onChange={onChange}
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                name="title"
                                label="Titel"
                                value={entity.title}
                                onChange={onChange}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <EmailField
                                name="email"
                                label="E-post"
                                value={entity.email}
                                onChange={onChange}
                                required
                                helperText={
                                    user?.emailVerified && entity.email !== user?.email
                                        ? 'Användaren måste verifiera sin e-post igen om du ändrar den.'
                                        : ''
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PhoneField
                                name="phone"
                                label="Mobil"
                                value={entity.phone}
                                onChange={onChange}
                                helperText={
                                    user?.phoneVerified && entity.phone && entity.phone !== user?.phone
                                        ? 'Användaren måste verifiera sitt mobilnummer igen om du ändrar det.'
                                        : ''
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="phoneAlt"
                                label="Telefon"
                                maxLength={20}
                                value={entity.phoneAlt}
                                onChange={onChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12} lg={6}>
                    <Grid container spacing={2}>
                        {!id && (
                            <Grid item xs={12}>
                                <SectionDivider label="Övrigt" marginTop={1} />
                                <BoolField
                                    name="sendInvitation"
                                    label="Skicka inbjudan"
                                    value={entity.sendInvitation}
                                    onChange={onChange}
                                    helperText="Användaruppgifter skickas till e-postadressen när användaren skapats."
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </FullForm>
    );
};

export default UserForm;
