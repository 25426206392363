import React from 'react';
import { useParams } from 'react-router-dom';
import { Avatar, Grid } from '@mui/material';
import { Notifications } from '@mui/icons-material';

import { Block, Header, List } from '../CommonComponents';

import { FetchPropsType } from '../CommonComponents/Fetch/FetchPropsType';
import { WatchlistType } from '../../types';
import { fetchWatchlistById, selectFetchingWatchlistById, selectWatchlistById } from '../../redux/actions/watchlists';
import FetchRedux from '../CommonComponents/Fetch/FetchRedux';
import { Container, Paper } from '../StyledComponents';
import {
    formatDateTime,
    formatDelivery,
    formatPickup,
    formatRange
} from '../../utils/parsing';

function WatchlistDetails(props: FetchPropsType<WatchlistType>) {
    const { entity } = props;

    return (
        <Container>
            <Header
                title={`Bevakning: ${entity.name}`}
                subtitle={`${entity.userName}, ${entity.tenantName}`}
            />
            <Paper padding={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <Avatar sx={{ width: 'auto', height: '80%', maxHeight: 500 }} variant="rounded">
                            <Notifications sx={{ fontSize: 120, p: 2 }} />
                        </Avatar>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                        <Block
                            variant="outlined"
                            title="Grunduppgifter"
                        >
                            <List
                                data={[{
                                    name: 'Företag',
                                    value: entity.tenantName,
                                    url: `/tenants/${entity.tenantId}`
                                }, {
                                    name: 'Användare',
                                    value: entity.userName,
                                    url: `/users/${entity.userId}`
                                }, {
                                    name: 'Namn',
                                    value: entity.name
                                }, {
                                    name: 'Direktnotiser',
                                    value: entity.instantNotifications,
                                    type: 'bool'
                                }, {
                                    name: 'Dagliga notiser',
                                    value: entity.dailyNotifications,
                                    type: 'bool'
                                }, {
                                    name: 'Aktiva e-postnotiser',
                                    value: entity.activeEmailNotifications,
                                    type: 'bool'
                                }, {
                                    name: 'Upphämtningsområde',
                                    value: formatPickup(entity)
                                }, {
                                    name: 'Leveransområde',
                                    value: formatDelivery(entity)
                                }, {
                                    name: 'Totalvikt [kg]',
                                    value: formatRange(entity.minGrossWeight, entity.maxGrossWeight, 'kg')
                                }, {
                                    name: 'FDR-vikt [kg]',
                                    value: formatRange(entity.minChargeableWeight, entity.maxChargeableWeight, 'kg')
                                }, {
                                    name: 'Volym [m³]',
                                    value: formatRange(entity.minVolume, entity.maxVolume, 'm³')
                                }, {
                                    name: 'Flakmeter',
                                    value: formatRange(entity.minLoadingMeters, entity.maxLoadingMeters, 'flm')
                                }, {
                                    name: 'Pallplatser',
                                    value: formatRange(entity.minPalletPlaces, entity.maxPalletPlaces, 'st')
                                }, {
                                    name: 'Pallar',
                                    value: formatRange(entity.minPallets, entity.maxPallets, 'st')
                                }, {
                                    name: 'Endast uppdrag i grupp',
                                    value: entity.groupName,
                                    url: entity.groupId ? `/groups/${entity.groupId}` : undefined
                                }, {
                                    name: 'Endast uppdrag med riktpris',
                                    value: entity.hasListPrice,
                                    type: 'bool'
                                }, {
                                    name: 'Endast uppdrag som tar intresseanmälningar',
                                    value: entity.takesOffer,
                                    type: 'bool'
                                }, {
                                    name: 'Skapad',
                                    value: !entity.createdAt
                                        ? '' : `${formatDateTime(entity.createdAt)} av ${entity.createdByName || '-'}`,
                                    url: entity.createdBy ? `/users/${entity.createdBy}` : undefined
                                }, {
                                    name: 'Uppdaterad',
                                    value: !entity.updatedAt
                                        ? '' : `${formatDateTime(entity.updatedAt)} av ${entity.updatedByName || '-'}`,
                                    url: entity.updatedBy ? `/users/${entity.updatedBy}` : undefined
                                }]}
                            />
                        </Block>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

export default function WatchlistDetailsFetched({ hasRendered }: { hasRendered?: boolean }) {
    const { id } = useParams() as unknown as { id: number };

    return (
        <FetchRedux<WatchlistType>
            fetchEntity={fetchWatchlistById(id)}
            selectEntity={selectWatchlistById(id)}
            selectIsFetching={selectFetchingWatchlistById(id)}
            Component={WatchlistDetails}
            name="bevakningen"
            initialFetch={!hasRendered}
        />
    );
}
