import apiRequest from './api-request';

const resource = 'groups';

export function getGroups() {
    return apiRequest({
        method: 'GET',
        resource
    });
}

export function getGroupsByTenantId(id: number | string) {
    return apiRequest({
        method: 'GET',
        resource: `tenants/${id}/${resource}`
    });
}

export function getGroup(id: number | string) {
    return apiRequest({
        method: 'GET',
        resource,
        id
    });
}

export function getGroupMeta(id: number | string) {
    return apiRequest({
        method: 'GET',
        resource: `${resource}/${id}/meta`
    });
}

export function createGroup(body: any) {
    return apiRequest({
        method: 'POST',
        resource,
        body
    });
}

export function updateGroup(id: number | string, body: any) {
    return apiRequest({
        method: 'PUT',
        resource,
        id,
        body
    });
}

export function deleteGroup(id: number | string) {
    return apiRequest({
        method: 'DELETE',
        resource,
        id
    });
}
