import React, {
    useRef,
    useState,
    useCallback
} from 'react';

type EntityType = any;
// {
//     [key: string]: any
// };

type UseChangeableReturnType = [
    EntityType,
    (a: EntityType | React.ChangeEvent<HTMLInputElement>, b?: { reset: boolean }) => void
];

const useChangeable = (defaultEntity: EntityType): UseChangeableReturnType => {
    const entityRef = useRef(defaultEntity);
    const [entity, setEntity] = useState(entityRef.current);

    const onChange = useCallback((e, { reset } = { reset: false }) => {
        const isEvent = e && e.target && e.target.name;

        if (isEvent) {
            const { name } = e.target;
            const newValue = e.target.type === 'checkbox'
                ? e.target.checked
                : e.target.value;

            entityRef.current = {
                ...entityRef.current,
                [name]: newValue
            };
        } else {
            const changes = e;

            if (reset) {
                entityRef.current = changes;
            } else {
                entityRef.current = changes
                    ? {
                        ...entityRef.current,
                        ...changes
                    }
                    : {};
            }
        }

        setEntity(entityRef.current);
    }, []);

    return [entity, onChange];
};

export default useChangeable;
