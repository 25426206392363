import dashboards from '../slices/dashboard';
import type { RootStateType, ThunkDispatchType } from '../index';

import { getData } from '../../api-admin/dashboard';

export function fetchDashboardData() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(dashboards.actions.fetchData());
            const data = await getData();
            dispatch(dashboards.actions.dataFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching dashboard data, set redux error state and handle in errorBoundary.', e);
            dispatch(dashboards.actions.resetData());
        }
    };
}

export const selectDashboardData = () => (state: RootStateType) => state.dashboard.data;
export const selectFetchingDashboardData = () => (state: RootStateType) => state.dashboard.fetchingData;
