import apiRequest from './api-request';

const resource = 'stats';

export function getTenantStats() {
    return apiRequest({
        method: 'GET',
        resource: `${resource}/tenants`
    });
}

export function getUserStats() {
    return apiRequest({
        method: 'GET',
        resource: `${resource}/users`
    });
}

export function getOrderStats() {
    return apiRequest({
        method: 'GET',
        resource: `${resource}/orders`
    });
}

export function getGroupStats() {
    return apiRequest({
        method: 'GET',
        resource: `${resource}/groups`
    });
}

export function getDhlStats() {
    return apiRequest({
        method: 'GET',
        resource: `${resource}/dhl`
    });
}
