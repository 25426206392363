import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Header, Table } from '../CommonComponents';
import { Container, Paper } from '../StyledComponents';

import { useDispatch, useSelector } from '../../redux/hooks';
import { fetchAllRequests, selectAllRequests, selectFetchingAllRequests } from '../../redux/actions/requests';

const columns = [{
    name: 'Företag',
    key: 'tenantName',
    type: 'string' as 'string',
    canSearch: true
}, {
    name: 'Användare',
    key: 'userName',
    type: 'string' as 'string',
    canSearch: true
}, {
    name: 'Metod',
    key: 'method',
    type: 'string' as 'string',
    canSearch: true
}, {
    name: 'Resurs',
    key: 'url',
    type: 'string' as 'string',
    canSearch: true
}, {
    name: 'Datum',
    key: 'createdAt',
    type: 'datetime' as 'datetime',
    canFilter: true
}];

export default function Requests() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const requests = useSelector(selectAllRequests());
    const isFetching = useSelector(selectFetchingAllRequests());

    React.useEffect(() => {
        dispatch(fetchAllRequests());
    }, [dispatch]);

    const onRowClick = React.useCallback((request: { userId: number }) => {
        navigate(`/users/${request.userId}`);
    }, [navigate]);

    return (
        <Container>
            <Header
                title="Aktiviteter, 1000 senaste"
            />

            <Paper padding={0}>
                <Table
                    name="requests"
                    columns={columns}
                    data={requests}
                    onRowClick={onRowClick}
                    emptyText={isFetching ? 'Hämtar aktiviteter...' : 'Det finns inga aktiviteter.'}
                    showPagination
                    defaultOrderBy="createdAt"
                    defaultOrderDirection="asc"
                />
            </Paper>
        </Container>
    );
}
