import auth from '../slices/auth';
import type { RootStateType } from '../index';

export const {
    setIsLoading,
    setIsLoggedIn,
} = auth.actions;

export const selectIsLoading = () => (state: RootStateType) => state.auth.isLoading;

export const selectIsLoggedIn = () => (state: RootStateType) => state.auth.isLoggedIn;
