/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WatchlistForListType, WatchlistForUserListType, WatchlistType } from '../../types';

type WatchlistsStateType = {
    all: WatchlistForListType[],
    byUserId: {[key: number | string]: WatchlistForUserListType[] },
    fetchingByUserId: {[key: number | string]: boolean}
    fetchingAll: boolean,
    byId: {[key: number | string]: WatchlistType | undefined },
    fetchingById: {[key: number | string]: boolean}
};

const initialState: WatchlistsStateType = {
    all: [],
    fetchingAll: false,
    byUserId: {},
    fetchingByUserId: {},
    byId: {},
    fetchingById: {}
};

const slice = createSlice({
    name: 'watchlists',
    initialState,
    reducers: {
        fetchAll: (state) => {
            state.fetchingAll = true;
        },
        allFetched: (state, action: PayloadAction<{data: WatchlistsStateType['all']}>) => {
            state.all = action.payload.data;
            state.fetchingAll = false;
        },
        fetchByUserId: (state, action) => {
            state.fetchingByUserId[action.payload.id] = true;
        },
        byUserIdFetched: (state, action: PayloadAction<{ id: number | string, data: WatchlistForUserListType[] }>) => {
            state.byUserId[action.payload.id] = action.payload.data;
            state.fetchingByUserId[action.payload.id] = false;
        },
        fetchById: (state, action) => {
            state.fetchingById[action.payload.id] = true;
        },
        byIdFetched: (state, action: PayloadAction<{ id: number | string, data?: WatchlistType }>) => {
            state.byId[action.payload.id] = action.payload.data;
            state.fetchingById[action.payload.id] = false;
        }
    }
});

export default slice;
