import store from '../redux';

import { setIsLoggedIn } from '../redux/actions/auth';
import { setMessage } from '../redux/actions/toast';
import { fetchAdminProfile } from '../redux/actions/admins';

import { getCurrentUser } from './auth';

// Errors must be handled when used
export default async function login(): Promise<void> {
    await getCurrentUser();
    store.dispatch(fetchAdminProfile());
    store.dispatch(setIsLoggedIn(true));
    store.dispatch(setMessage('Välkommen till Logic Link!'));
}
