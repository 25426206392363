import { createContext } from 'react';

export type AuthValueType = {
    email: string,
    password: string,
    code: string
};

export type AuthContextValueType = {
    values: AuthValueType,
    onChange: (a: any) => void
};

export const defaultAuthValues: AuthValueType = {
    email: '',
    password: '',
    code: ''
};

const defaultAuthContext = {
    values: defaultAuthValues,
    onChange: (a: any) => {}
};

const AuthContext = createContext(defaultAuthContext);

export default AuthContext;
