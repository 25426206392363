import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    fetchOrdersByGroupId,
    selectFetchingOrdersByGroupId,
    selectOrdersByGroupId
} from '../../redux/actions/orders';
import { OrderForListType, OrderStatusType } from '../../types';
import { formatOrderStatus } from '../../utils/parsing';
import { Table } from '../CommonComponents';
import { FetchPropsType } from '../CommonComponents/Fetch/FetchPropsType';
import FetchRedux from '../CommonComponents/Fetch/FetchRedux';

const columns = [{
    name: 'Nummer',
    key: 'id',
    canSearch: true
}, {
    name: 'Upphämtningsdatum',
    key: 'firstPickupDate',
    type: 'date' as 'date',
    canFilter: true
}, {
    name: 'Upphämtningsort',
    key: 'pickupCity',
    canSearch: true
}, {
    name: 'Leveransort',
    key: 'deliveryCity',
    canSearch: true
}, {
    name: 'Totalvikt',
    key: 'grossWeight',
    type: 'kg' as 'kg',
    canFilter: true
}, {
    name: 'Antal anbud',
    key: 'offerCount',
    type: 'number' as 'number',
    canFilter: true
}, {
    name: 'Status',
    key: 'status',
    getValue: ({ status }: { status: OrderStatusType }) => formatOrderStatus(status),
    canSearch: true,
    canFilter: true
}, {
    name: 'Skapades',
    key: 'createdAt',
    type: 'datetime' as 'datetime',
    canFilter: true
}];

function GroupOrders(props: FetchPropsType<OrderForListType[]>) {
    const { entity, isFetching } = props;
    const navigate = useNavigate();

    const onRowClick = React.useCallback((order: { id: number }) => {
        navigate(`/orders/${order.id}`);
    }, [navigate]);

    return (
        <Table
            name="groupOrders"
            columns={columns}
            data={entity}
            onRowClick={onRowClick}
            emptyText={isFetching ? 'Hämtar uppdrag...' : 'Det finns inga uppdrag.'}
            showPagination
            defaultOrderBy="createdAt"
            defaultOrderDirection="asc"
        />
    );
}

export default function GroupDetailsFetched({ hasRendered }: { hasRendered?: boolean }) {
    const { id } = useParams() as unknown as { id: number };

    return (
        <FetchRedux<OrderForListType[]>
            fetchEntity={fetchOrdersByGroupId(id)}
            selectEntity={selectOrdersByGroupId(id)}
            selectIsFetching={selectFetchingOrdersByGroupId(id)}
            Component={GroupOrders}
            name="uppdrag"
            initialFetch={!hasRendered}
        />
    );
}
