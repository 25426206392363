import React from 'react';
import { CircularProgress } from '@mui/material';

const Spinner: React.FC = () => {
    return (
        <CircularProgress color="primary" />
    );
};

export default Spinner;
