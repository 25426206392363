import React from 'react';
import { useParams } from 'react-router-dom';

import { Header, Tabs } from '../CommonComponents';
import { Container } from '../StyledComponents';
import TenantDetails from './TenantDetails';
import TenantUsers from './TenantUsers';
import TenantGroups from './TenantGroups';
import TenantOffers from './TenantOffers';
import TenantOrders from './TenantOrders';
import FetchRedux from '../CommonComponents/Fetch/FetchRedux';
import { FetchPropsType } from '../CommonComponents/Fetch/FetchPropsType';
import { fetchTenantMetaById, selectFetchingTenantMetaById, selectTenantMetaById } from '../../redux/actions/tenants';
import TenantRequests from './TenantRequests';

const tabs = [{
    key: 'tenant',
    label: 'Uppgifter',
    Component: TenantDetails,
    padding: 2
}, {
    key: 'users',
    label: 'Användare',
    Component: TenantUsers
}, {
    key: 'groups',
    label: 'Grupper',
    Component: TenantGroups
}, {
    key: 'orders',
    label: 'Uppdrag',
    Component: TenantOrders
}, {
    key: 'offers',
    label: 'Anbud',
    Component: TenantOffers
}, {
    key: 'requests',
    label: 'Aktiviteter',
    Component: TenantRequests
}];

type TenantMetaType = {
    [key: string]: number | string
    // name: string,
    // usersCount: number,
    // groupsCount: number,
    // ordersCount: number,
    // offersCount: number
};

function TenantTabs(props: FetchPropsType<TenantMetaType>) {
    const { entity, isFetching } = props;

    const getTitle = React.useCallback((tab) => {
        if (!entity) {
            return tab.label;
        }
        const count = entity[`${tab.key}Count` as string];
        return typeof count === 'number'
            ? `${tab.label} (${count})`
            : tab.label;
    }, [entity]);

    return (
        <Container>
            <Header
                title={entity.name as string ?? 'Företag'}
                subtitle={entity.orgNumber as string}
            />
            <Tabs
                tabsKey="tenants"
                tabs={tabs}
                getTitle={getTitle}
            />
        </Container>
    );
}

export default function TenantTabsFetched() {
    const { id } = useParams() as unknown as { id: number };

    return (
        <FetchRedux<TenantMetaType>
            fetchEntity={fetchTenantMetaById(id)}
            selectEntity={selectTenantMetaById(id)}
            selectIsFetching={selectFetchingTenantMetaById(id)}
            Component={TenantTabs}
            name="företaget"
        />
    );
}
