import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';

type PropsType = {
    children: React.ReactNode | string,
    variant?: 'button'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'inherit'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'overline'
};

const InfoText: React.FC<PropsType> = (props) => {
    const { children, variant = 'body1' } = props;

    return (
        <Box sx={{ p: 2 }}>
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <InfoIcon color="action" />
                </Grid>
                <Grid item xs>
                    <Typography variant={variant}>
                        {children}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default InfoText;
