import React, { createContext, useState, useCallback } from 'react';

type StateType = {
    [key: string]: boolean
};

type PropsType = {
    children: any
};

export type ValidationType = {
    type: string,
    isValid: boolean,
    errorText: string
};

export type ValidationContextType = (nameArg: string, typeArg: string) => ({
    validate: (isValidArg: boolean) => void,
    clear: () => void
});

export type ContextType = (nameArg: string, typeArg: string) => ({
    validate: (isValidArg: boolean) => void,
    clear: () => void
});

export const ValidationContext = createContext<ValidationContextType>(
    (name: string, type: string) => ({
        validate: (isValidArg: boolean) => null,
        clear: () => null
    })
);

const Validation: React.FC<PropsType> = (props) => {
    const { children } = props;

    const [fields, setFields] = useState<StateType>({});

    const validation = useCallback((name, type) => {
        const typedName = type ? `${name}-${type}` : name;

        const updateTypedName = (isValid: boolean) => {
            setFields((prevState: StateType) => ({
                ...prevState,
                [typedName]: !isValid
            }));
        };

        return ({
            validate(isValid: boolean) {
                updateTypedName(isValid);
            },
            clear() {
                updateTypedName(true);
            }
        });
    }, []);

    return (
        <ValidationContext.Provider value={validation}>
            {children(Object.values(fields).some((hasError) => hasError))}
        </ValidationContext.Provider>
    );
};

export default Validation;
