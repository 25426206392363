import React from 'react';
import Typography from '@mui/material/Typography';

type PropsType = {
    variant?: 'h6'
        | 'h1'
        | 'h2'
        | 'h3'
        | 'h4'
        | 'h5'
        | 'subtitle1'
        | 'subtitle2'
        | 'body1'
        | 'body2'
        | 'caption'
        | 'button'
        | 'overline'
        | 'inherit'
        | undefined
}

const Title: React.FC<PropsType> = (props) => {
    const {
        children,
        variant = 'h6'
    } = props;

    return (
        <Typography
            component="h2"
            variant={variant}
            color="primary"
            gutterBottom
        >
            {children}
        </Typography>
    );
};

export default Title;
