import { AnyAction, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import auth from './slices/auth';
import admins from './slices/admins';
import dashboard from './slices/dashboard';
import feedback from './slices/feedback';
import groups from './slices/groups';
import invites from './slices/invites';
// import notifications from './slices/notifications';
import orders from './slices/orders';
import offers from './slices/offers';
import requests from './slices/requests';
import settings from './slices/settings';
import stats from './slices/stats';
import tables from './slices/tables';
import tabs from './slices/tabs';
import tenants from './slices/tenants';
import toast from './slices/toast';
import users from './slices/users';
import watchlists from './slices/watchlists';

const store = configureStore({
    reducer: {
        auth: auth.reducer,
        admins: admins.reducer,
        dashboard: dashboard.reducer,
        feedback: feedback.reducer,
        groups: groups.reducer,
        invites: invites.reducer,
        // notifications: notifications.reducer,
        offers: offers.reducer,
        orders: orders.reducer,
        requests: requests.reducer,
        settings: settings.reducer,
        stats: stats.reducer,
        tables: tables.reducer,
        tabs: tabs.reducer,
        tenants: tenants.reducer,
        toast: toast.reducer,
        users: users.reducer,
        watchlists: watchlists.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
});

export default store;

export type DispatchType = typeof store.dispatch;

export type RootStateType = ReturnType<typeof store.getState>;

export type ThunkDispatchType = ThunkDispatch<RootStateType, unknown, AnyAction>;
// // If returning promise, use: ThunkType<Promise<SomeReturnType>>
// export type ThunkType<ReturnType = void> = ThunkAction<
//     ReturnType,
//     RootState,
//     unknown,
//     AnyAction
// >;
