import groups from '../slices/groups';
import type { RootStateType, ThunkDispatchType } from '../index';

import {
    getGroups,
    getGroupsByTenantId,
    getGroup,
    getGroupMeta
} from '../../api-admin/groups';

import { getGroupInvitationsByGroupId } from '../../api-admin/group-invitations';

export function fetchAllGroups() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(groups.actions.fetchAll());
            const data = await getGroups();
            dispatch(groups.actions.allFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching groups, set redux error state and handle in errorBoundary.', e);
            dispatch(groups.actions.allFetched({ data: [] }));
        }
    };
}

export function fetchGroupsByTenantId(id: number | string) {
    if (!id) {
        throw Error('No id supplied for tenant');
    }
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(groups.actions.fetchByTenantId({ id }));
            const data = await getGroupsByTenantId(id);
            dispatch(groups.actions.byTenantIdFetched({ id, data }));
        } catch (e) {
            console.log('ERROR fetching groups for tenant, set redux error state and handle in errorBoundary.', e);
            dispatch(groups.actions.byTenantIdFetched({ id, data: [] }));
        }
    };
}

export function fetchGroupById(id: number | string) {
    if (!id) {
        throw Error('No id supplied for group');
    }
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(groups.actions.fetchById({ id }));
            const data = await getGroup(id);
            dispatch(groups.actions.byIdFetched({ id, data }));
        } catch (e) {
            console.log('ERROR fetching group, set redux error state and handle in errorBoundary.', e);
            dispatch(groups.actions.byIdFetched({ id }));
        }
    };
}

export function fetchGroupMetaById(id: number | string) {
    if (!id) {
        throw Error('No id supplied for group');
    }
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(groups.actions.fetchMetaById({ id }));
            const data = await getGroupMeta(id);
            dispatch(groups.actions.metaByIdFetched({ id, data }));
        } catch (e) {
            console.log('ERROR fetching group meta, set redux error state and handle in errorBoundary.', e);
            dispatch(groups.actions.metaByIdFetched({ id }));
        }
    };
}

export function fetchGroupInvitationsByGroupId(id: number | string) {
    if (!id) {
        throw Error('No id supplied for group');
    }
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(groups.actions.fetchGroupInvitationsByGroupId({ id }));
            const data = await getGroupInvitationsByGroupId(id);
            dispatch(groups.actions.groupInvitationsByGroupIdFetched({ id, data }));
        } catch (e) {
            console.log('ERROR fetching group invitations, set redux error state and handle in errorBoundary.', e);
            dispatch(groups.actions.groupInvitationsByGroupIdFetched({ id, data: [] }));
        }
    };
}

export const selectAllGroups = () => (state: RootStateType) => state.groups.all;
export const selectFetchingAllGroups = () => (state: RootStateType) => state.groups.fetchingAll;

export const selectGroupsByTenantId = (id: number | string) => (state: RootStateType) => (
    state.groups.byTenantId[id] ?? []
);
export const selectFetchingGroupsByTenantId = (id: number | string) => (state: RootStateType) => (
    state.groups.fetchingByTenantId[id] ?? false
);

export const selectGroupById = (id: number | string) => (state: RootStateType) => (
    state.groups.byId[id]
);
export const selectFetchingGroupById = (id: number | string) => (state: RootStateType) => (
    state.groups.fetchingById[id]
);

export const selectGroupMetaById = (id: number | string) => (state: RootStateType) => (
    state.groups.metaById[id]
);
export const selectFetchingGroupMetaById = (id: number | string) => (state: RootStateType) => (
    state.groups.fetchingMetaById[id]
);

export const selectGroupInvitationsByGroupId = (id: number | string) => (state: RootStateType) => (
    state.groups.groupInvitationsByGroupId[id]
);
export const selectFetchingGroupInvitationsByGroupId = (id: number | string) => (state: RootStateType) => (
    state.groups.fetchingGroupInvitationsByGroupId[id]
);
