import invites from '../slices/invites';
import type { RootStateType, ThunkDispatchType } from '../index';

import { getInvites } from '../../api-admin/invites';

export function fetchAllInvites() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(invites.actions.fetchAll());
            const data = await getInvites();
            dispatch(invites.actions.allFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching invites, set redux error state and handle in errorBoundary.', e);
            dispatch(invites.actions.allFetched({ data: [] }));
        }
    };
}

export const selectAllInvites = () => (state: RootStateType) => state.invites.all;
export const selectFetchingAllInvites = () => (state: RootStateType) => state.invites.fetchingAll;
