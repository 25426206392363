import React from 'react';

import { FetchPropsType } from '../CommonComponents/Fetch/FetchPropsType';
import { UserStatsType } from '../../types';
import {
    fetchUserStats,
    selectFetchingUserStats,
    selectUserStats
} from '../../redux/actions/stats';
import FetchRedux from '../CommonComponents/Fetch/FetchRedux';
import { BarChart } from '../CommonComponents/Charts';

function UserStats(props: FetchPropsType<UserStatsType>) {
    const { entity } = props;

    return (
        <BarChart
            title="Användarstatistik"
            sets={[{
                label: 'Aktiva användare (unika per dag)',
                data: entity.activeUsers
            }, {
                label: 'Skapade användare',
                data: entity.createdUsers
            }, {
                label: 'Skapade bevakningar',
                data: entity.createdWatchlists
            }]}
        />
    );
}

export default function UserStatsFetched({ hasRendered }: { hasRendered?: boolean }) {
    return (
        <FetchRedux<UserStatsType>
            fetchEntity={fetchUserStats()}
            selectEntity={selectUserStats()}
            selectIsFetching={selectFetchingUserStats()}
            Component={UserStats}
            name="statistiken"
            initialFetch={!hasRendered}
        />
    );
}
