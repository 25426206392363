import feedback from '../slices/feedback';
import type { RootStateType, ThunkDispatchType } from '../index';

import { getFeedback } from '../../api-admin/feedback';

export function fetchAllFeedback() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(feedback.actions.fetchAll());
            const data = await getFeedback();
            dispatch(feedback.actions.allFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching feedback, set redux error state and handle in errorBoundary.', e);
            dispatch(feedback.actions.allFetched({ data: [] }));
        }
    };
}

export const selectAllFeedback = () => (state: RootStateType) => state.feedback.all;
export const selectFetchingAllFeedback = () => (state: RootStateType) => state.feedback.fetchingAll;
