import React from 'react';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select
} from '@mui/material';

import { ValidationType } from '../validations/ValidationContext';
import { validateRequired } from '../validations/validations';
import useValidation from '../hooks/useValidation';
import FormContext from '../FormContext';

type OptionType = { value: any, label: string };

type PropsType = {
    name: string,
    label: string,
    value: string,
    required?: boolean,
    options: OptionType[],
    helperText?: string,
    submitOnEnter?: boolean,
    disabled?: boolean,
    onChange: (a: any) => void,
};

export default function SelectField(props: PropsType) {
    const {
        name,
        label,
        options,
        required,
        value,
        submitOnEnter,
        disabled,
        helperText,
        onChange
    } = props;

    const validations = typeof required !== 'undefined'
        ? [validateRequired({ value: !required || value })]
        : [];

    const errors = validations
        // useValidation registers the validation and returns isValid for filter
        // eslint-disable-next-line react-hooks/rules-of-hooks
        .filter((v: ValidationType) => useValidation({ name, type: v.type, isValid: v.isValid }));

    const formContext = React.useContext(FormContext);
    const isSubmitting = formContext?.isSubmitting;
    const submit = formContext?.submit;

    const submitIfEnter = React.useCallback((event) => {
        if (submitOnEnter && event.key === 'Enter' && submit) {
            event.preventDefault();
            submit();
        }
    }, [submitOnEnter, submit]);

    return (
        <FormControl fullWidth variant="standard">
            <InputLabel id="demo-simple-select-label">
                {label}
            </InputLabel>
            <Select
                labelId={`${name}-label`}
                id={name}
                value={value}
                label={label}
                onChange={({ target: { value: newValue } }) => onChange({ [name]: newValue })}
                error={!!errors.length}
                onKeyPress={submitIfEnter}
                disabled={disabled || isSubmitting}
            >
                {options.map((o: OptionType) => (
                    <MenuItem value={o.value} key={o.value}>{o.label}</MenuItem>
                ))}
            </Select>
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    );
}
