import React from 'react';

import { FetchPropsType } from '../CommonComponents/Fetch/FetchPropsType';
import { DhlStatsType } from '../../types';
import {
    fetchDhlStats,
    selectFetchingDhlStats,
    selectDhlStats
} from '../../redux/actions/stats';
import FetchRedux from '../CommonComponents/Fetch/FetchRedux';
import { BarChart } from '../CommonComponents/Charts';

function DhlStats(props: FetchPropsType<DhlStatsType[]>) {
    const { entity } = props;

    return (
        <BarChart
            title="DHL-uppdrag"
            sets={[{
                label: 'Publicerade',
                data: entity.map<[string, number]>((e) => [e.loadingDate, e.orderCount])
            }, {
                label: 'Visningar',
                data: entity.map<[string, number]>((e) => [e.loadingDate, e.viewCount])
            }]}
        />
    );
}

export default function DhlStatsFetched({ hasRendered }: { hasRendered?: boolean }) {
    return (
        <FetchRedux<DhlStatsType[]>
            fetchEntity={fetchDhlStats()}
            selectEntity={selectDhlStats()}
            selectIsFetching={selectFetchingDhlStats()}
            Component={DhlStats}
            name="statistiken"
            initialFetch={!hasRendered}
        />
    );
}
