import { formatDate, formatDateTime } from '../../../utils/parsing';
import { ColumnType, getRowValue } from './Table';

export type FiltersType = {
    columnKey: string,
    key: string,
    label: string,
    getDescription: (testValue: string) => string,
    test: (row: any, testValue: string) => boolean,
    options?: {
        label: string,
        value: string
    }[]
}[];

type FormatValueType = { formatValue: (v: string) => string | number };

// TEST GENERATORS
function equals(column: ColumnType) {
    return ({
        columnKey: column.key,
        key: `${column.key}-equals`,
        label: 'Lika med',
        getDescription: (testValue: string) => ( // testValue can be boolean
            // eslint-disable-next-line no-nested-ternary
            typeof testValue === 'boolean'
                ? (testValue ? column.name : `Inte ${column.name.toLowerCase()}`)
                : `${column.name} är "${String(testValue)}"`
        ),
        test: (row: any, testValue: string) => getRowValue({ column, row }) === testValue // testValue can be boolean
    });
}

function hasValue(column: ColumnType) {
    return ({
        columnKey: column.key,
        key: `${column.key}-hasValue`,
        label: 'Har värde',
        getDescription: (testValue: string) => ( // testValue is boolean
            testValue
                ? `${column.name} har värde`
                : `${column.name} saknar värde`
        ),
        test: (row: any, testValue: string) => {
            const value = getRowValue({ column, row });
            return (!!testValue === ( // testValue is boolean
                typeof value !== 'undefined'
                && value !== null
                && value !== ''
            ));
        },
        options: [
            { label: '', value: '' },
            { label: 'Ja', value: true },
            { label: 'Nej', value: false },
        ]
    });
}

function includes(column: ColumnType) {
    return ({
        columnKey: column.key,
        key: `${column.key}-includes`,
        label: 'Innehåller',
        getDescription: (testValue: string) => `${column.name} innehåller "${testValue.toLowerCase()}"`,
        test: (row: any, testValue: string) => (
            `${getRowValue({ column, row })}`
        ).toLowerCase().includes(testValue.toLowerCase())
    });
}

function getGreaterThan({ formatValue, ...column }: ColumnType & FormatValueType) {
    return ({
        columnKey: column.key,
        key: `${column.key}-greaterThan`,
        label: 'Större än',
        getDescription: (testValue: string) => `${column.name} > ${formatValue(testValue)}`,
        test: (row: any, testValue: string) => (
            formatValue(getRowValue({ column, row })) > formatValue(testValue)
        )
    });
}

function getLessThan({ formatValue, ...column }: ColumnType & FormatValueType) {
    return ({
        columnKey: column.key,
        key: `${column.key}-lessThan`,
        label: 'Mindre än',
        getDescription: (testValue: string) => `${column.name} < ${formatValue(testValue)}`,
        test: (row: any, testValue: string) => (
            formatValue(getRowValue({ column, row })) < formatValue(testValue)
        )
    });
}

// BASE TESTS
// function empty(testValue) {
//     return testValue === null || testValue === '';
// }

// -- function notEmpty(testValue) {
// --     return !empty(testValue);
// -- }

// -- function notEquals(testValue, cmpValue) {
// --     return !equals(testValue);
// -- }

// function greaterThan(testValue, cmpValue) {
//     return testValue > cmpValue;
// }

// function lessThan(testValue, cmpValue) {
//     return testValue < cmpValue;
// }
/*
returns
*/
export default function getTableFiltersByKey(column: ColumnType) {
    switch (column.type) {
        case 'bool':
            return [
                equals(column)
            ];
        case 'number':
            return [
                getGreaterThan({
                    ...column,
                    formatValue: parseFloat
                }),
                getLessThan({
                    ...column,
                    formatValue: parseFloat
                }),
                hasValue(column)
                // notEquals,
                // isEmpty,
                // isNotEmpty
            ];
        case 'date':
            return [
                getGreaterThan({
                    ...column,
                    formatValue: formatDate
                }),
                getLessThan({
                    ...column,
                    formatValue: formatDate
                }),
                hasValue(column)
                // notEquals,
                // isEmpty,
                // isNotEmpty
            ];
        case 'datetime':
            return [
                getGreaterThan({
                    ...column,
                    formatValue: formatDateTime
                }),
                getLessThan({
                    ...column,
                    formatValue: formatDateTime
                }),
                hasValue(column)
                // notEquals,
                // isEmpty,
                // isNotEmpty
            ];
        case 'string':
        default:
            return [
                includes(column),
                hasValue(column)
                // contains,
                // notContains,
                // isEmpty
                // isNotEmpty
            ];
    }
}
