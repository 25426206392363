import store from '../redux';
import admins from '../redux/slices/admins';
import { setIsLoggedIn } from '../redux/actions/auth';

import { signOut } from './auth';
// import toastMessage from './toast'; // Får inte toasta utanför komponent i nuläget...

// Errors must be handled when used
// export default async function logout(msg = 'Välkommen åter!') {
export default async function logout() {
    if ((store.getState())?.auth?.isLoggedIn) {
        store.dispatch(setIsLoggedIn(false));

        // Cannot use actions.clearUserProfile since circular dependecy, so set valur directly
        store.dispatch(admins.actions.profileFetched({ data: null }));

        // const token = (await getExpoPushTokenAsync()).data;
        // await removePushToken({ token });

        signOut();
        // toastMessage(msg);
    }
}
