/* eslint-disable no-param-reassign */
// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AuthStateType = {
    isLoading: boolean,
    isLoggedIn: boolean
};

const initialState: AuthStateType = {
    isLoading: true,
    isLoggedIn: false
};

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
            state.isLoggedIn = action.payload;
        }
    }
});

export default slice;
