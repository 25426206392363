import orders from '../slices/orders';
import type { RootStateType, ThunkDispatchType } from '../index';

import {
    getOrders,
    getOrdersByTenantId,
    getOrdersByGroupId,
    getOrder,
    getOrderMeta
} from '../../api-admin/orders';

export function fetchAllOrders() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(orders.actions.fetchAll());
            const data = await getOrders();
            dispatch(orders.actions.allFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching orders, set redux error state and handle in errorBoundary.', e);
            dispatch(orders.actions.allFetched({ data: [] }));
        }
    };
}

export function fetchOrdersByTenantId(id: number | string) {
    if (!id) {
        throw Error('No id supplied for tenant');
    }
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(orders.actions.fetchByTenantId({ id }));
            const data = await getOrdersByTenantId(id);
            dispatch(orders.actions.byTenantIdFetched({ id, data }));
        } catch (e) {
            console.log('ERROR fetching orders for tenant, set redux error state and handle in errorBoundary.', e);
            dispatch(orders.actions.byTenantIdFetched({ id, data: [] }));
        }
    };
}

export function fetchOrdersByGroupId(id: number | string) {
    if (!id) {
        throw Error('No id supplied for group');
    }
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(orders.actions.fetchByGroupId({ id }));
            const data = await getOrdersByGroupId(id);
            dispatch(orders.actions.byGroupIdFetched({ id, data }));
        } catch (e) {
            console.log('ERROR fetching orders for group, set redux error state and handle in errorBoundary.', e);
            dispatch(orders.actions.byGroupIdFetched({ id, data: [] }));
        }
    };
}

export function fetchOrderById(id: number | string) {
    if (!id) {
        throw Error('No id supplied for order');
    }
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(orders.actions.fetchById({ id }));
            const data = await getOrder(id);
            dispatch(orders.actions.byIdFetched({ id, data }));
        } catch (e) {
            console.log('ERROR fetching orders for order, set redux error state and handle in errorBoundary.', e);
            dispatch(orders.actions.byIdFetched({ id }));
        }
    };
}

export function fetchOrderMetaById(id: number | string) {
    if (!id) {
        throw Error('No id supplied for order');
    }
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(orders.actions.fetchMetaById({ id }));
            const data = await getOrderMeta(id);
            dispatch(orders.actions.metaByIdFetched({ id, data }));
        } catch (e) {
            console.log('ERROR fetching order meta, set redux error state and handle in errorBoundary.', e);
            dispatch(orders.actions.metaByIdFetched({ id }));
        }
    };
}

export const selectAllOrders = () => (state: RootStateType) => state.orders.all;
export const selectFetchingAllOrders = () => (state: RootStateType) => state.orders.fetchingAll;

export const selectOrdersByTenantId = (id: number | string) => (state: RootStateType) => (
    state.orders.byTenantId[id] ?? []
);
export const selectFetchingOrdersByTenantId = (id: number | string) => (state: RootStateType) => (
    state.orders.fetchingByTenantId[id] ?? false
);

export const selectOrdersByGroupId = (id: number | string) => (state: RootStateType) => (
    state.orders.byGroupId[id] ?? []
);
export const selectFetchingOrdersByGroupId = (id: number | string) => (state: RootStateType) => (
    state.orders.fetchingByGroupId[id] ?? false
);

export const selectOrderById = (id: number | string) => (state: RootStateType) => (
    state.orders.byId[id]
);
export const selectFetchingOrderById = (id: number | string) => (state: RootStateType) => (
    state.orders.fetchingById[id] ?? false
);

export const selectOrderMetaById = (id: number | string) => (state: RootStateType) => (
    state.orders.metaById[id]
);
export const selectFetchingOrderMetaById = (id: number | string) => (state: RootStateType) => (
    state.orders.fetchingMetaById[id] ?? false
);
