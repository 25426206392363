import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Avatar, Button, Grid } from '@mui/material';
import { Edit, LocalShipping } from '@mui/icons-material';

import { Block, List } from '../CommonComponents';

import { FetchPropsType } from '../CommonComponents/Fetch/FetchPropsType';
import { OrderType } from '../../types';
import { fetchOrderById, selectFetchingOrderById, selectOrderById } from '../../redux/actions/orders';
import FetchRedux from '../CommonComponents/Fetch/FetchRedux';
import {
    formatDateTime,
    formatLoadArray,
    formatNumber,
    formatOrderStatus,
    getOrderDeliveryPlace,
    getOrderPickupDateTime,
    getOrderPickupPlace
} from '../../utils/parsing';

function OrderDetails(props: FetchPropsType<OrderType>) {
    const { entity } = props;
    const navigate = useNavigate();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
                <Avatar sx={{ width: 'auto', height: '80%', maxHeight: 500 }} variant="rounded">
                    <LocalShipping sx={{ fontSize: 120, p: 2 }} />
                </Avatar>
            </Grid>

            <Grid item xs={12} lg={6}>
                <Block
                    variant="outlined"
                    title="Grunduppgifter"
                    ActionComponent={(
                        <Button
                            variant="outlined"
                            startIcon={<Edit />}
                            onClick={() => navigate(`/orders/edit/${entity.id}`)}
                            sx={{ ml: 1, mb: 1 }}
                            disabled
                        >
                            Redigera
                        </Button>
                    )}
                >
                    <List
                        data={[{
                            name: 'Id',
                            value: entity.id
                        }, {
                            name: 'Publicerat av',
                            value: entity.tenantName,
                            url: `/tenants/${entity.tenantId}`
                        }, {
                            name: 'Publicerat för',
                            value: entity.isPublic ? 'Publikt' : entity.groupNames.join(', '),
                        }, {
                            name: 'Riktpris',
                            value: entity.listPrice,
                            type: 'currency'
                        }, {
                            name: 'Anmäl intresse senast',
                            value: formatDateTime(entity.expiresAt),
                        }, {
                            name: 'Status',
                            value: formatOrderStatus(entity.status)
                        }, {
                            name: 'Upphämtningsdatum',
                            value: getOrderPickupDateTime(entity)
                        }, {
                            name: 'Leveranssdatum',
                            value: getOrderPickupDateTime(entity)
                        }, {
                            name: 'Upphämtningsplats',
                            value: getOrderPickupPlace(entity)
                        }, {
                            name: 'Leveransplats',
                            value: getOrderDeliveryPlace(entity)
                        }, {
                            name: 'Gods',
                            value: formatLoadArray(entity).join(', '),
                        }, {
                            name: 'Körsträcka',
                            value: entity.drivingDistance
                                ? formatNumber(Math.round(entity.drivingDistance / 1000), 'km')
                                : '-'
                        }, {
                            name: 'Publik beskrivning',
                            value: entity.publicDescription,
                        }, {
                            name: 'Privat beskrivning',
                            value: entity.privateDescription,
                        }, {
                            name: 'Skapad',
                            value: !entity.createdAt
                                ? '' : `${formatDateTime(entity.createdAt)} av ${entity.createdByName || '-'}`,
                            url: entity.createdBy ? `/users/${entity.createdBy}` : undefined
                        }, {
                            name: 'Uppdaterad',
                            value: !entity.updatedAt
                                ? '' : `${formatDateTime(entity.updatedAt)} av ${entity.updatedByName || '-'}`,
                            url: entity.updatedBy ? `/users/${entity.updatedBy}` : undefined
                        }, {
                            name: 'Utkast',
                            value: !entity.draftedAt
                                ? '' : `${formatDateTime(entity.draftedAt)} av ${entity.draftedByName || '-'}`,
                            url: entity.draftedBy ? `/users/${entity.draftedBy}` : undefined
                        }, {
                            name: 'Publicerad',
                            value: !entity.publishedAt
                                ? '' : `${formatDateTime(entity.publishedAt)} av ${entity.publishedByName || '-'}`,
                            url: entity.publishedBy ? `/users/${entity.publishedBy}` : undefined
                        }, {
                            name: 'Tilldelad',
                            value: !entity.acceptedAt
                                ? '' : `${formatDateTime(entity.acceptedAt)} av ${entity.acceptedByName || '-'}`,
                            url: entity.acceptedBy ? `/users/${entity.acceptedBy}` : undefined
                        }, {
                            name: 'Bekräftad',
                            value: !entity.startedAt
                                ? '' : `${formatDateTime(entity.startedAt)} av ${entity.startedByName || '-'}`,
                            url: entity.startedBy ? `/users/${entity.startedBy}` : undefined
                        }, {
                            name: 'Slutförd',
                            value: !entity.completedAt
                                ? '' : `${formatDateTime(entity.completedAt)} av ${entity.completedByName || '-'}`,
                            url: entity.completedBy ? `/users/${entity.completedBy}` : undefined
                        }]}
                    />
                </Block>
            </Grid>
        </Grid>
    );
}

export default function OrderDetailsFetched({ hasRendered }: { hasRendered?: boolean }) {
    const { id } = useParams() as unknown as { id: number };

    return (
        <FetchRedux<OrderType>
            fetchEntity={fetchOrderById(id)}
            selectEntity={selectOrderById(id)}
            selectIsFetching={selectFetchingOrderById(id)}
            Component={OrderDetails}
            name="uppdraget"
            initialFetch={!hasRendered}
        />
    );
}
