import React from 'react';

import { FetchPropsType } from '../CommonComponents/Fetch/FetchPropsType';
import { TenantStatsType } from '../../types';
import {
    fetchTenantStats,
    selectFetchingTenantStats,
    selectTenantStats
} from '../../redux/actions/stats';
import FetchRedux from '../CommonComponents/Fetch/FetchRedux';
import { BarChart } from '../CommonComponents/Charts';

function TenantStats(props: FetchPropsType<TenantStatsType[]>) {
    const { entity } = props;

    return (
        <BarChart
            title="Företagsstatistik"
            sets={[{
                label: 'Registreringar',
                data: entity.map<[string, number]>((t) => [t.regDate, t.regCount])
            }]}
        />
    );
}

export default function TenantStatsFetched({ hasRendered }: { hasRendered?: boolean }) {
    return (
        <FetchRedux<TenantStatsType[]>
            fetchEntity={fetchTenantStats()}
            selectEntity={selectTenantStats()}
            selectIsFetching={selectFetchingTenantStats()}
            Component={TenantStats}
            name="statistiken"
            initialFetch={!hasRendered}
        />
    );
}
