import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

import {
    useChangeable,
    FullForm,
    TextField,
    EmailField,
    WebsiteField,
    NumberField,
    OrgNumberField,
} from '../CommonComponents/Form';
import { SectionDivider } from '../CommonComponents';

import {
    createTenant,
    deleteTenant,
    getTenant,
    updateTenant
} from '../../api-admin/tenants';

import toast from '../../utils/toast';

const defaultTenant = {
    name: '',
    orgNumber: '',
    employees: null,
    revenue: null,
    website: '',

    email: '',
    phone: '',
    street: '',
    zip: '',
    city: '',

    billingEmail: '',
    billingMark: '',
    billingStreet: '',
    billingZip: '',
    billingCity: ''
};

const TenantForm: React.FC = () => {
    const { id } = useParams();
    const [entity, onChange] = useChangeable(defaultTenant);
    const navigate = useNavigate();

    React.useEffect(() => {
        (async () => {
            if (id) {
                const t = await getTenant(id);
                onChange(t);
            }
        })();
    }, [id, onChange]);

    const onSubmit = React.useCallback(async () => {
        try {
            if (id) {
                await updateTenant(id, entity);
                toast('Företag uppdaterat');
                navigate(-1);
            } else {
                const createdUser = await createTenant(entity);
                toast('Företag skapat');
                navigate(`/tenants/${createdUser.id}`, { replace: true });
            }
        } catch (e: any) {
            toast(e, `Kunde inte ${id ? 'uppdatera' : 'skapa'} företaget, försök igen`);
        }
    }, [id, entity, navigate]);

    const onDelete = React.useCallback(async () => {
        try {
            if (id) {
                await deleteTenant(id);
                toast('Företaget borttaget');
                navigate(-2);
            }
        } catch (e: any) {
            toast(e, 'Kunde inte ta bort företaget, försök igen');
        }
    }, [id, navigate]);

    return (
        <FullForm
            title={`${id ? 'Redigera' : 'Skapa'} företag`}
            entity={entity}
            onSubmit={onSubmit}
            onDelete={id ? onDelete : undefined}
        >
            <Grid container spacing={2}>
                <Grid item md={12} lg={6}>
                    <SectionDivider label="Grunduppgifter" marginTop={2} marginBottom={0} />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                name="name"
                                label="Namn"
                                value={entity.name}
                                onChange={onChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <OrgNumberField
                                name="orgNumber"
                                label="Org. nummer"
                                value={entity.orgNumber}
                                onChange={onChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <NumberField
                                name="employees"
                                label="Antal anställda"
                                value={entity.employees}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <NumberField
                                name="revenue"
                                label="Omsättning"
                                value={entity.revenue}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <WebsiteField
                                name="website"
                                label="Hemsida"
                                value={entity.website}
                                onChange={onChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12} lg={6} />

                <Grid item md={12} lg={6}>
                    <SectionDivider label="Kontaktuppgifter" marginTop={2} marginBottom={0} />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <EmailField
                                name="email"
                                label="E-post"
                                value={entity.email}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="phone"
                                label="Telefon"
                                value={entity.phone}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="street"
                                label="Address"
                                value={entity.street}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="zip"
                                label="Postnummer"
                                value={entity.zip}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="city"
                                label="Ort"
                                value={entity.city}
                                onChange={onChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12} lg={6}>
                    <SectionDivider label="Faktureringsuppgifter" marginTop={2} marginBottom={0} />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <EmailField
                                name="billingEmail"
                                label="E-post för faktura"
                                value={entity.billingEmail}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="billingMark"
                                label="Märkning"
                                value={entity.billingMark}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="billingStreet"
                                label="Gata"
                                value={entity.billingStreet}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="billingZip"
                                label="Postnummer"
                                value={entity.billingZip}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="billingCity"
                                label="Postort"
                                value={entity.billingCity}
                                onChange={onChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </FullForm>
    );
};

export default TenantForm;
