import React from 'react';
import { TextField } from '@mui/material';

type PropsType = {
    label: string,
    fullWidth?: boolean,
    value: string,
    [key: string]: any
};

const Input: React.FC<PropsType> = (props) => {
    const {
        value,
        label,
        fullWidth = true,
        ...restProps
    } = props;

    return (
        <TextField
            label={label}
            variant="standard"
            fullWidth={fullWidth}
            {...restProps}
            InputLabelProps={value ? { shrink: true } : {}}
            value={value}
        />
    );
};

export default Input;
