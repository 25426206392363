import watchlists from '../slices/watchlists';
import type { RootStateType, ThunkDispatchType } from '../index';

import { getWatchlist, getWatchlists, getWatchlistsByUserId } from '../../api-admin/watchlists';

export function fetchAllWatchlists() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(watchlists.actions.fetchAll());
            const data = await getWatchlists();
            dispatch(watchlists.actions.allFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching watchlists, set redux error state and handle in errorBoundary.', e);
            dispatch(watchlists.actions.allFetched({ data: [] }));
        }
    };
}

export function fetchWatchlistsByUserId(id: number | string) {
    if (!id) {
        throw Error('No id supplied for user');
    }
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(watchlists.actions.fetchByUserId({ id }));
            const data = await getWatchlistsByUserId(id);
            dispatch(watchlists.actions.byUserIdFetched({ id, data }));
        } catch (e) {
            console.log('ERROR fetching watchlists for user, set redux error state and handle in errorBoundary.', e);
            dispatch(watchlists.actions.byUserIdFetched({ id, data: [] }));
        }
    };
}

export function fetchWatchlistById(id: number | string) {
    if (!id) {
        throw Error('No id supplied for watchlist');
    }
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(watchlists.actions.fetchById({ id }));
            const data = await getWatchlist(id);
            dispatch(watchlists.actions.byIdFetched({ id, data }));
        } catch (e) {
            console.log('ERROR fetching watchlist, set redux error state and handle in errorBoundary.', e);
            dispatch(watchlists.actions.byIdFetched({ id }));
        }
    };
}

export const selectAllWatchlists = () => (state: RootStateType) => state.watchlists.all;
export const selectFetchingAllWatchlists = () => (state: RootStateType) => state.watchlists.fetchingAll;

export const selectWatchlistsByUserId = (id: number | string) => (state: RootStateType) => (
    state.watchlists.byUserId[id] ?? []
);
export const selectFetchingWatchlistsByUserId = (id: number | string) => (state: RootStateType) => (
    state.watchlists.fetchingByUserId[id] ?? false
);

export const selectWatchlistById = (id: number | string) => (state: RootStateType) => (
    state.watchlists.byId[id]
);
export const selectFetchingWatchlistById = (id: number | string) => (state: RootStateType) => (
    state.watchlists.fetchingById[id]
);
