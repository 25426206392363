import React from 'react';
import { useParams } from 'react-router-dom';
import {
    fetchRequestsByUserId,
    selectFetchingRequestsByUserId,
    selectRequestsByUserId
} from '../../redux/actions/requests';
import { RequestForListType } from '../../types';
import { Table } from '../CommonComponents';
import { FetchPropsType } from '../CommonComponents/Fetch/FetchPropsType';
import FetchRedux from '../CommonComponents/Fetch/FetchRedux';

const columns = [{
    name: 'Metod',
    key: 'method',
    type: 'string' as 'string',
    canSearch: true
}, {
    name: 'Resurs',
    key: 'url',
    type: 'string' as 'string',
    canSearch: true
}, {
    name: 'Datum',
    key: 'createdAt',
    type: 'datetime' as 'datetime',
    canFilter: true
}];

function UserRequests(props: FetchPropsType<RequestForListType[]>) {
    const { entity, isFetching } = props;

    return (
        <Table
            name="userRequests"
            columns={columns}
            data={entity}
            emptyText={isFetching ? 'Hämtar aktiviteter...' : 'Det finns inga aktiviteter.'}
            showPagination
            defaultOrderBy="createdAt"
            defaultOrderDirection="asc"
        />
    );
}

export default function UserRequestsFetched({ hasRendered }: { hasRendered?: boolean }) {
    const { id } = useParams() as unknown as { id: number };

    return (
        <FetchRedux<RequestForListType[]>
            fetchEntity={fetchRequestsByUserId(id)}
            selectEntity={selectRequestsByUserId(id)}
            selectIsFetching={selectFetchingRequestsByUserId(id)}
            Component={UserRequests}
            name="aktiviteter"
            initialFetch={!hasRendered}
        />
    );
}
