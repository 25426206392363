import React from 'react';

import { Divider, Typography } from '@mui/material';

type PropsType = {
    label: string,
    marginTop?: number,
    marginBottom?: number,
};

const SectionDivider: React.FC<PropsType> = (props) => {
    const {
        label,
        marginTop = 4,
        marginBottom = 2
    } = props;

    return (
        <Divider sx={{ marginTop, marginBottom }}>
            <Typography variant="overline">
                {label}
            </Typography>
        </Divider>
    );
};

export default SectionDivider;
