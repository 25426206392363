/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    DhlStatsType,
    GroupStatsType,
    OrderStatsType,
    TenantStatsType,
    UserStatsType
} from '../../types';

type StatsStateType = {
    tenantStats: TenantStatsType[],
    fetchingTenantStats: boolean,
    userStats: UserStatsType,
    fetchingUserStats: boolean,
    groupStats: GroupStatsType,
    fetchingGroupStats: boolean,
    orderStats: OrderStatsType[],
    fetchingOrderStats: boolean,
    dhlStats: DhlStatsType[],
    fetchingDhlStats: boolean
};

const initialState: StatsStateType = {
    tenantStats: [],
    fetchingTenantStats: false,
    userStats: {
        createdUsers: [],
        activeUsers: [],
        createdWatchlists: []
    },
    fetchingUserStats: false,
    groupStats: {
        groupsCreatedAt: [],
        groupInvitationsCreatedAt: []
    },
    fetchingGroupStats: false,
    orderStats: [],
    fetchingOrderStats: false,
    dhlStats: [],
    fetchingDhlStats: false
};

const slice = createSlice({
    name: 'stats',
    initialState,
    reducers: {
        fetchTenantStats: (state) => {
            state.fetchingTenantStats = true;
        },
        tenantStatsFetched: (state, action: PayloadAction<{data: StatsStateType['tenantStats']}>) => {
            state.tenantStats = action.payload.data;
            state.fetchingTenantStats = false;
        },
        fetchUserStats: (state) => {
            state.fetchingUserStats = true;
        },
        userStatsFetched: (state, action: PayloadAction<{data: StatsStateType['userStats']}>) => {
            state.userStats = action.payload.data;
            state.fetchingUserStats = false;
        },
        fetchGroupStats: (state) => {
            state.fetchingGroupStats = true;
        },
        groupStatsFetched: (state, action: PayloadAction<{data: StatsStateType['groupStats']}>) => {
            state.groupStats = action.payload.data;
            state.fetchingGroupStats = false;
        },
        fetchOrderStats: (state) => {
            state.fetchingOrderStats = true;
        },
        orderStatsFetched: (state, action: PayloadAction<{data: StatsStateType['orderStats']}>) => {
            state.orderStats = action.payload.data;
            state.fetchingOrderStats = false;
        },
        fetchDhlStats: (state) => {
            state.fetchingDhlStats = true;
        },
        dhlStatsFetched: (state, action: PayloadAction<{data: StatsStateType['dhlStats']}>) => {
            state.dhlStats = action.payload.data;
            state.fetchingDhlStats = false;
        }
    }
});

export default slice;
