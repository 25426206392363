/* eslint-disable no-param-reassign */
// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SettingsStateType = {
    marketFilterOpen: boolean,
    marketListOpen: boolean
};

const initialState: SettingsStateType = {
    marketFilterOpen: false,
    marketListOpen: true
};

const slice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setMarketFilterOpen: (state, action: PayloadAction<boolean>) => {
            state.marketFilterOpen = action.payload;
        },
        setMarketListOpen: (state, action: PayloadAction<boolean>) => {
            state.marketListOpen = action.payload;
        }
    }
});

export default slice;
