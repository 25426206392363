import apiRequest from './api-request';

const resource = 'requests';

export function getRequests() {
    return apiRequest({
        method: 'GET',
        resource
    });
}

export function getRequestsByTenantId(id: number | string) {
    return apiRequest({
        method: 'GET',
        resource: `tenants/${id}/${resource}`
    });
}

export function getRequestsByUserId(id: number | string) {
    return apiRequest({
        method: 'GET',
        resource: `users/${id}/${resource}`
    });
}

export function getRequestsByOrderId(id: number | string) {
    return apiRequest({
        method: 'GET',
        resource: `orders/${id}/${resource}`
    });
}
