import React from 'react';
import {
    Grid,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from '@mui/material';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    // LineElement,
    // PointElement,
    // Title as ChartTitle,
    Tooltip,
    Legend,
    TimeScale
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
// import { Line } from 'react-chartjs-2';

import 'chartjs-adapter-date-fns';
import {
    add,
    isWithinInterval,
    startOfDay,
    startOfWeek,
    startOfMonth,
    startOfQuarter,
    startOfYear,
    sub,
    isEqual
// eslint-disable-next-line import/no-duplicates
} from 'date-fns';

// eslint-disable-next-line import/no-duplicates
import setDefaultOptions from 'date-fns/setDefaultOptions';
// eslint-disable-next-line import/no-duplicates
import { sv } from 'date-fns/locale';

import { Title } from '../../StyledComponents';

setDefaultOptions({ locale: sv });

// // Bar
// ChartJS.register(
//     CategoryScale,
//     LinearScale,
//     BarElement,
//    // ChartTitle,
//     Tooltip,
//     Legend,
//     TimeScale
// );

// Line
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    // PointElement,
    // LineElement,
    // ChartTitle,
    Tooltip,
    Legend,
    TimeScale
);

function getColor(i: number, opacity: number = 1) {
    const colors = [
        '0, 109, 119',
        '80, 80, 160',
        '131, 197, 190',
        '255, 221, 210',
        '210, 109, 119',
        '134, 129, 147',
    ];
    return `rgba(${colors[i % colors.length]}, ${opacity})`;
}

function getTooltipFormat(unit: string) {
    let dateFormat = 'MMMM dd yyyy';

    if (unit === 'year') {
        dateFormat = 'yyyy';
    }

    if (unit === 'quarter') {
        dateFormat = 'QQQ - yyyy';
    }

    if (unit === 'month') {
        dateFormat = 'MMMM yyyy';
    }

    return dateFormat;
}

type UnitType = 'year' | 'quarter' | 'month' | 'week' | 'day';

type ParamsType = {
    title?: string,
    description?: string,
    sets: {
        label: string,
        data: string[] | [string, number][]
    }[]
}

const now = new Date();

const unitsWithProps = [{
    unit: 'year',
    numberOfBins: 4,
    getBreakpointDate: (offset: number) => sub(
        startOfYear(add(now, { years: 1 })),
        { years: offset }
    ),
}, {
    unit: 'quarter',
    numberOfBins: 8,
    getBreakpointDate: (offset: number) => sub(
        startOfQuarter(add(now, { months: 3 })),
        { months: 3 * offset }
    )
}, {
    unit: 'month',
    numberOfBins: 12,
    getBreakpointDate: (offset: number) => sub(
        startOfMonth(add(now, { months: 1 })),
        { months: offset }
    )
}, {
    unit: 'week',
    numberOfBins: 26,
    getBreakpointDate: (offset: number) => sub(
        startOfWeek(add(now, { weeks: 1 })),
        { weeks: offset }
    )
}, {
    unit: 'day',
    numberOfBins: 31,
    getBreakpointDate: (offset: number) => sub(
        startOfDay(add(now, { days: 1 })),
        { days: offset }
    )
}
];

const unitsWithBins = Object.fromEntries(unitsWithProps.map((up) => [up.unit, {
    numberOfBins: up.numberOfBins,
    getBreakpointDate: up.getBreakpointDate,
    bins: [...Array(up.numberOfBins)].map((_, i) => ({
        date: up.getBreakpointDate(i + 1),
        getBinValue: (data: any) => (
            data.reduce((acc: number, curr: any) => {
                const point = Array.isArray(curr)
                    ? { date: curr[0], value: curr[1] }
                    : { date: curr, value: 1 };
                const date = new Date(point.date);
                const start = up.getBreakpointDate(i + 1);
                const end = up.getBreakpointDate(i);
                return acc + (
                    isWithinInterval(date, { start, end }) && !isEqual(date, end) ? point.value : 0
                );
            }, 0)
        )
    }))
}]));

export default function BarChart({ title, description, sets }: ParamsType) {
    const [unit, setUnit] = React.useState<UnitType>('month');
    const unitSetDataLoaded = React.useRef<{ [key: string]: Boolean }>({});
    const [unitSetData, setUnitSetData] = React.useState<{ [key: string]: number[] }>({});
    // const [binCount, setBinCount] = React.useState(12);
    // const [binOffset, setBinOffset] = React.useState(0);

    const options = React.useMemo(() => ({
        locale: 'sv-SE',
        responsive: true,
        // interaction: {
        //     mode: 'index' as const,
        //     intersect: false
        // },
        // stacked: false,
        plugins: {
            legend: {
                position: 'top' as const,
            },
        },
        scales: {
            x: {
                type: 'time' as const,
                isoWeekday: true,
                time: {
                    unit,
                    tooltipFormat: getTooltipFormat(unit)
                }
            },
            y: {
                beginAtZero: true
            },
        }
    }), [unit]);

    const datasetsMeta = React.useMemo(() => (
        sets.map((s: any, i: number) => ({
            label: s.label,
            // data: [], // overridden
            // data: getBinnedData(s.data),
            borderWidth: 1,
            borderColor: getColor(i),
            backgroundColor: getColor(i, 0.6),
            hoverBorderColor: getColor(i),
            hoverBackgroundColor: getColor(i, 0.8)
        }))
    ), [sets]);

    // If sets or unit change, rebin data only if not already done
    const generateData = React.useCallback(async () => {
        const newData = sets.reduce((acc, curr, i) => {
            if (!unitSetDataLoaded.current[`${unit}${i}`] && curr.data.length) {
                unitSetDataLoaded.current[`${unit}${i}`] = true;
                return ({
                    ...acc,
                    [`${unit}${i}`]: unitsWithBins[unit].bins.map((b) => b.getBinValue(curr.data))
                });
            }
            return acc;
        }, {});
        if (Object.keys(newData).length) {
            setUnitSetData((d) => ({ ...d, ...newData }));
        }
    }, [sets, unit]);

    React.useEffect(() => {
        generateData();
    }, [generateData]);

    return (
        <Grid container spacing={2}>
            <Grid item xs>
                <Title>{title}</Title>
                <Typography>{description}</Typography>
            </Grid>

            <Grid item>
                <ToggleButtonGroup
                    color="primary"
                    value={unit}
                    exclusive
                    onChange={(_, newValue) => newValue && setUnit(newValue)}
                    aria-label="Intervall"
                >
                    <ToggleButton value="year">År</ToggleButton>
                    <ToggleButton value="quarter">Kvartal</ToggleButton>
                    <ToggleButton value="month">Månad</ToggleButton>
                    <ToggleButton value="day">Dag</ToggleButton>
                </ToggleButtonGroup>
            </Grid>

            <Grid item xs={12}>
                <Bar
                    options={options}
                    data={{
                        labels: unitsWithBins[unit].bins.map((b) => b.date),
                        datasets: datasetsMeta.map((ds, i) => ({
                            ...ds,
                            data: unitSetData[`${unit}${i}`] || []
                        }))
                    }}
                />
            </Grid>
        </Grid>

    );
}
