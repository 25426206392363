import React, {
    useCallback,
    useContext,
    useState
} from 'react';

import FormContext from '../FormContext';
import useValidation from '../hooks/useValidation';
import { validateRequired } from '../validations/validations';
import type { ValidationType } from '../validations/ValidationContext';
import { Input } from '../../../StyledComponents';
import Debounce from '../../../../utils/debounce';

type PropsType = {
    required?: boolean
    type?: string,
    inputProps?: { [key: string]: any },
    name: string,
    label: string,
    value: string,
    onChange: (a: any) => void,
    emptyAsNull?: boolean,
    helperText?: string,
    submitOnEnter?: boolean,
    validations: {
        type: string,
        errorText: string,
        isValid: boolean
    }[]
};

export default function InputField(props: PropsType) {
    const {
        name,
        label,
        value,
        required,
        onChange,
        emptyAsNull,
        helperText,
        validations = [],
        submitOnEnter,
        ...restProps
    } = props;

    const [errorMessageHidden, setErrorMessageHidden] = useState(false);
    const allValidations = typeof required !== 'undefined'
        ? [validateRequired({ value: !required || value }), ...validations]
        : validations;

    const errors = allValidations
        // useValidation registers the validation and returns isValid for filter
        // eslint-disable-next-line react-hooks/rules-of-hooks
        .filter((v: ValidationType) => useValidation({ name, type: v.type, isValid: v.isValid }));

    const errorMessage = errors.map((v: ValidationType) => v.errorText).join(' ');

    const delayErrorMessage = useCallback(Debounce(() => {
        setErrorMessageHidden(false);
    }, 1000), []);

    const formContext = useContext(FormContext);
    const isSubmitting = formContext?.isSubmitting;
    const submit = formContext?.submit;

    const submitIfEnter = React.useCallback((event) => {
        if (submitOnEnter && event.key === 'Enter' && submit) {
            event.preventDefault();
            submit();
        }
    }, [submitOnEnter, submit]);

    return (
        <Input
            name={name}
            label={`${label}${required ? ' *' : ''}`}
            value={value ?? ''}
            // error={!!errors.length && value}
            error={!!errors.length}
            helperText={(!errorMessageHidden && errorMessage) || helperText}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setErrorMessageHidden(true);
                delayErrorMessage();
                onChange(
                    (emptyAsNull && event.target.value === '')
                        ? { [event.target.name]: null }
                        : event
                );
            }}
            autoComplete="off"
            disabled={isSubmitting}
            onKeyPress={submitIfEnter}
            {...restProps}
        />
    );
}
