import React from 'react';
import { useParams } from 'react-router-dom';
import {
    fetchGroupInvitationsByGroupId,
    fetchGroupMetaById,
    selectFetchingGroupInvitationsByGroupId,
    selectGroupInvitationsByGroupId
} from '../../redux/actions/groups';
import { useDispatch } from '../../redux/hooks';
import { GroupInvitationForListType } from '../../types';
import { Table } from '../CommonComponents';
import { FetchPropsType } from '../CommonComponents/Fetch/FetchPropsType';
import FetchRedux from '../CommonComponents/Fetch/FetchRedux';
import { useChangeable } from '../CommonComponents/Form';
import GroupInvitationForm from './GroupInvitationForm';

const columns = [{
    name: 'Företag',
    key: 'tenantName',
    canSearch: true
}, {
    name: 'Accepterat',
    key: 'isAccepted',
    type: 'bool' as 'bool',
    canFilter: true
}, {
    name: 'Kan publicera',
    key: 'canPublish',
    type: 'bool' as 'bool',
    canFilter: true
}, {
    name: 'Skapades',
    key: 'createdAt',
    type: 'datetime' as 'datetime',
    canFilter: true
}];

const defaultInvitation = {
    groupId: undefined, // set dynamically
    tenantId: null,
    tenantName: null,
    canPublish: false,
    isAccepted: false,
    sendNotifications: false,
    invitedEmail: '',
    invitedPhone: ''
};

function GroupInvitations(props: FetchPropsType<GroupInvitationForListType[]>) {
    const { entity, isFetching, fetch } = props;

    const { id } = useParams() as unknown as { id: number };
    const dispatch = useDispatch();

    const [invitationToEdit, setInvitationToEdit] = useChangeable(null);

    const onRowClick = React.useCallback((invitation: GroupInvitationForListType) => {
        setInvitationToEdit({ ...defaultInvitation, ...invitation });
    }, [setInvitationToEdit]);

    const onAddClick = React.useCallback(() => {
        setInvitationToEdit({ ...defaultInvitation, groupId: id });
    }, [id, setInvitationToEdit]);

    const refetch = React.useCallback(() => {
        fetch();
        dispatch(fetchGroupMetaById(id));
    }, [dispatch, fetch, id]);

    return (
        <>
            <GroupInvitationForm
                invitations={entity}
                invitation={invitationToEdit}
                setInvitation={setInvitationToEdit}
                refetch={refetch}
            />
            <Table
                name="groupInvitations"
                columns={columns}
                data={entity}
                onRowClick={onRowClick}
                onAddClick={onAddClick}
                emptyText={isFetching ? 'Hämtar inbjudningar...' : 'Det finns inga inbjudningar.'}
                showPagination
                defaultOrderBy="createdAt"
                defaultOrderDirection="asc"
            />
        </>
    );
}

export default function GroupInvitationsFetched({ hasRendered }: { hasRendered?: boolean }) {
    const { id } = useParams() as unknown as { id: number };

    return (
        <FetchRedux<GroupInvitationForListType[]>
            fetchEntity={fetchGroupInvitationsByGroupId(id)}
            selectEntity={selectGroupInvitationsByGroupId(id)}
            selectIsFetching={selectFetchingGroupInvitationsByGroupId(id)}
            Component={GroupInvitations}
            name="inbjudningar"
            initialFetch={!hasRendered}
        />
    );
}
