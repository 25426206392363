import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {
    Check as CheckIcon,
    Close as CloseIcon,
    Delete as DeleteIcon
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

type PropsType = {
    open: boolean,
    title: string,
    body?: string,
    okLabel?: string,
    cancelLabel?: string,
    deleteLabel?: string,
    handleOk: () => any,
    handleClose: () => any,
    handleDelete?: () => any,
    okDisabled?: boolean,
    deleteDisabled?: boolean,
    isSubmitting?: boolean,
    fullWidth?: boolean,
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
};

const Popup: React.FC<PropsType> = (props) => {
    const {
        open,
        title,
        body = '',
        okLabel = 'Ok',
        cancelLabel = 'Avbryt',
        deleteLabel = 'Ta bort',
        handleOk,
        handleClose,
        handleDelete,
        okDisabled = false,
        deleteDisabled = false,
        isSubmitting = false,
        children,
        fullWidth,
        maxWidth
    } = props;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={fullWidth}
            maxWidth={maxWidth}
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                {children || (
                    <DialogContentText id="alert-dialog-description">
                        {body}
                    </DialogContentText>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="text"
                    startIcon={<CloseIcon />}
                    onClick={handleClose}
                    disabled={isSubmitting}
                >
                    {cancelLabel}
                </Button>
                {handleDelete && (
                    <LoadingButton
                        variant="contained"
                        color="error"
                        onClick={handleDelete}
                        loading={isSubmitting}
                        loadingPosition="start"
                        startIcon={<DeleteIcon />}
                        disabled={deleteDisabled}
                    >
                        {deleteLabel}
                    </LoadingButton>
                )}
                <LoadingButton
                    variant="contained"
                    onClick={handleOk}
                    loading={isSubmitting}
                    loadingPosition="start"
                    startIcon={<CheckIcon />}
                    disabled={okDisabled}
                >
                    {okLabel}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default Popup;
