import Amplify, { Auth } from 'aws-amplify';

const userPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID;
const userPoolWebClientId = process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID;

const options = {
    Auth: {
        region: 'eu-north-1',
        userPoolId,
        userPoolWebClientId,
        mandatorySignIn: true,
        authenticationFlowType: 'USER_SRP_AUTH',
    },
    Analytics: {
        disabled: true
    }
};

Amplify.configure(options);

export function refreshSession() {
    return Auth.currentAuthenticatedUser({ bypassCache: true });
}

export function getCurrentUser() {
    return Auth.currentAuthenticatedUser();
}

export function signIn({ email, password }: { email: string; password: string; }) {
    if (!email) {
        throw new Error('Du måste fylla i en e-postadress.');
    }
    if (!password) {
        throw new Error('Du måste fylla i ett lösenord.');
    }
    return Auth.signIn(email.trim(), password);
}

export function confirmSignIn({ user, code }: { user: any; code: string; }) {
    if (!user) {
        throw new Error('Saknar användare.');
    }
    if (!code) {
        throw new Error('Du måste fylla i en MFA-kod.');
    }
    return Auth.confirmSignIn(
        user, // Return object from Auth.signIn()
        code, // Confirmation code
        'SMS_MFA' // MFA Type e.g. , SOFTWARE_TOKEN_MFA
    );
}

export function signOut(global = false) {
    return Auth.signOut({ global });
}

// Only used by logged in users, logged out users are doing this over own api to make sure email is sent
export function sendResetPasswordCode({ email }: { email: string; }) {
    return Auth.forgotPassword(email.trim());
}

export function resetPassword(
    { email, resetCode, newPassword }: { email: string, resetCode: string, newPassword: string }
) {
    return Auth.forgotPasswordSubmit(email.trim(), resetCode, newPassword);
}

export function sendVerifyEmailCode() {
    return Auth.verifyCurrentUserAttribute('email');
}

export function verifyEmail(verificationCode: string) {
    return Auth.verifyCurrentUserAttributeSubmit('email', verificationCode);
}

export function sendVerifyPhoneCode() {
    return Auth.verifyCurrentUserAttribute('phone_number');
}

export function verifyPhone(verificationCode: string) {
    return Auth.verifyCurrentUserAttributeSubmit('phone_number', verificationCode);
}

// Not yet used:
export function resendConfirmationCode({ email }: { email: string }) {
    return Auth.resendSignUp(email.trim());
}
