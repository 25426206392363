/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TenantForListType, TenantMetaType, TenantType } from '../../types';

type TenantsStateType = {
    all: TenantForListType[],
    fetchingAll: boolean,
    byId: {[key: number | string]: TenantType | undefined },
    fetchingById: {[key: number | string]: boolean},
    metaById: {[key: number | string]: TenantMetaType | undefined },
    fetchingMetaById: {[key: number | string]: boolean}
};

const initialState: TenantsStateType = {
    all: [],
    fetchingAll: false,
    byId: {},
    fetchingById: {},
    metaById: {},
    fetchingMetaById: {}
};

const slice = createSlice({
    name: 'tenants',
    initialState,
    reducers: {
        fetchAll: (state) => {
            state.fetchingAll = true;
        },
        allFetched: (state, action: PayloadAction<{data: TenantsStateType['all']}>) => {
            state.all = action.payload.data;
            state.fetchingAll = false;
        },
        fetchById: (state, action) => {
            state.fetchingById[action.payload.id] = true;
        },
        byIdFetched: (state, action: PayloadAction<{ id: number | string, data?: TenantType }>) => {
            state.byId[action.payload.id] = action.payload.data;
            state.fetchingById[action.payload.id] = false;
        },
        fetchMetaById: (state, action) => {
            state.fetchingMetaById[action.payload.id] = true;
        },
        metaByIdFetched: (state, action: PayloadAction<{ id: number | string, data?: TenantMetaType }>) => {
            state.metaById[action.payload.id] = action.payload.data;
            state.fetchingMetaById[action.payload.id] = false;
        }
    }
});

export default slice;
